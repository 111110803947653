import { AnalysisConfigurableType, AnalysisValueView } from 'src/api/generated';
import { getTrimmedValue } from '../analysisValues';

export const getStrengths = (
  analysisValues: AnalysisValueView[]
): AnalysisValueView[] => {
  return analysisValues.filter(isStrengthValue);
};

export const getStrengthValue = (
  analysisValue: AnalysisValueView
): string | null => {
  if (!isStrengthValue(analysisValue)) return null;

  const trimmedValue = getTrimmedValue(analysisValue);
  return trimmedValue;
};

const isStrengthValue = (analysisValue: AnalysisValueView): boolean => {
  return (
    analysisValue.analysisConfigurableType ===
    AnalysisConfigurableType.FEEDBACK_STRENGTH
  );
};
