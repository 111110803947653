// src/hooks/analysis/summary/latestContactQualiScore.ts

import { useMemo } from 'react';
import { AnalysisValueView } from 'src/api/generated';
import { getLatestContactQualiScore } from 'src/utils/analysisValues/scores/contactQuali';

export const useLatestContactQualiScore = (
  analysisValues: AnalysisValueView[]
) => {
  return useMemo(
    () => getLatestContactQualiScore(analysisValues),
    [analysisValues]
  );
};
