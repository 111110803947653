import { useMemo, useState } from 'react';
import { DailyCallSummary } from 'src/api/generated';
import { AgentInfo, CallType } from 'src/types/call-summaries';

/**
 * A hook for filtering DailyCallSummary data by selected agent IDs.
 *
 * @param {DailyCallSummary[]} dailySummaries - Array of call summaries.
 * @returns {Object} {
 *   selectedAgents: number[], // Array of selected agent IDs
 *   handleAgentSelectionChange: (agentIds: number[]) => void, // Function to update selected agents
 *   filteredDailySummaries: DailyCallSummary[] // Filtered call summaries
 * }
 */
export const useAgentFilteredCalls = (dailySummaries: DailyCallSummary[]) => {
  const [selectedAgents, setSelectedAgents] = useState<number[]>([]);

  const filteredSummariesByAgent: DailyCallSummary[] = useMemo(() => {
    return dailySummaries.map((summary) => {
      const filteredAgents = summary.agents.filter((agent) =>
        selectedAgents.includes(agent.id)
      );

      const totals = filteredAgents.reduce(
        (acc, agent) => ({
          totalEffectiveCalls: acc.totalEffectiveCalls + agent.effectiveCalls,
          totalMissedCalls: acc.totalMissedCalls + agent.missedCalls,
          totalVoicemailCalls: acc.totalVoicemailCalls + agent.voicemailCalls,
        }),
        { totalEffectiveCalls: 0, totalMissedCalls: 0, totalVoicemailCalls: 0 }
      );

      return {
        ...summary,
        totalEffectiveCalls: totals.totalEffectiveCalls,
        totalMissedCalls: totals.totalMissedCalls,
        totalVoicemailCalls: totals.totalVoicemailCalls,
        agents: filteredAgents,
      };
    });
  }, [dailySummaries, selectedAgents]);

  return {
    selectedAgents,
    setSelectedAgents,
    filteredSummariesByAgent,
  };
};

/**
 * A hook for filtering DailyCallSummary data by selected call type.
 *
 * @param {DailyCallSummary[]} dailySummaries - Array of call summaries.
 * @returns {Object} {
 *   selectedCallType: CallType, // Selected call type
 *   handleCallTypeChange: (callType: CallType) => void, // Function to update selected call type
 *   filteredDailySummaries: DailyCallSummary[] // Filtered call summaries
 * }
 */
export const useCallTypeFilteredCalls = (
  dailySummaries: DailyCallSummary[]
) => {
  const [selectedCallType, setSelectedCallType] = useState<CallType>('all');

  const filteredSummariesByCallType: DailyCallSummary[] = useMemo(() => {
    return dailySummaries.map((summary) => {
      let filteredTotalEffectiveCalls = summary.totalEffectiveCalls;
      let filteredTotalMissedCalls = summary.totalMissedCalls;
      let filteredTotalVoicemailCalls = summary.totalVoicemailCalls;

      const filteredAgents = summary.agents.map((agent) => {
        let effectiveCalls = agent.effectiveCalls;
        let missedCalls = agent.missedCalls;
        let voicemailCalls = agent.voicemailCalls;

        if (selectedCallType !== 'all') {
          if (selectedCallType !== 'effective') effectiveCalls = 0;
          if (selectedCallType !== 'missed') missedCalls = 0;
          if (selectedCallType !== 'voicemail') voicemailCalls = 0;
        }

        return {
          ...agent,
          effectiveCalls,
          missedCalls,
          voicemailCalls,
        };
      });

      if (selectedCallType !== 'all') {
        if (selectedCallType !== 'effective') filteredTotalEffectiveCalls = 0;
        if (selectedCallType !== 'missed') filteredTotalMissedCalls = 0;
        if (selectedCallType !== 'voicemail') filteredTotalVoicemailCalls = 0;
      }

      return {
        ...summary,
        agents: filteredAgents,
        totalEffectiveCalls: filteredTotalEffectiveCalls,
        totalMissedCalls: filteredTotalMissedCalls,
        totalVoicemailCalls: filteredTotalVoicemailCalls,
      };
    });
  }, [dailySummaries, selectedCallType]);

  return {
    selectedCallType,
    setSelectedCallType,
    filteredSummariesByCallType,
  };
};

/**
 * Custom hook to sort and return an array of unique agent names with their IDs.
 * @param dailyCallSummaries Array of DailyCallSummary objects.
 * @returns Sorted array of unique agent objects, each containing 'name' and 'id'.
 */
export const useAgentSorting = (
  dailyCallSummaries: DailyCallSummary[]
): AgentInfo[] => {
  return useMemo(() => {
    const agents = dailyCallSummaries.flatMap((dailySummary) =>
      dailySummary.agents.map((agent) => ({ name: agent.name, id: agent.id }))
    );

    const uniqueAgents = Array.from(
      new Map(agents.map((agent) => [agent.id, agent])).values()
    );

    return uniqueAgents.sort((a, b) => a.name.localeCompare(b.name));
  }, [dailyCallSummaries]);
};
