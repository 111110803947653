import React from 'react';
import Button from 'src/modules/shared/components/atoms/buttons/button';

interface WhatsAppTemplatesModalFooterProps {
  onCancel: () => void;
  onSend: () => void;
  sendDisabled: boolean;
  loading: boolean;
}

const WhatsAppTemplatesModalFooter: React.FC<
  WhatsAppTemplatesModalFooterProps
> = ({ onCancel, onSend, sendDisabled, loading }) => (
  <div className="flex justify-end space-x-2 rounded-lg shadow-sm p-4">
    <Button onClick={onCancel} variant="secondary" size="md">
      {/* TODO: check when implementing i18n */}
      Cancelar
    </Button>
    <Button onClick={onSend} disabled={sendDisabled} size="md">
      {/* TODO: check when implementing i18n */}
      {loading ? 'Enviando...' : 'Enviar mensaje'}
    </Button>
  </div>
);

export default WhatsAppTemplatesModalFooter;
