// src/components/molecules/team-select/team-select.tsx

import Select from 'components/atoms/select';
import { Team } from 'src/api/generated';
import { SelectData } from 'tw-elements-react/dist/types/forms/Select/types';

interface TeamSelectProps {
  teams: Team[];
  selectedTeams: number[];
  onTeamSelectionChange: (selectedTeams: number[]) => void;
}

const TeamSelect: React.FC<TeamSelectProps> = ({
  teams,
  selectedTeams,
  onTeamSelectionChange: onAgentSelectionChange,
}) => {
  const generateData = () => {
    return teams.map((team) => ({
      // TODO: check when implementing i18n
      text: team.name || 'Equipo sin nombre',
      value: team.id,
    }));
  };

  const handleAgentSelectionChange = (
    selectedData: SelectData | SelectData[]
  ) => {
    const selectedValues = Array.isArray(selectedData)
      ? selectedData.map((data) => data.value as number)
      : [selectedData.value as number];

    if (selectedValues.length === 0) {
      return onAgentSelectionChange([]);
    }

    onAgentSelectionChange(selectedValues);
  };

  return (
    // TODO: check when implementing i18n
    <Select
      data={generateData()}
      multiple
      search
      searchLabel="Buscar equipos..."
      label="Filtrar por equipos"
      value={selectedTeams}
      onValueChange={handleAgentSelectionChange}
      selectAll
      selectAllLabel="Seleccionar todos"
      noResultsText="No hay resultados"
      optionsSelectedLabel="equipos seleccionados"
      containerClassName="max-w-[16rem]"
    />
  );
};

export default TeamSelect;
