// src/hooks/contacts.ts

import {
  ApiQueryParams,
  CallWithAnalysisAndUser,
  ContactWithCalls,
  User,
} from 'src/api/generated';
import { FilterParam, generateFilterQueryString } from 'src/utils/queryParams';
import { useSearch } from './search';
import { useFilterAgent } from './users';
import { SortOrder } from 'components/molecules/tables/sorting-header';
import { useDataFilteringQueryParams } from './dataFiltering';

// TODO: where to put this?
export type FormattingFunction = (contact: ContactWithCalls) => {
  calls: CallWithAnalysisAndUser[];
  lastCallDate: Date | null;
  maxQualiScore: number | null;
  agentsTooltip: string;
  timeSinceLastCall: number;
  contactName: string;
  agentName: string;
  missedCalls: number;
};

export const useContactsQueryParams = (
  agentsInFilter: User[],
  currentUser: User | null,
  defaultSortField: string | null,
  defaultSortOrder: SortOrder,
  defaultPageSize: number | null
) => {
  const { selectedAgentId, sendAgentSelection } = useFilterAgent(
    agentsInFilter,
    currentUser
  );

  const filters: Record<string, FilterParam> = {};
  if (selectedAgentId) {
    filters.userId = { value: selectedAgentId };
  }

  const filterBy = generateFilterQueryString(filters);
  const { searchQueryParams, sendSearch } = useSearch();
  const {
    dataFilteringQueryParams,
    sortField,
    selectSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    pageSize,
    setPageSize,
  } = useDataFilteringQueryParams(
    defaultSortField,
    defaultSortOrder,
    defaultPageSize
  );

  const queryParams: ApiQueryParams = {
    filterBy,
    ...searchQueryParams,
    ...dataFilteringQueryParams,
  };

  return {
    queryParams,
    selectedAgentId,
    sendAgentSelection,
    sendSearch,
    sortField,
    selectSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    pageSize,
    setPageSize,
  };
};
