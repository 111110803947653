// src/hooks/analysis/summary/useCopyableContent.ts

import { useEffect } from 'react';
import { AnalysisValueView } from 'src/api/generated';
import {
  getExtractionDescription,
  getExtractionValue,
} from 'src/utils/analysisValues/extractions/extractions';
import {
  getScoreDescription,
  getScoreMaxValue,
  getScoreValue,
} from 'src/utils/analysisValues/scores/scores';

interface UseCopyableContentProps {
  loading: boolean;
  analysisValues: AnalysisValueView[];
  latestContactQualiScore: AnalysisValueView | null;
  publicScores: AnalysisValueView[];
  extractions: AnalysisValueView[];
  onCopyableContentChange?: (content: string) => void;
}

export const useCopyableContent = ({
  loading,
  analysisValues,
  latestContactQualiScore,
  publicScores,
  extractions,
  onCopyableContentChange,
}: UseCopyableContentProps) => {
  useEffect(() => {
    if (loading || !analysisValues.length) return;

    const copyableContentLines: string[] = [];

    if (latestContactQualiScore) {
      const scoreValue = getScoreValue(latestContactQualiScore);
      const scoreMaxValue = getScoreMaxValue(latestContactQualiScore);
      if (scoreValue !== null && scoreMaxValue !== null) {
        copyableContentLines.push(
          `Calificación del prospecto: ${scoreValue} / ${scoreMaxValue}`
        );
      }
    }

    if (publicScores.length > 0) {
      publicScores.forEach((score) => {
        const scoreDescription = getScoreDescription(score);
        const scoreValue = getScoreValue(score);
        const scoreMaxValue = getScoreMaxValue(score);

        if (scoreDescription && scoreValue !== null && scoreMaxValue !== null) {
          copyableContentLines.push(
            `${scoreDescription}: ${scoreValue} / ${scoreMaxValue}`
          );
        }
      });
    }

    const summaryExtraction = extractions.find(
      (extraction) => extraction.configKey === 'transcription.summary'
    );
    if (summaryExtraction) {
      const summaryValue = getExtractionValue(summaryExtraction);
      if (summaryValue) {
        copyableContentLines.push(
          `\nResumen de la conversación: ${summaryValue}`
        );
      }
    }

    const otherExtractions = extractions.filter(
      (extraction) => extraction.configKey !== 'transcription.summary'
    );

    if (otherExtractions.length > 0) {
      copyableContentLines.push('');
      otherExtractions.forEach((extraction) => {
        const description = getExtractionDescription(extraction);
        const value = getExtractionValue(extraction);
        if (description && value) {
          copyableContentLines.push(`${description}: ${value}`);
        }
      });
    }

    const copyableContent = copyableContentLines.join('\n');

    onCopyableContentChange && onCopyableContentChange(copyableContent);
  }, [
    loading,
    analysisValues,
    latestContactQualiScore,
    publicScores,
    extractions,
    onCopyableContentChange,
  ]);
};
