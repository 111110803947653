// src/hooks/opportunity-tasks.ts

import { useCreateTask, useUpdateTask } from './api/tasks';
import { ApiQueryParams, NewTask, Task, TaskableType } from 'src/api/generated';
import { useAlert } from 'src/contexts/alert-context';

export const useAddOpportunityTask = (tasksQueryParams?: ApiQueryParams) => {
  const { showAlert } = useAlert();
  // TODO: check when implementing i18n
  const handleUserCreateSuccess = () => {
    showAlert('Se creó un recordatorio exitosamente.', 'success');
  };
  const handleUserCreateError = () => {
    showAlert(
      `Ocurrió un error intentando crear el recordatorio. \
      Por favor revisa tu conexión e intenta nuevamente.`,
      'error'
    );
  };
  const cacheKey = tasksQueryParams ? [tasksQueryParams] : [];
  const { createTask } = useCreateTask(
    handleUserCreateSuccess,
    handleUserCreateError,
    cacheKey
  );

  const addOpportunityTask = (opportunityId: number, date?: Date) => {
    const dueDate = date ? date.toISOString() : new Date().toISOString();
    const newTask: NewTask = {
      dueDate,
      taskableId: opportunityId,
      taskableType: TaskableType.OPPORTUNITY,
    };
    createTask({ newTask });
  };

  return { addOpportunityTask };
};

export const useCompleteOpportunityTask = (
  tasksQueryParams?: ApiQueryParams
) => {
  const { showAlert } = useAlert();
  // TODO: check when implementing i18n
  const handleUserUpdateSuccess = () => {
    showAlert(
      'Se marcó el recordatorio como completado exitosamente.',
      'success'
    );
  };
  const handleUserUpdateError = () => {
    showAlert(
      `Ocurrió un error actualizando el recordatorio. \
      Por favor revisa tu conexión e intenta nuevamente.`,
      'error'
    );
  };
  const cacheKey = tasksQueryParams ? [tasksQueryParams] : [];
  const { updateTask } = useUpdateTask(
    handleUserUpdateSuccess,
    handleUserUpdateError,
    cacheKey
  );

  const completeOpportunityTask = (taskId: number) => {
    const updatedTask: Partial<Task> = {
      completedAt: new Date().toISOString(),
    };
    updateTask({
      taskId,
      updatedTask,
    });
  };

  return { completeOpportunityTask };
};
