// src/components/molecules/status-select/status-select.tsx

import ControlledSelect from 'components/atoms/forms/controlled-select';
import { useUpdateOpportunity } from 'hooks/api/opportunities';
import { useCallback, useMemo } from 'react';
import { ApiQueryParams, FunnelStatus, Opportunity } from 'src/api/generated';
import { useAlert } from 'src/contexts/alert-context';

interface StatusSelectProps {
  statuses: FunnelStatus[];
  opportunity: Opportunity;
  opportunitiesQueryParams?: ApiQueryParams;
}

const StatusSelect: React.FC<StatusSelectProps> = ({
  statuses,
  opportunity,
  opportunitiesQueryParams,
}) => {
  // TODO: move logic to hooks
  const opportunityStatus = useCallback((): FunnelStatus | undefined => {
    const funnelStatus = statuses.find(
      (status) => status.id === opportunity.funnelStatusId
    );
    return funnelStatus;
  }, [opportunity.funnelStatusId, statuses]);

  const selectValue = useMemo(() => {
    const defaultStatus = opportunityStatus() || statuses[0];
    if (!defaultStatus) {
      return;
    }
    return defaultStatus.id.toString();
  }, [opportunityStatus, statuses]);

  const { showAlert } = useAlert();

  // TODO: check when implementing i18n
  const handleUserUpdateSuccess = () => {
    showAlert('El estado se ha actualizado correctamente.', 'success');
  };
  const handleUserUpdateError = () => {
    showAlert(
      `Ocurrió un error actualizando el estado. Por favor revisa tu conexión e intenta nuevamente.`,
      'error'
    );
  };
  const cacheKey = opportunitiesQueryParams ? [opportunitiesQueryParams] : [];
  const { updateOpportunity } = useUpdateOpportunity(
    handleUserUpdateSuccess,
    handleUserUpdateError,
    cacheKey
  );

  const funnelStatusSelectOptions = useMemo(() => {
    const options = statuses.map((status) => ({
      text: status.name,
      value: status.id.toString(),
    }));
    return options;
  }, [statuses]);

  const handleStatusSelected = useCallback(
    (selectedValue: string | undefined) => {
      if (!selectedValue) {
        return;
      }
      const newFunnelStatusId = parseInt(selectedValue);
      const updatedOpportunity: Partial<Opportunity> = {
        funnelStatusId: newFunnelStatusId,
      };
      updateOpportunity({
        opportunityId: opportunity.id,
        updatedOpportunity,
      });
    },
    [opportunity.id, updateOpportunity]
  );

  return (
    <ControlledSelect
      options={funnelStatusSelectOptions}
      selectedValue={selectValue}
      onSelectionChange={handleStatusSelected}
    />
  );
};

export default StatusSelect;
