/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessageDirection } from '../models/MessageDirection';
import type { MessagesListResponse } from '../models/MessagesListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MessagingMessagesService {

    /**
     * @param conversationId
     * @param limit
     * @param pageToken
     * @param direction
     * @param tenantId
     * @returns MessagesListResponse Ok
     * @throws ApiError
     */
    public static getMessages(
        conversationId: string,
        limit?: number,
        pageToken?: string,
        direction?: MessageDirection,
        tenantId?: string,
    ): CancelablePromise<MessagesListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/messaging/conversations/{conversationId}/messages',
            path: {
                'conversationId': conversationId,
            },
            query: {
                'limit': limit,
                'pageToken': pageToken,
                'direction': direction,
                'tenantId': tenantId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden.`,
            },
        });
    }

}
