import { Conversation } from 'src/api/generated';
import { Card, CardBody, Typography } from '@material-tailwind/react';
import ConversationStatusDisplay from 'src/modules/messaging/components/atoms/conversations/conversation-status-display';
import ConversationExpirationDisplay from 'src/modules/messaging/components/atoms/conversations/conversation-expiration-display';
import { formatTimeDistance } from 'src/modules/shared/utils/formatting/date.util';
import { usePeriodicRerender } from 'src/modules/shared/hooks/render';

interface ConversationItemProps {
  conversation: Conversation;
  isSelected: boolean;
}

const ConversationItem: React.FC<ConversationItemProps> = ({
  conversation,
  isSelected = false,
}) => {
  usePeriodicRerender(5000);

  const cardBaseClasses =
    'shadow-sm border rounded-none border-gray-100 hover:bg-gray-100 transition-colors duration-300 cursor-pointer';
  const cardBodyClasses = 'flex flex-col p-4 space-y-2';
  const rowClasses = 'flex justify-between items-center';
  const titleClasses = 'font-semibold text-md';
  const timestampClasses = 'text-gray-500 text-sm text-right';
  const messagePreviewClasses =
    'text-sm text-gray-500 truncate min-h-[1rem] md:pr-12 !mt-2 !mb-1';
  const participantClasses = 'text-gray-500 text-xs';
  const statusContainerClasses = 'flex items-center gap-1 justify-end';

  const timestamp = formatTimeDistance(
    new Date(conversation.lastMessageAt),
    false
  );

  return (
    <Card className={`${cardBaseClasses} ${isSelected ? 'bg-gray-200' : ''}`}>
      <CardBody className={cardBodyClasses}>
        <div className={rowClasses}>
          <Typography className={titleClasses}>{conversation.title}</Typography>
          <Typography className={timestampClasses}>{timestamp}</Typography>
        </div>

        <Typography className={messagePreviewClasses}>
          {conversation.lastMessage?.content || ''}
        </Typography>

        <div className={rowClasses}>
          <Typography className={participantClasses}>
            {conversation.mainParticipant.address}
          </Typography>
          <div className={statusContainerClasses}>
            <ConversationExpirationDisplay conversation={conversation} />
            <ConversationStatusDisplay status={conversation.status} size="sm" />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ConversationItem;
