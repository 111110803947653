import React from 'react';
import { Tabs, TabsHeader, Tab } from '@material-tailwind/react';
import MessageLoading from 'components/atoms/loading/message-loading';

type LoadedTab = {
  key: string;
  label: string;
  content: React.ReactNode;
  loading?: boolean;
};

type LoadedTabsProps = {
  tabs: LoadedTab[];
  selectedTab: string;
  onSelectTab: (key: string) => void;
};

/**
 * A React component that serves as a wrapper for the Tabs component from @material-tailwind/react.
 * This component ensures that all tabs remain loaded at all times, preventing unmounting when switching tabs.
 * This design choice allows for faster navigation between tabs as the content is preloaded and does not require
 * additional fetching or rendering.
 *
 * @component
 * @param props - The properties object.
 * @param props.tabs - An array of tab objects, each containing a key, label, content, and an optional loading state.
 * @param props.selectedTab - The key of the currently selected tab.
 * @param props.onSelectTab - Callback function to handle tab selection changes.
 *
 * @returns The LoadedTabs component.
 */
const LoadedTabs: React.FC<LoadedTabsProps> = ({
  tabs,
  selectedTab,
  onSelectTab,
}) => {
  return (
    <Tabs value={selectedTab} className="h-full">
      <TabsHeader>
        {tabs.map((tab) => (
          <Tab
            key={tab.key}
            value={tab.key}
            onClick={() => onSelectTab(tab.key)}
          >
            {tab.label}
          </Tab>
        ))}
      </TabsHeader>
      <div className="h-full mt-1">
        {tabs.map((tab) => (
          <div
            key={tab.key}
            style={{
              display: selectedTab === tab.key ? 'block' : 'none',
            }}
            className="h-full overflow-y-auto"
          >
            {tab.loading ? <MessageLoading /> : tab.content}
          </div>
        ))}
      </div>
    </Tabs>
  );
};

export default LoadedTabs;
