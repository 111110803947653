import { createFileRoute } from '@tanstack/react-router';
import AuthenticateRoute from 'components/organisms/app-layouts/authenticate-route';
import SettingsView from 'components/views/settings-view';

export const Route = createFileRoute('/settings/')({
  component: SettingsIndexComponent,
});

// Example to add nested routes
// https://tanstack.com/router/latest/docs/framework/react/examples/basic-file-based
function SettingsIndexComponent() {
  return (
    <AuthenticateRoute>
      <SettingsView />
    </AuthenticateRoute>
  );
}
