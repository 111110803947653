// src/hooks/api/useOpportunities.ts

import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from './api';
import {
  ApiQueryParams,
  Funnel,
  FunnelsService,
  FunnelStatus,
} from 'src/api/generated';
import { useCallback } from 'react';

interface FunnelsResponse extends ApiResponse {
  funnels: Funnel[];
}

export const useFunnels = (): FunnelsResponse => {
  const fetchFunnels = async (): Promise<Funnel[]> => {
    return await FunnelsService.getFunnels();
  };

  const {
    data: funnels,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['funnels'],
    queryFn: fetchFunnels,
  });

  return {
    loading,
    error,
    funnels: funnels || [],
    refetch,
  };
};

interface FunnelStatusesResponse extends ApiResponse {
  funnelStatuses: FunnelStatus[];
}

export const useFunnelStatuses = (
  funnelId: number | undefined,
  queryParams: ApiQueryParams = {}
): FunnelStatusesResponse => {
  const fetchFunnelStatutes = useCallback(
    async (funnelId: number | undefined): Promise<FunnelStatus[]> => {
      if (!funnelId) return [];
      const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

      return await FunnelsService.getFunnelStatuses(
        funnelId,
        filterBy,
        searchBy,
        sortBy,
        page,
        pageSize
      );
    },
    [queryParams]
  );

  const {
    data: funnelStatuses,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['funnelStatuses', funnelId, queryParams],
    queryFn: () => fetchFunnelStatutes(funnelId),
  });

  return {
    loading,
    error,
    funnelStatuses: funnelStatuses || [],
    refetch,
  };
};
