// src/components/molecules/phone/phone-config/phone-config/phone-config.tsx

import {
  AdjustmentsHorizontalIcon,
  MicrophoneIcon,
  PhoneIcon,
  SpeakerWaveIcon,
} from '@heroicons/react/24/solid';
import AudioDeviceSelect from 'components/atoms/audio-device-select';
import CallQualityRadio from 'components/atoms/call-quality-radio';
import InfoError from 'components/atoms/info-error';
import MessageLoading from 'components/atoms/loading/message-loading';
import PhoneNumberSelect from 'components/atoms/phone-number-select';
import { useAvailablePhones } from 'hooks/api/available-phones';
import { useAvailableAudioDevices } from 'hooks/phones/providers/twilio/audio-devices';
import { PhoneConfigActions } from 'hooks/phones/types';
import { useBooleanSetting } from 'hooks/settings/settings';
import { SettingName } from 'src/api/generated';
import { useAlert } from 'src/contexts/alert-context';
import {
  useAssignedPhone,
  useUpdateAssignedPhone,
} from 'src/hooks/api/assigned-phone';

interface PhoneConfigProps {
  phoneConfigActions: PhoneConfigActions;
}

const PhoneConfig: React.FC<PhoneConfigProps> = ({ phoneConfigActions }) => {
  const { showAlert } = useAlert();
  const {
    setting: assignedPhoneChoose,
    loading: assignedPhoneChooseLoading,
    error: assignedPhoneChooseError,
  } = useBooleanSetting(SettingName.ASSIGNED_PHONE_CHOOSE);

  const { inputDevices, outputDevices } = useAvailableAudioDevices();

  const {
    phones,
    error: availablePhonesError,
    loading: availablePhonesLoading,
  } = useAvailablePhones();
  const {
    phone,
    error: assignedPhoneError,
    loading: assignedPhoneLoading,
  } = useAssignedPhone();

  const handleUserUpdateSuccess = () => {
    showAlert('El número se ha seleccionado correctamente.', 'success');
  };
  const handleUserUpdateError = () => {
    showAlert(
      `Ocurrió un error seleccionando el número. Por favor revisa tu conexión e intenta nuevamente.`,
      'error'
    );
  };
  const {
    updateAssignedPhone,
    loading: updateAssignedPhoneLoading,
    error: updateAssignedPhoneError,
  } = useUpdateAssignedPhone(handleUserUpdateSuccess, handleUserUpdateError);

  const handlePhoneNumberChange = (newPhoneId: number) => {
    updateAssignedPhone({ phoneId: newPhoneId });
  };

  const {
    selectedInputDevice,
    setSelectedInputDevice,
    selectedOutputDevice,
    setSelectedOutputDevice,
    selectedDeviceBitrate,
    setDeviceBitrate,
  } = phoneConfigActions;

  const loading =
    availablePhonesLoading ||
    assignedPhoneLoading ||
    assignedPhoneChooseLoading;
  const error =
    availablePhonesError || assignedPhoneError || assignedPhoneChooseError;

  return (
    <div className="space-y-4 mx-4">
      {loading && <MessageLoading />}
      {!loading && error && <InfoError error={error} />}
      {!loading && !error && (
        <>
          <div className="flex space-x-2">
            <MicrophoneIcon className="h-5 w-5 text-gray-500" />
            <AudioDeviceSelect
              // TODO: check when implementing i18n
              label="Micrófono"
              devices={inputDevices}
              selectedDeviceId={selectedInputDevice}
              onChange={setSelectedInputDevice}
            />
          </div>
          <div className="flex space-x-2">
            <SpeakerWaveIcon className="h-5 w-5 text-gray-500" />
            <AudioDeviceSelect
              // TODO: check when implementing i18n
              label="Altavoz"
              devices={outputDevices}
              selectedDeviceId={selectedOutputDevice}
              onChange={setSelectedOutputDevice}
            />
          </div>
          <div className="flex space-x-2">
            <AdjustmentsHorizontalIcon className="h-5 w-5 text-gray-500" />
            <CallQualityRadio
              selectedDeviceBitrate={selectedDeviceBitrate}
              setSelectedDeviceBitrate={setDeviceBitrate}
            />
          </div>
          {assignedPhoneChoose && (
            <div className="flex space-x-2">
              {updateAssignedPhoneLoading && <MessageLoading />}
              {!updateAssignedPhoneLoading && updateAssignedPhoneError && (
                <InfoError error={updateAssignedPhoneError} />
              )}
              {!updateAssignedPhoneLoading && !updateAssignedPhoneError && (
                <>
                  <PhoneIcon className="h-5 w-5 text-gray-500" />
                  <PhoneNumberSelect
                    // TODO: check when implementing i18n
                    label="Mi número"
                    phones={phones}
                    selectedPhoneId={phone?.id}
                    onChange={handlePhoneNumberChange}
                  />
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PhoneConfig;
