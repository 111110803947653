export type FilterValue = string | number | boolean | null;
export type FilterOperator = '=' | '<' | '>';
export type FilterParam = {
  operator?: FilterOperator;
  value: FilterValue | Array<FilterValue>;
};
export type FilterParams = Record<string, FilterParam>;
export type SortParams = Record<string, 'asc' | 'desc'>;

const PARAM_SEPARATOR_SYMBOL: string = ';';
const SORT_PARAM_VALUE_SYMBOL: string = '=';
const DEFAULT_PARAM_VALUE_SYMBOL: string = '=';

export const generateFilterQueryString = (params: FilterParams): string => {
  const queryString: string[] = [];

  for (const [key, param] of Object.entries(params)) {
    const { operator, value } = param;
    let formattedValue: string;

    if (Array.isArray(value)) {
      formattedValue = `[${value.join(',')}]`;
    } else {
      formattedValue = String(value);
    }

    queryString.push(
      `${key}${operator || DEFAULT_PARAM_VALUE_SYMBOL}${formattedValue}`
    );
  }

  return queryString.join(PARAM_SEPARATOR_SYMBOL);
};

export const generateSortQueryString = (params: SortParams): string => {
  const queryString: string[] = [];

  for (const [key, value] of Object.entries(params)) {
    const formattedValue = value;
    queryString.push(`${key}${SORT_PARAM_VALUE_SYMBOL}${formattedValue}`);
  }

  return queryString.join(PARAM_SEPARATOR_SYMBOL);
};
