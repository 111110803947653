// src/hooks/api/reports.ts

import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from './api';
import { ReportsService } from 'src/api/generated';

interface RetoolReportsEmbedResponse extends ApiResponse {
  embedUrl: string | null;
}

export const useRetoolReportsEmbed = (
  enabled: boolean = true
): RetoolReportsEmbedResponse => {
  const fetchRetoolReportsEmbed = async (): Promise<string | null> => {
    return await ReportsService.getRetoolReportsEmbed();
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['reports/retool/embed'],
    queryFn: fetchRetoolReportsEmbed,
    enabled,
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  const embedUrl = data || null;

  return {
    loading: isLoading,
    error,
    embedUrl,
    refetch,
  };
};
