import React from 'react';
import { Template } from 'src/api/generated';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import Tag from 'src/modules/shared/components/atoms/tag';

interface TemplateListItemProps {
  template: Template;
  onEdit: (template: Template) => void;
  onDelete: (template: Template) => void;
}

const TemplateListItem: React.FC<TemplateListItemProps> = ({
  template,
  onEdit,
  onDelete,
}) => {
  return (
    <div className="flex items-center justify-between py-2 px-3 hover:bg-gray-50 rounded-lg group">
      <div className="flex-1 min-w-0">
        <div className="flex items-center gap-2">
          <h4 className="text-sm font-medium text-gray-900 truncate">
            {template.title}
          </h4>
          {template.category && (
            <Tag text={template.category} size="xs" variant="neutral" />
          )}
        </div>
        <p className="text-xs text-gray-500 truncate mt-0.5">
          {template.content}
        </p>
      </div>
      <div className="flex items-center gap-1 ml-4">
        <Button
          variant="plain"
          size="2xs"
          onClick={() => onEdit(template)}
          className="!p-1"
        >
          <PencilIcon className="h-3.5 w-3.5 text-gray-600" />
        </Button>
        <Button
          variant="plain"
          size="2xs"
          onClick={() => onDelete(template)}
          className="!p-1"
        >
          <TrashIcon className="h-3.5 w-3.5 text-gray-600" />
        </Button>
      </div>
    </div>
  );
};

export default TemplateListItem;
