import { useState } from 'react';
import { Button } from '@material-tailwind/react';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

interface DateTimePickerProps {
  onAcceptValue?: (date: Date | null) => void;
  className?: string;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  onAcceptValue,
  className,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button className={className} variant="outlined" onClick={onOpen}>
        <CalendarDaysIcon className="h-5 w-5" />
      </Button>
      {/* TODO: set texts to spanish */}
      <MobileDateTimePicker
        disablePast
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        value={selectedDate}
        onChange={setSelectedDate}
        onAccept={onAcceptValue}
        sx={{
          display: 'none',
        }}
      />
    </>
  );
};

export default DateTimePicker;
