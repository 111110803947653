import React from 'react';
import {
  ConversationChannel,
  Message,
  MessageDirection,
} from 'src/api/generated';
import AttachmentList from '../../attachments/attachment-list';
import WhatsAppText from 'src/modules/messaging/whatsapp/components/molecules/messages/whatsapp-text';
import MessageStatusIndicator from '../message-status-indicator';
import {
  formatDate,
  formatTime,
} from 'src/modules/shared/utils/formatting/date.util';
import { isToday } from 'date-fns';

interface MessageItemProps {
  message: Message;
}

export const MessageItem: React.FC<MessageItemProps> = ({ message }) => {
  const isOutbound = message.direction === MessageDirection.OUTBOUND;
  const containerClasses = `flex ${isOutbound ? 'justify-end' : 'justify-start'}`;
  const messageClasses = `max-w-md p-2 rounded-lg shadow-md break-words ${
    isOutbound ? 'bg-blue-500 text-white' : 'bg-gray-200'
  }`;
  const dateClasses = `text-xs mt-0.5 text-right ${
    isOutbound ? 'text-gray-300' : 'text-gray-500'
  }`;

  // TODO: refactor to avoid using a component of a submodule
  const MessageComponent = () => {
    if (!message.content) return null;

    if (message.channel === ConversationChannel.WHATSAPP) {
      return <WhatsAppText text={message.content} />;
    }

    return <p className="text-sm">{message.content}</p>;
  };
  const timestamp = isToday(new Date(message.createdAt))
    ? formatTime(message.createdAt, 'HH:MM')
    : `${formatDate(message.createdAt, 'DD-MM')} ${formatTime(message.createdAt, 'HH:MM')}`;

  return (
    <div className={containerClasses}>
      <div className={messageClasses}>
        {message.attachments && (
          <div className="mb-2">
            <AttachmentList attachments={message.attachments} />
          </div>
        )}
        <MessageComponent />
        <div className="flex items-center justify-end gap-1 mt-1">
          <p className={dateClasses}>{timestamp}</p>
          {isOutbound && <MessageStatusIndicator message={message} />}
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
