// src/components/organisms/opportunities-view/opportunities-view.tsx

import { ContactWithCalls, Opportunity, Task, User } from 'src/api/generated';
import ViewContainer from 'components/atoms/view-container';
import {
  TableColumnProps,
  TableRowProps,
} from 'components/molecules/tables/table';
import AdvancedTable from 'components/molecules/tables/advanced-table';
import { useOpportunitiesContacts } from 'hooks/api/contacts/contacts';
import { useAgents } from 'hooks/api/users';
import { useSelectedFunnel } from 'hooks/funnels';
import { useFunnelStatuses } from 'hooks/api/funnels';
import {
  useFilterOptions,
  useOpportunitiesQueryParams,
} from 'hooks/opportunities';
import { Typography } from '@material-tailwind/react';
import { generateSortQueryString } from 'src/utils/queryParams';
import PhoneNumberItem from 'components/molecules/phone/phone-number-item';
import {
  filterCompletedCalls,
  filterMissedCalls,
  getLastCall,
  getMaxQualiScore,
} from 'src/utils/calls';
import { parseDate } from 'src/utils/date';
import StatusSelect from 'components/molecules/opportunities/status-select';
import { useMemo } from 'react';
import Finder from 'components/molecules/finder';
import NotesTextArea from 'components/molecules/opportunities/notes-text-area';
import ViewFilter from 'components/atoms/view-filter';
import OpportunityTaskChip from 'components/molecules/tasks/opportunity-task-chip/opportunity-task-chip';
import { useActiveOpportunityTasksCount, useTasks } from 'hooks/api/tasks';
import { getOpportunityTaskSortingValue, isRecentTask } from 'src/utils/tasks';
import AgentSelect from 'components/molecules/agent-select';
import { useOpportunities } from 'hooks/api/opportunities';
import { useCurrentUser } from 'hooks/users';
import { formatTimeDistance } from 'src/modules/shared/utils/formatting/date.util';
import { formatFullName, formatName } from 'src/utils/formatting/name';
import { useControlSorting } from 'hooks/tables';
import BadgeTooltipButton from 'components/atoms/badge-tooltip-button';
import ContactQualiChip from 'components/molecules/analysis/contact-quali-chip';
import { useCurrentCompany } from 'hooks/api/companies';
import { useAnalysisValuesFromContacts } from 'hooks/analysis/contacts';

const DEFAULT_SORT_FIELD = 'lastCallTime';
const DEFAULT_SORT_ORDER = 'asc';

// TODO: implement backend data filtering here
const OpportunitiesView: React.FC = () => {
  const { currentCompany } = useCurrentCompany();

  // TODO: move logic to hooks
  const {
    selectedFunnel,
    loading: selectedFunnelLoading,
    error: selectedFunnelError,
  } = useSelectedFunnel();
  const selectedFunnelId: number | undefined = selectedFunnel?.id;
  const sortBy = generateSortQueryString({ position: 'asc' });
  const {
    funnelStatuses,
    loading: funnelStatusesLoading,
    error: funnelStatusesError,
  } = useFunnelStatuses(selectedFunnelId, { sortBy });

  const { currentFilterIndex, setCurrentFilterIndex, filterOptions } =
    useFilterOptions(funnelStatuses);

  const { sortField, setSortField, sortOrder, setSortOrder } =
    useControlSorting(DEFAULT_SORT_FIELD, DEFAULT_SORT_ORDER);

  const { users, loading: usersLoading, error: usersError } = useAgents();
  const { currentUser } = useCurrentUser();
  const { queryParams, selectedAgentId, sendAgentSelection, sendSearch } =
    useOpportunitiesQueryParams(users, currentUser, selectedFunnelId);

  const {
    opportunities,
    loading: opportunitiesLoading,
    error: opportunitiesError,
  } = useOpportunities(queryParams);
  const { count: overdueTasks } = useActiveOpportunityTasksCount(
    queryParams,
    true
  );
  const { count: activeTasks } = useActiveOpportunityTasksCount(queryParams);

  const goToTasks = () => {
    setCurrentFilterIndex(0);
    setSortField('opportunityTask');
    setSortOrder('asc');
  };

  const {
    contacts,
    loading: contactsLoading,
    error: contactsError,
  } = useOpportunitiesContacts(queryParams);

  const { analysisValueViews: analysisValues } =
    useAnalysisValuesFromContacts(contacts);

  // TODO: if query params are added to this endpoint call, the key must be passed
  // to the OpportunityTaskChip component, in order to update the cache
  const { tasks, loading: tasksLoading, error: tasksError } = useTasks();

  const loading =
    selectedFunnelLoading ||
    funnelStatusesLoading ||
    opportunitiesLoading ||
    contactsLoading ||
    usersLoading ||
    tasksLoading;
  const error =
    selectedFunnelError ||
    funnelStatusesError ||
    opportunitiesError ||
    contactsError ||
    usersError ||
    tasksError;

  // TODO: check when implementing i18n
  const columns: TableColumnProps[] = [
    {
      header: 'Contacto',
      accessor: 'contactName',
    },
    {
      header: 'Teléfono',
      accessor: 'contactPhone',
    },
    {
      header: 'Calificación prospecto (0-10)',
      accessor: 'maxQualiScore',
    },
    {
      header: 'Llamadas efectivas',
      accessor: 'completedCalls',
    },
    {
      header: 'Llamadas perdidas',
      accessor: 'missedCalls',
    },
    {
      header: 'Tiempo desde última llamada',
      accessor: 'lastCallTime',
    },
    {
      header: 'Llamar en',
      accessor: 'opportunityTask',
    },
    {
      header: 'Agente asignado',
      accessor: 'agent',
    },
    {
      header: 'Estado',
      accessor: 'funnelStatus',
    },
    {
      header: 'Observaciones',
      accessor: 'actions',
    },
  ];

  // Should these functions be inside opportunity's utils/model file?
  const opportunityContact = (
    opportunity: Opportunity
  ): ContactWithCalls | undefined => {
    return contacts.find((contact) => contact.id === opportunity.contactId);
  };
  const opportunityUser = (opportunity: Opportunity): User | undefined => {
    return users.find((contact) => contact.id === opportunity.userId);
  };
  const opportunityLastTask = (opportunity: Opportunity): Task | null => {
    const opportunityTasks = tasks.filter(
      (task) => task.taskableId === opportunity.id && !task.completedAt
    );
    const lastTask: Task | undefined =
      opportunityTasks[opportunityTasks.length - 1];

    return lastTask;
  };

  const currentTime = useMemo(() => new Date().getTime(), []);
  const buildOpportunitiesRows = (): TableRowProps[] => {
    const opportunitiesRows = opportunities.map((opportunity: Opportunity) => {
      const contact = opportunityContact(opportunity);
      const user = opportunityUser(opportunity);
      const lastTask = opportunityLastTask(opportunity);

      const statusId = opportunity.funnelStatusId;
      const statusElement = () => {
        return (
          <StatusSelect
            statuses={funnelStatuses}
            opportunity={opportunity}
            opportunitiesQueryParams={queryParams}
          />
        );
      };

      const contactPhone = contact?.phone || '';
      const phoneElement = () => {
        return (
          <PhoneNumberItem
            phoneNumber={contactPhone}
            userName={formatName(currentUser?.firstName)}
            companyName={formatName(currentCompany?.name)}
          />
        );
      };

      const calls = contact?.calls || [];

      const maxQualiScore = getMaxQualiScore(calls, analysisValues);

      const completedCallsNumber = filterCompletedCalls(calls).length;
      const missedCallsNumber = filterMissedCalls(calls).length;
      const missedCallsElement = () => {
        return (
          <Typography variant="small" className="font-normal text-red-600">
            {missedCallsNumber}
          </Typography>
        );
      };
      const lastCall = getLastCall(calls);
      const lastCallDate = parseDate(lastCall?.startTime);
      const timeSinceLastCall = lastCallDate
        ? currentTime - lastCallDate.getTime()
        : Number.POSITIVE_INFINITY;
      const lastCallTimeElement = () => {
        const lastCallTime = lastCallDate
          ? formatTimeDistance(lastCallDate)
          : '';
        return (
          <Typography variant="small" className="font-normal">
            {lastCallTime}
          </Typography>
        );
      };

      const actionsElement = () => (
        <NotesTextArea
          opportunity={opportunity}
          opportunitiesQueryParams={queryParams}
        />
      );

      const isRecent = isRecentTask(timeSinceLastCall);
      const taskSortingValue = getOpportunityTaskSortingValue(lastTask);

      const opportunityTaskElement = () => {
        const dueDate = lastTask?.dueDate ? new Date(lastTask.dueDate) : null;
        const taskCompleted = !!lastTask?.completedAt;

        return (
          <OpportunityTaskChip
            taskId={lastTask?.id}
            opportunityId={opportunity.id}
            taskCompleted={taskCompleted}
            dueDate={dueDate}
            isRecent={isRecent}
          />
        );
      };

      const tableRow: TableRowProps = {
        key: opportunity.id.toString(),
        row: [
          {
            accesor: 'contactName',
            value: formatFullName(contact?.firstName, contact?.lastName),
          },
          {
            accesor: 'contactPhone',
            value: contactPhone,
            element: phoneElement(),
          },
          {
            accesor: 'maxQualiScore',
            value: maxQualiScore ?? -1,
            element: <ContactQualiChip value={maxQualiScore} />,
          },
          {
            accesor: 'completedCalls',
            value: completedCallsNumber,
          },
          {
            accesor: 'missedCalls',
            value: missedCallsNumber,
            element: missedCallsElement(),
          },
          {
            accesor: 'lastCallTime',
            value: timeSinceLastCall,
            element: lastCallTimeElement(),
          },
          {
            accesor: 'agent',
            value: formatFullName(user?.firstName, user?.lastName),
          },
          {
            accesor: 'funnelStatus',
            value: statusId,
            element: statusElement(),
          },
          {
            accesor: 'actions',
            value: opportunity.notes || '',
            element: actionsElement(),
          },
          {
            accesor: 'opportunityTask',
            value: taskSortingValue,
            element: opportunityTaskElement(),
          },
        ],
      };
      return tableRow;
    });

    return opportunitiesRows;
  };

  const viewHeaderElement = (
    <ViewFilter>
      <AgentSelect
        agents={users}
        selectedAgent={selectedAgentId}
        onAgentSelectionChange={sendAgentSelection}
      />
      <Finder onSearch={sendSearch} />
    </ViewFilter>
  );

  const tableHeaderElement = (
    <BadgeTooltipButton
      // TODO: check when implementing i18n
      buttonText="Ir a recordatorios"
      badgeText={overdueTasks > 0 ? overdueTasks.toString() : null}
      badgeColor={overdueTasks > 0 ? 'red' : 'green'}
      tooltipContent={
        activeTasks > 0
          ? `${overdueTasks} de ${activeTasks} recordatorios expirados.`
          : null
      }
      onClick={goToTasks}
    />
  );

  return (
    // TODO: check when implementing i18n
    <ViewContainer
      title="Seguimiento"
      headerElement={viewHeaderElement}
      loading={loading}
      error={error}
    >
      <AdvancedTable
        columns={columns}
        data={buildOpportunitiesRows()}
        filterOptions={filterOptions}
        controlledFilterIndex={currentFilterIndex}
        setControlledFilterIndex={setCurrentFilterIndex}
        controlledSortField={sortField}
        setControlledSortField={setSortField}
        controlledSortOrder={sortOrder}
        setControlledSortOrder={setSortOrder}
        rowsPerPage={8}
        countRecords={true}
        headerElement={tableHeaderElement}
      />
    </ViewContainer>
  );
};

export default OpportunitiesView;
