import React, { ComponentProps } from 'react';
import { ComponentSize } from 'src/modules/shared/types/component.type';
import { ComponentVariant } from 'src/modules/shared/types/component.type';

type LabelProps = ComponentProps<'label'> & {
  className?: string;
  variant?: ComponentVariant;
  size?: ComponentSize;
};

const Label: React.FC<LabelProps> = ({
  children,
  className = '',
  variant = 'primary',
  size = 'md',
  ...rest
}) => {
  const baseClasses = ['flex-1', 'font-medium', 'block'];

  const variantClasses: Record<ComponentVariant, string> = {
    primary: 'text-gray-700',
    secondary: 'text-gray-500',
    danger: 'text-red-500',
    warning: 'text-yellow-500',
    plain: 'text-gray-700',
  };

  const sizeClasses: Record<ComponentSize, string> = {
    lg: 'py-2 px-4 text-md',
    md: 'py-2 px-4 text-sm',
    sm: 'py-1 px-3 text-sm',
    xs: 'py-1 px-2 text-xs',
    '2xs': 'py-1 px-2 text-[0.7rem] text-xs',
  };

  const classes = [
    ...baseClasses,
    variantClasses[variant],
    sizeClasses[size],
    className,
  ].join(' ');

  return (
    <label className={classes} {...rest}>
      {children}
    </label>
  );
};

export default Label;
