// src/components/molecules/analysis/feedback-modal-content/feedback-modal-content.tsx

import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { useMemo } from 'react';
import { getWeaknesses } from 'src/utils/analysisValues/weaknesses/weaknesses';
import { getStrengths } from 'src/utils/analysisValues/strengths/strengths';
import { getGoals } from 'src/utils/analysisValues/goals/goals';
import GoalRow from './goal-row';
import StrengthRow from './strength-row';
import WeaknessRow from './weakness-row';
import MessageLoading from 'components/atoms/loading/message-loading';
import { FilterParam, generateFilterQueryString } from 'src/utils/queryParams';
import { useAnalysisValueViews } from 'hooks/api/analysis-values/analysis-value-views';
import TextModalContent from '../text-modal-content';

interface FeedbackModalContentProps {
  callId: number;
  enabled?: boolean;
}

// TODO: check when implementing i18n
const DEFAULT_MESSAGE = `El feedback puede tardar hasta 1 minuto en aparecer. Solamente las llamadas \
efectivas generan feedback.`;

const FeedbackModalContent: React.FC<FeedbackModalContentProps> = ({
  callId,
  enabled = true,
}) => {
  const filters: Record<string, FilterParam> = {
    callId: { value: callId },
  };
  const filterBy = generateFilterQueryString(filters);
  const { analysisValueViews: analysisValues, loading } = useAnalysisValueViews(
    { filterBy },
    enabled
  );

  const goals = useMemo(() => getGoals(analysisValues), [analysisValues]);
  const strengths = useMemo(
    () => getStrengths(analysisValues),
    [analysisValues]
  );
  const weaknesses = useMemo(
    () => getWeaknesses(analysisValues),
    [analysisValues]
  );

  if (loading) {
    return <MessageLoading />;
  }
  if (!goals.length && !strengths.length && !weaknesses.length) {
    return (
      <div className="px-2 md:py-2 md:px-4">
        <TextModalContent content={DEFAULT_MESSAGE} />
      </div>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="simple table">
        <TableBody>
          {goals.map((goal) => (
            <GoalRow key={goal.id} goal={goal} />
          ))}
          {strengths.map((strength) => (
            <StrengthRow key={strength.id} strength={strength} />
          ))}
          {weaknesses.map((weakness) => (
            <WeaknessRow key={weakness.id} weakness={weakness} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FeedbackModalContent;
