/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Funnel } from '../models/Funnel';
import type { FunnelStatus } from '../models/FunnelStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FunnelsService {

    /**
     * @returns Funnel Ok
     * @throws ApiError
     */
    public static getFunnels(): CancelablePromise<Array<Funnel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/funnels',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param funnelId
     * @param filterBy Filter criteria in the format "field:value;field2<value2;field3:[value3,value4,value5]" for exact matches.
     * @param searchBy Search criteria in the format "name lastname" for partial matches.
     * @param sortBy Sorting criteria in the format "field:asc;field2:desc".
     * @param page The page number in pagination.
     * @param pageSize The number of items per page in pagination.
     * @returns FunnelStatus Ok
     * @throws ApiError
     */
    public static getFunnelStatuses(
        funnelId: number,
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<Array<FunnelStatus>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/funnels/{funnelId}/statuses',
            path: {
                'funnelId': funnelId,
            },
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

}
