import { ReactNode } from 'react';
import withAuthentication from 'src/hocs/withAuthentication';
import TimezoneProvider from './timezone-sync-provider';

interface AuthenticatedProvidersProps {
  children: ReactNode;
}

const AuthenticatedProviders: React.FC<AuthenticatedProvidersProps> = ({
  children,
}) => {
  const AuthenticatedTimezoneProvider = withAuthentication(TimezoneProvider);

  return (
    <AuthenticatedTimezoneProvider>
      {/* Add other providers that require authentication here */}
      {children}
    </AuthenticatedTimezoneProvider>
  );
};

export default AuthenticatedProviders;
