import { useState, useEffect } from 'react';
import { IntegrationAddress } from 'src/api/generated';
import { useWhatsAppPhones } from 'src/modules/messaging/whatsapp/hooks/api/phones';
import { standardizePhoneNumber } from 'src/utils/formatting/phone';

interface UseSelectedPhoneProps {
  initialPhoneId?: string;
}

interface UseSelectedPhoneReturn {
  phones: IntegrationAddress[];
  loading: boolean;
  error: Error | null;
  selectedPhone: IntegrationAddress | undefined;
  handlePhoneChange: (phoneId?: string) => void;
}

export const useSelectedPhone = ({
  initialPhoneId,
}: UseSelectedPhoneProps): UseSelectedPhoneReturn => {
  const { phones, loading, error } = useWhatsAppPhones();

  const [selectedPhone, setSelectedPhone] = useState<
    IntegrationAddress | undefined
  >(undefined);

  useEffect(() => {
    if (initialPhoneId) {
      const initialPhone = phones.find(
        (phone) => phone.providerId === initialPhoneId
      );
      setSelectedPhone(initialPhone);
    }
  }, [initialPhoneId, phones]);

  const handlePhoneChange = (phoneId?: string) => {
    const selectedPhone = phones.find((phone) => phone.providerId === phoneId);
    setSelectedPhone(selectedPhone);
  };

  return {
    phones,
    loading,
    error,
    selectedPhone,
    handlePhoneChange,
  };
};

interface UsePhoneNumberInputProps {
  initialNumber: string;
}

interface UsePhoneNumberInputReturn {
  phoneNumber: string;
  isValid: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setPhoneNumber: (number: string) => void;
}

export const usePhoneNumberInput = ({
  initialNumber,
}: UsePhoneNumberInputProps): UsePhoneNumberInputReturn => {
  const [phoneNumber, setPhoneNumber] = useState(initialNumber);
  const [isValid, setIsValid] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = e.target.value;
    const standardizedNumber = standardizePhoneNumber(number);
    const isValid = !!standardizedNumber;
    setIsValid(isValid);
    setPhoneNumber(number);
  };

  return {
    phoneNumber,
    isValid,
    handleChange,
    setPhoneNumber,
  };
};
