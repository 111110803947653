import { Conversation } from 'src/api/generated';
import { ExpirationInfo } from '../../types/conversations/expiration.type';

export const getConversationExpiration = (
  conversation: Conversation
): Date | undefined => {
  const expirationTime: string | undefined =
    conversation.integrationsFields?.expirationTime;
  if (!expirationTime) return undefined;

  return new Date(expirationTime);
};

export const getConversationExpirationInfo = (
  conversation: Conversation
): ExpirationInfo => {
  const expirationDate = getConversationExpiration(conversation);
  if (!expirationDate) {
    return { isExpired: false, timeRemaining: null };
  }

  const now = new Date();
  const timeRemaining = expirationDate.getTime() - now.getTime();

  return {
    isExpired: timeRemaining <= 0,
    timeRemaining: timeRemaining > 0 ? timeRemaining : 0,
  };
};

export const isConversationExpired = (conversation: Conversation): boolean => {
  return getConversationExpirationInfo(conversation).isExpired;
};

export const getTimeUntilExpiration = (
  conversation: Conversation
): number | null => {
  return getConversationExpirationInfo(conversation).timeRemaining;
};

export const formatExpirationTime = (timeRemaining: number): string => {
  if (timeRemaining <= 0) return '0m';

  const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));

  if (hours > 24) {
    const days = Math.floor(hours / 24);
    return `${days}d`;
  }

  if (hours > 0) {
    return `${hours}h`;
  }

  return `${Math.max(minutes, 1)}m`;
};
