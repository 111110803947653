/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DownloadType } from '../models/DownloadType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DownloadsService {

    /**
     * @param downloadType
     * @returns string Ok
     * @throws ApiError
     */
    public static createDownload(
        downloadType: DownloadType,
    ): CancelablePromise<string | null> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/downloads',
            query: {
                'downloadType': downloadType,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

}
