import { useNetworkState } from '@uidotdev/usehooks';

const RTT_THRESHOLD = 250;
const BANDWIDTH_THRESHOLD = 1.5;

export const useNetworkIndicator = () => {
  const network = useNetworkState();

  const hasBadConnection =
    network.effectiveType && network.effectiveType !== '4g';
  const hasHighLatency = network.rtt && network.rtt > RTT_THRESHOLD;
  const hasLowBandwidth =
    network.downlink && network.downlink < BANDWIDTH_THRESHOLD;
  const isOnline = network.online;

  return {
    isOnline,
    hasHighLatency,
    hasLowBandwidth,
    hasBadConnection,
    network,
  };
};
