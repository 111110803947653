import { Call } from '@twilio/voice-sdk';
import { CallData } from 'hooks/phones/types';

export const mapTwilioCallToCallData = (call: Call): CallData => {
  return {
    providerId: call.parameters.CallSid,
    providerName: 'twilio',
    agent: call.customParameters.get('agent'),
    to: call.customParameters.get('To'),
    direction: call.direction.toLowerCase(),
    callProviderData: JSON.parse(
      call.customParameters.get('callProviderData') || '{}'
    ),
    callCustomData: JSON.parse(
      call.customParameters.get('callCustomData') || '{}'
    ),
  };
};
