// src/hooks/api/calls/call-transcriptions.ts

import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from 'hooks/api/api';
import { CallsService, Transcription } from 'src/api/generated';

interface CallTranscriptionResponse extends ApiResponse {
  transcription: Transcription | null;
}

export const useCallTranscription = (
  callId: number,
  enabled = true
): CallTranscriptionResponse => {
  const fetchCallTranscription = async (): Promise<Transcription | null> => {
    return await CallsService.getCallTranscription(callId);
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['calls/transcription', { callId }],
    queryFn: fetchCallTranscription,
    enabled,
  });

  return {
    loading,
    error,
    transcription: data || null,
    refetch,
  };
};
