import { SettingName, SettingProps } from 'src/api/generated';
import { useSettings } from '../api/settings';
import { useMemo } from 'react';
import { useCurrentUser } from 'hooks/users';

export const useFeatureFlagActive = (settingName: SettingName) => {
  const { loading, error, settings } = useSettings();

  // TODO: fix when implementing admin/profiles
  const { currentUser } = useCurrentUser();
  const isAdmin = currentUser?.profile === 'admin';

  const isFlagActive: boolean = useMemo(
    () =>
      settings.some((setting: SettingProps) => {
        if (setting.name === settingName) {
          if (setting.value === 'active') {
            return true;
          }
          if (setting.value === 'active-admin') {
            return isAdmin;
          }
        }
        return false;
      }),
    [settings, settingName, isAdmin]
  );

  if (loading || error) {
    return false;
  }

  return isFlagActive;
};
