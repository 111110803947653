import React from 'react';
import InlineTextInput from '../inline-text-input';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { XCircleIcon } from '@heroicons/react/24/outline';

interface CancellableTextInputProps {
  value: string;
  onChange: (newValue: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  className?: string;
  placeholder?: string;
}

const CancellableTextInput: React.FC<CancellableTextInputProps> = ({
  value,
  onChange,
  onKeyDown,
  onClear,
  className,
  placeholder,
}) => {
  return (
    <div className="flex h-fit relative w-full">
      <InlineTextInput
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        className={`pr-8 pl-2 ${className}`}
        containerClassName="min-w-0"
      />
      {value && (
        <Button onClick={onClear} className={`w-8 h-8 absolute right-0`}>
          <XCircleIcon strokeWidth={1} className={`h-6 w-6 text-red-800`} />
        </Button>
      )}
    </div>
  );
};

export default CancellableTextInput;
