const PHONE = {
  CONNECTING: 'Connecting',
  REGISTERING: 'Registering',
  READY: 'Ready',
  INCOMING: 'Incoming',
  ON_CALL: 'On call',
  OFFLINE: 'Offline',
  ERROR: 'Error',
};

export default PHONE;
