import { Tooltip as MaterialTailwindTooltip } from '@material-tailwind/react';
import { ReactNode, ComponentProps } from 'react';

type TooltipProps = Omit<
  ComponentProps<typeof MaterialTailwindTooltip>,
  'className' | 'content'
> & {
  content: string;
  children: ReactNode;
  className?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  variant?: 'primary' | 'secondary';
  size?: 'md' | 'sm' | 'xs';
};

const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  className = '',
  variant = 'primary',
  size = 'md',
  placement = 'top',
  ...rest
}) => {
  const baseClasses = ['rounded', 'font-medium'];

  const variantClasses = {
    primary: 'bg-gray-800 text-white',
    secondary: 'bg-gray-600 text-gray-100',
  };

  const sizeClasses = {
    md: 'py-2 px-4 text-sm',
    sm: 'py-1 px-3 text-xs',
    xs: 'py-0.5 px-2 text-xs',
  };

  const classes = [
    ...baseClasses,
    variantClasses[variant],
    sizeClasses[size],
    className,
  ].join(' ');

  return (
    <MaterialTailwindTooltip
      content={content}
      placement={placement}
      className={classes}
      {...rest}
    >
      {children}
    </MaterialTailwindTooltip>
  );
};

export default Tooltip;
