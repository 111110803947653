// src/hooks/api/call-summaries.ts

import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from './api';
import { CallsService, DailyCallSummary } from 'src/api/generated';
import { MINUTE_IN_MS } from 'src/utils/date';

interface CallSummariesResponse extends ApiResponse {
  summaries: DailyCallSummary[];
}

export const useCallSummaries = (
  startDate: string,
  endDate: string
): CallSummariesResponse => {
  const fetchCallSummaries = async (): Promise<DailyCallSummary[]> => {
    return await CallsService.getCallSummaries(startDate, endDate);
  };

  const {
    data: summaries,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['callSummaries', startDate, endDate],
    queryFn: fetchCallSummaries,
    staleTime: MINUTE_IN_MS * 5,
  });

  return {
    loading: isLoading,
    error,
    summaries: summaries || [],
    refetch,
  };
};
