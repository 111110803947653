import WhatsappButton from 'components/atoms/buttons/whatsapp-button';
import PhoneNumberButton from 'components/atoms/phone/phone-number-button';
import React from 'react';
import { usePhoneContext } from 'src/contexts/phone-context';

interface PhoneNumberItemProps {
  phoneNumber: string;
  userName?: string;
  companyName?: string;
}

const PhoneNumberItem: React.FC<PhoneNumberItemProps> = ({
  phoneNumber,
  userName,
  companyName,
}) => {
  const { setPhoneNumber } = usePhoneContext();

  return (
    <div className="flex">
      <PhoneNumberButton
        onPhoneNumberClick={setPhoneNumber}
        number={phoneNumber}
      />
      {userName && (
        <WhatsappButton
          phone={phoneNumber}
          name={userName}
          company={companyName}
        />
      )}
    </div>
  );
};

export default PhoneNumberItem;
