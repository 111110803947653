// src/components/views/not-found-view/not-found-view.tsx

import { Link } from '@tanstack/react-router';
import { Route } from 'src/routes';

const NotFoundView = () => {
  return (
    <div className="flex flex-col items-center justify-center pt-10">
      <h1 className="text-2xl md:text-4xl font-bold">Página no encontrada</h1>
      <p className="mt-4 text-lg">
        Ups! No encontramos la página que buscabas.
      </p>
      <Link
        to={Route.to}
        className="mt-6 px-6 py-2 text-white bg-blue-600 rounded hover:bg-blue-700 transition"
      >
        Ir a inicio
      </Link>
    </div>
  );
};

export default NotFoundView;
