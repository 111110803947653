import { SettingName, SettingProps } from 'src/api/generated';
import { useSettings } from '../api/settings';
import { useMemo } from 'react';
import { safeParseStringArray } from 'src/utils/strings';

interface Setting {
  setting: unknown;
  loading: boolean;
  error: Error | null;
}

interface StringSetting extends Setting {
  setting: string | null;
}

interface NumberSetting extends Setting {
  setting: number | null;
}

interface BooleanSetting extends Setting {
  setting: boolean;
}

export const useStringArraySetting = (settingName: SettingName): string[] => {
  const { loading, error, settings } = useSettings();

  const settingValue: string[] = useMemo(
    () =>
      settings.reduce((acc: string[], setting: SettingProps) => {
        if (setting.name === settingName) {
          return safeParseStringArray(setting.value);
        }
        return acc;
      }, []),
    [settings, settingName]
  );

  if (loading || error) {
    return [];
  }

  return settingValue;
};

export const useStringSetting = (settingName: SettingName): StringSetting => {
  const { loading, error, settings } = useSettings();

  const settingValue: string | null = useMemo(() => {
    const setting = settings.find((setting) => setting.name === settingName);
    return setting ? setting.value : null;
  }, [settings, settingName]);

  return { setting: settingValue, loading, error };
};

export const useNumberSetting = (settingName: SettingName): NumberSetting => {
  const { setting, loading, error } = useStringSetting(settingName);

  const value = setting ? parseInt(setting) : null;
  return { setting: value, loading, error };
};

export const useBooleanSetting = (settingName: SettingName): BooleanSetting => {
  const { setting, loading, error } = useStringSetting(settingName);

  const value = setting === 'active';
  return { setting: value, loading, error };
};
