import React, { useState, useEffect } from 'react';
import MediaModal from '../../../atoms/media/media-modal';
import MediaSkeleton from '../../../atoms/media/media-skeleton';

interface ImageMediaModalProps {
  url: string;
  alt?: string;
}

const ImageMediaModal: React.FC<ImageMediaModalProps> = ({
  url,
  alt = 'image',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setIsLoaded(true);
      setHasError(false);
    };
    img.onerror = () => {
      setIsLoaded(false);
      setHasError(true);
    };
  }, [url]);

  return (
    <>
      <div className="relative w-full h-auto max-w-[27rem] max-h-[27rem] rounded-lg">
        <MediaSkeleton isLoading={!isLoaded} hasError={hasError} />
        <img
          src={url}
          alt={alt}
          onClick={() => setIsModalOpen(true)}
          className={`w-full h-full rounded-lg cursor-pointer ${
            isLoaded ? 'block' : 'hidden'
          }`}
        />
      </div>
      <MediaModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <MediaSkeleton isLoading={!isLoaded} hasError={hasError} />
        <img
          src={url}
          alt={alt}
          className={`max-w-full max-h-full rounded-lg cursor-pointer object-contain ${
            isLoaded ? 'block' : 'hidden'
          }`}
          onClick={() => setIsModalOpen(false)}
        />
      </MediaModal>
    </>
  );
};

export default ImageMediaModal;
