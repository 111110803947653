/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company';
import type { NewTenant } from '../models/NewTenant';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompaniesService {

    /**
     * @returns Company Ok
     * @throws ApiError
     */
    public static getCurrentCompany(): CancelablePromise<Company> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/current',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static createTenant(
        requestBody: NewTenant,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tenants',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

}
