/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SettingName } from '../models/SettingName';
import type { SettingProps } from '../models/SettingProps';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SettingsService {

    /**
     * @returns SettingProps Ok
     * @throws ApiError
     */
    public static getSettings(): CancelablePromise<Array<SettingProps>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/settings',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param name
     * @param requestBody
     * @returns SettingProps Ok
     * @throws ApiError
     */
    public static updateSetting(
        name: SettingName,
        requestBody: {
            value: string;
        },
    ): CancelablePromise<SettingProps> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/settings/{name}',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

}
