import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from '@tanstack/react-router';
import { Route as LoginRoute } from 'src/routes/login';

type AuthenticateRouteProps = {
  children: JSX.Element;
};

const AuthenticateRoute: React.FC<AuthenticateRouteProps> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (!isLoading && !isAuthenticated) {
    return <Navigate to={LoginRoute.to} />;
  }

  return children;
};

export default AuthenticateRoute;
