import { createFileRoute } from '@tanstack/react-router';
import AuthenticateRoute from 'components/organisms/app-layouts/authenticate-route';
import ConversationsView from 'components/views/conversations-view';

export const Route = createFileRoute('/conversations/')({
  component: ConversationsIndexComponent,
});

function ConversationsIndexComponent() {
  return (
    <AuthenticateRoute>
      <ConversationsView />
    </AuthenticateRoute>
  );
}
