import React from 'react';

type ViewFilterProps = {
  children?: React.ReactNode;
};

const ViewFilter: React.FC<ViewFilterProps> = ({ children }) => {
  return (
    <div className="flex flex-grow flex-col justify-end md:flex-row gap-3">
      {children}
    </div>
  );
};

export default ViewFilter;
