/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Alias_WhatsAppMessageCreateBodyParams } from '../models/Alias_WhatsAppMessageCreateBodyParams';
import type { Alias_WhatsAppTemplateMessageCreateBodyParams } from '../models/Alias_WhatsAppTemplateMessageCreateBodyParams';
import type { WhatsAppMessageCreateResponse } from '../models/WhatsAppMessageCreateResponse';
import type { WhatsAppTemplateMessageCreateResponse } from '../models/WhatsAppTemplateMessageCreateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MessagingWhatsappMessagesService {

    /**
     * @param requestBody
     * @returns WhatsAppTemplateMessageCreateResponse Ok
     * @throws ApiError
     */
    public static createTemplateMessage(
        requestBody: Alias_WhatsAppTemplateMessageCreateBodyParams,
    ): CancelablePromise<WhatsAppTemplateMessageCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/messaging/whatsapp/messages/templates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden.`,
            },
        });
    }

    /**
     * @param conversationId
     * @param requestBody
     * @returns WhatsAppMessageCreateResponse Ok
     * @throws ApiError
     */
    public static createMessage(
        conversationId: string,
        requestBody: Alias_WhatsAppMessageCreateBodyParams,
    ): CancelablePromise<WhatsAppMessageCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/messaging/whatsapp/messages/conversations/{conversationId}',
            path: {
                'conversationId': conversationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden.`,
            },
        });
    }

}
