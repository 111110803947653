import React, { ComponentProps } from 'react';
import { ComponentSize } from 'src/modules/shared/types/component.type';
import { ComponentVariant } from 'src/modules/shared/types/component.type';
import Loading from 'src/modules/shared/components/atoms/loading';

type ButtonProps = ComponentProps<'button'> & {
  className?: string;
  variant?: ComponentVariant;
  size?: ComponentSize;
  loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  className = '',
  variant = 'primary',
  size = 'md',
  disabled = false,
  loading = false,
  ...rest
}) => {
  const baseClasses = [
    'flex',
    'whitespace-nowrap',
    'border',
    'rounded-md',
    'transition-colors',
    'duration-200',
    'justify-center',
    'items-center',
    'box-border',
    'gap-2',
    disabled || loading ? 'opacity-50 cursor-not-allowed' : '',
  ];

  const variantClasses: Record<ComponentVariant, string> = {
    primary: 'bg-blue-600 text-white border-transparent',
    secondary: 'bg-white text-blue-600 border-blue-600',
    danger: 'bg-red-600 text-white border-transparent',
    warning: 'bg-yellow-500 text-white border-transparent',
    plain: 'bg-white text-gray-700 border-gray-300',
  };

  const hoverClasses: Record<ComponentVariant, string> = {
    primary: 'hover:bg-blue-700',
    secondary: 'hover:bg-gray-100',
    danger: 'hover:bg-red-700',
    warning: 'hover:bg-yellow-600',
    plain: 'hover:bg-gray-100',
  };

  const activeClasses: Record<ComponentVariant, string> = {
    primary: 'active:bg-blue-800',
    secondary: 'active:bg-gray-200',
    danger: 'active:bg-red-800',
    warning: 'active:bg-yellow-700',
    plain: 'active:bg-gray-200',
  };

  const sizeClasses: Record<ComponentSize, string> = {
    lg: 'h-12 px-4 text-md',
    md: 'h-10 px-4 text-sm',
    sm: 'h-8 px-3 text-sm',
    xs: 'h-7 px-2 text-xs',
    '2xs': 'h-6 px-1.5 text-xs',
  };

  const classes = [
    ...baseClasses,
    variantClasses[variant],
    !disabled && !loading && hoverClasses[variant],
    !disabled && !loading && activeClasses[variant],
    sizeClasses[size],
    className,
  ].join(' ');

  return (
    <button className={classes} disabled={disabled || loading} {...rest}>
      {loading ? (
        <>
          <Loading />
          {children}
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
