// src/components/molecules/tables/table-with-header/table-with-header.tsx

import React from 'react';
import Table, {
  TableColumnProps,
  TableRowProps,
} from 'components/molecules/tables/table';
import { Typography } from '@material-tailwind/react';

type TableWithHeaderProps = {
  title: string;
  columns: TableColumnProps[];
  data: TableRowProps[];
  children?: React.ReactNode;
};

const TableWithHeader: React.FC<TableWithHeaderProps> = ({
  title,
  columns,
  data,
  children,
}) => {
  return (
    <div>
      <div className="flex flex-col md:flex-row mb-2 md:mb-0 justify-between">
        <Typography variant="h6" className="m-2 text-blue-600">
          {title}
        </Typography>
        {children}
      </div>
      <Table columns={columns} data={data} />
    </div>
  );
};

export default TableWithHeader;
