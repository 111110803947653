// src/hooks/analysis/summary/analysisValues.ts

import { useAnalysisValueViews } from 'hooks/api/analysis-values/analysis-value-views';
import { FilterParam, generateFilterQueryString } from 'src/utils/queryParams';

interface UseAnalysisValuesProps {
  callId: number;
  enabled?: boolean;
}

export const useAnalysisValues = ({
  callId,
  enabled = true,
}: UseAnalysisValuesProps) => {
  const filters: Record<string, FilterParam> = {
    callId: { value: callId },
  };
  const filterBy = generateFilterQueryString(filters);

  const { analysisValueViews: analysisValues, loading } = useAnalysisValueViews(
    { filterBy },
    enabled
  );

  return { analysisValues, loading };
};
