import React, { useEffect } from 'react';
import LabelSelect from 'src/modules/shared/components/atoms/inputs/label-select';
import { IntegrationAddress } from 'src/api/generated/models/IntegrationAddress';

interface IntegrationPhoneSelectProps {
  phones: IntegrationAddress[];
  selectedPhoneId?: string;
  onPhoneChange: (phoneId?: string) => void;
}

function generatePhoneOptions(phones: IntegrationAddress[]) {
  return phones.map((phone) => {
    const displayText = phone.displayName
      ? `${phone.displayName}: ${phone.address}`
      : phone.address;

    return {
      content: displayText,
      value: phone.providerId,
    };
  });
}

const IntegrationPhoneSelect: React.FC<IntegrationPhoneSelectProps> = ({
  phones,
  selectedPhoneId,
  onPhoneChange,
}) => {
  useEffect(() => {
    if (phones.length > 0 && !selectedPhoneId) {
      onPhoneChange(phones[0].providerId);
    }
  }, [phones, onPhoneChange, selectedPhoneId]);

  return (
    <LabelSelect
      // TODO: check when implementing i18n
      label="Cuenta de WhatsApp"
      size="sm"
      options={generatePhoneOptions(phones)}
      selectProps={{
        value: selectedPhoneId,
        onChange: (event: React.ChangeEvent<HTMLSelectElement>) => {
          onPhoneChange(event.target.value);
        },
      }}
    />
  );
};

export default IntegrationPhoneSelect;
