import { AnalysisConfigurableType, AnalysisValueView } from 'src/api/generated';
import { getTrimmedValue } from '../analysisValues';

export const getWeaknesses = (
  analysisValues: AnalysisValueView[]
): AnalysisValueView[] => {
  return analysisValues.filter(isWeaknessValue);
};

export const getWeaknessValue = (
  analysisValue: AnalysisValueView
): string | null => {
  if (!isWeaknessValue(analysisValue)) return null;

  const trimmedValue = getTrimmedValue(analysisValue);
  return trimmedValue;
};

const isWeaknessValue = (analysisValue: AnalysisValueView): boolean => {
  return (
    analysisValue.analysisConfigurableType ===
    AnalysisConfigurableType.FEEDBACK_WEAKNESS
  );
};
