import { useState, useEffect, useCallback } from 'react';
import {
  WhatsAppTemplate,
  WhatsAppTemplateMessageComponent,
} from 'src/api/generated';
import { buildMessageComponents } from '../utils/template.util';

export const useTemplateMessage = (templates: WhatsAppTemplate[]) => {
  const [selectedTemplate, setSelectedTemplate] = useState<WhatsAppTemplate>();
  const [messageComponents, setMessageComponents] = useState<
    WhatsAppTemplateMessageComponent[]
  >([]);

  const selectTemplate = useCallback(
    (templateId?: string) => {
      const template = templates.find((t) => t.id === templateId);
      setSelectedTemplate(template);

      const messageComponents = buildMessageComponents(template);
      setMessageComponents(messageComponents);
    },
    [templates]
  );

  return {
    selectedTemplate,
    messageComponents,
    setMessageComponents,
    selectTemplate,
  };
};

export const usePlaceholderValues = (messageContent: string) => {
  const [placeholderValues, setPlaceholderValues] = useState<string[]>([]);

  useEffect(() => {
    const matches = messageContent.match(/{{\d+}}/g) || [];
    const uniquePlaceholders = Array.from(new Set(matches));
    setPlaceholderValues(uniquePlaceholders.map(() => ''));
  }, [messageContent]);

  return { placeholderValues, setPlaceholderValues };
};
