// src/modules/shared/components/molecules/modals/button-modal/index.tsx

import React, { useState } from 'react';
import { IconButton } from '@material-tailwind/react';
import CopyModal from 'src/modules/shared/components/molecules/modals/copy-modal/index';

interface ButtonModalProps {
  children: (enabled: boolean) => JSX.Element;
  copyableContent?: string;
  icon: JSX.Element;
  title?: string;
  visible?: boolean;
  className?: string;
  bodyClassName?: string;
}

const ButtonModal: React.FC<ButtonModalProps> = ({
  children,
  copyableContent,
  icon,
  title = '',
  visible = true,
  className = '',
  bodyClassName = '',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => setIsModalOpen(true)}
        variant="text"
        className={`mx-1 ${className} ${!visible ? 'invisible' : ''}`}
        disabled={!visible}
      >
        {icon}
      </IconButton>
      <CopyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={title}
        copyableContent={copyableContent}
        bodyClassName={bodyClassName}
      >
        {children(isModalOpen)}
      </CopyModal>
    </>
  );
};

export default ButtonModal;
