// src/components/molecules/analysis/summary-modal-content/summary-modal-content.tsx

import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { AnalysisValueView } from 'src/api/generated';
import { useMemo } from 'react';
import QualiScoreRow from './quali-score-row';
import ExtractionRow from './extraction-row';
import ScoreRow from './score-row';
import { splitIntoChunks } from 'src/utils/analysisValues/analysisValues';
import MessageLoading from 'components/atoms/loading/message-loading';
import TextModalContent from '../text-modal-content';
import ForbiddenWordsRow from './forbidden-words-row';
import { useSummaryContent } from 'hooks/analysis/summary';

interface SummaryModalContentProps {
  callId: number;
  enabled?: boolean;
  onCopyableContentChange?: (content: string) => void;
}

// TODO: check when implementing i18n
const DEFAULT_MESSAGE = `El resumen de la llamada puede tardar hasta 1 minuto en aparecer. \
Solamente las llamadas efectivas se resumen.`;

const SCORES_PER_ROW = 2;

const SummaryModalContent: React.FC<SummaryModalContentProps> = ({
  callId,
  enabled = true,
  onCopyableContentChange,
}) => {
  const {
    loading,
    extractions,
    latestContactQualiScore,
    publicScores,
    forbiddenWords,
  } = useSummaryContent({
    callId,
    enabled,
    onCopyableContentChange,
  });

  const chunkedPublicScores: AnalysisValueView[][] = useMemo(() => {
    return splitIntoChunks(publicScores, SCORES_PER_ROW);
  }, [publicScores]);

  const qualiScoreRowComponent = latestContactQualiScore ? (
    <QualiScoreRow analysisValue={latestContactQualiScore} />
  ) : null;

  const extractionRowsComponent = extractions.map((extraction) => (
    <ExtractionRow key={extraction.id} extraction={extraction} />
  ));

  const forbiddenWordsRowComponent = forbiddenWords.length > 0 && (
    <ForbiddenWordsRow forbiddenWords={forbiddenWords} />
  );

  const scoreRowsComponent = chunkedPublicScores.map((scores, index) => (
    <ScoreRow key={index} scores={scores} />
  ));

  if (loading) {
    return <MessageLoading />;
  }
  if (
    !latestContactQualiScore &&
    !extractions.length &&
    !forbiddenWords.length &&
    !chunkedPublicScores.length
  ) {
    return (
      <div className="px-2 md:py-2 md:px-4">
        <TextModalContent content={DEFAULT_MESSAGE} />
      </div>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>
          {qualiScoreRowComponent}
          {extractionRowsComponent}
          {forbiddenWordsRowComponent}
        </TableBody>
      </Table>
      <Divider className="py-2" />
      <Table size="small">
        <TableBody>{scoreRowsComponent}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default SummaryModalContent;
