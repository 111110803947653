import React, { forwardRef } from 'react';
import InputError from '../input-error';
import { ComponentSize } from 'src/modules/shared/types/component.type';
import { ComponentVariant } from 'src/modules/shared/types/component.type';

interface TextInputProps {
  variant?: ComponentVariant;
  size?: ComponentSize;
  className?: string;
  error?: string;
  refInputProps?: React.ComponentProps<'input'>;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      variant = 'primary',
      size = 'md',
      className = '',
      error,
      refInputProps = {},
    },
    ref
  ) => {
    const { disabled = false } = refInputProps;

    const baseClasses = [
      'flex-1',
      'flex',
      'border',
      'rounded-md',
      'focus:outline-none',
      'focus:ring-1',
      'max-w-full',
      'box-border',
      'leading-normal',
      disabled ? 'bg-gray-100 cursor-not-allowed opacity-75' : '',
    ];

    const effectiveVariant = error ? 'danger' : variant;

    const variantClasses: Record<ComponentVariant, string> = {
      primary: disabled ? '' : 'focus:ring-blue-500 border-gray-300',
      secondary: disabled ? '' : 'focus:ring-gray-500 border-gray-400',
      danger: disabled ? '' : 'focus:ring-red-500 border-red-300',
      warning: disabled ? '' : 'focus:ring-yellow-500 border-yellow-300',
      plain: disabled ? '' : 'focus:ring-gray-500 border-gray-300',
    };

    const sizeClasses: Record<ComponentSize, string> = {
      lg: 'py-2 leading-[1.9rem] px-4 text-md',
      md: 'py-2 leading-[1.4rem] px-4 text-sm',
      sm: 'py-1.5 leading-[1.15rem] px-3 text-sm',
      xs: 'py-1 leading-[1.15rem] px-2 text-xs',
      '2xs': 'py-0.5 leading-[1.15rem] px-1.5 text-xs',
    };

    const classes = [
      ...baseClasses,
      variantClasses[effectiveVariant],
      sizeClasses[size],
      className,
    ].join(' ');

    return (
      <div className="flex flex-1 flex-col w-full">
        <input type="text" className={classes} ref={ref} {...refInputProps} />
        <InputError message={error} />
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
