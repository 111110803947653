// src/hooks/api/settings.ts

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from './api';
import { SettingName, SettingProps, SettingsService } from 'src/api/generated';
import { MINUTE_IN_MS } from 'src/utils/date';

interface SettingsResponse extends ApiResponse {
  settings: SettingProps[];
}

export const useSettings = (): SettingsResponse => {
  const fetchSettings = async (): Promise<SettingProps[]> => {
    return await SettingsService.getSettings();
  };

  const {
    data: settings,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['settings'],
    queryFn: fetchSettings,
    staleTime: MINUTE_IN_MS * 2,
  });

  return {
    loading: isLoading,
    error,
    settings: settings || [],
    refetch,
  };
};

interface UpdateSettingParams {
  name: SettingName;
  value: string;
}

interface UpdateSettingResponse {
  loading: boolean;
  error: Error | null;
  updateSetting: (updateSettingParams: UpdateSettingParams) => void;
}

export const useUpdateSetting = (
  onUpdateSuccess?: () => void,
  onUpdateError?: () => void
): UpdateSettingResponse => {
  const queryClient = useQueryClient();

  const updateSetting = async ({
    name,
    value,
  }: UpdateSettingParams): Promise<SettingProps> => {
    return await SettingsService.updateSetting(name, { value });
  };

  const mutation = useMutation({
    mutationFn: updateSetting,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });
      onUpdateSuccess && onUpdateSuccess();
    },
    onError: () => onUpdateError && onUpdateError(),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    updateSetting: mutation.mutate,
  };
};
