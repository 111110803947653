// src/components/atoms/phone-number-select/phone-number-select.tsx

import { Phone } from 'src/api/generated';
import TextTooltip from '../tooltips/text-tooltip';
import { formatPhoneNumber } from 'src/utils/formatting/phone';

interface PhoneNumberSelectProps {
  label: string;
  phones: Phone[];
  selectedPhoneId?: number;
  onChange: (phoneId: number) => void;
}

const PhoneNumberSelect: React.FC<PhoneNumberSelectProps> = ({
  label,
  phones,
  selectedPhoneId,
  onChange,
}) => (
  <div className="mb-4">
    <TextTooltip
      content={label}
      tooltipContent={
        'Puedes seleccionar entre tus números disponibles para realizar llamadas.'
      }
      placement="top"
    />
    <select
      id={label}
      value={selectedPhoneId}
      onChange={(e) => {
        const phoneId = parseInt(e.target.value);
        onChange(phoneId);
      }}
      className="mt-1 block w-full p-2 pr-8 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
    >
      {phones.map((phone) => (
        <option key={phone.id} value={phone.id}>
          {formatPhoneNumber(phone.number)}
        </option>
      ))}
    </select>
  </div>
);

export default PhoneNumberSelect;
