// src/components/molecules/finder.tsx

import SearchInput from 'components/atoms/forms/search-input';
import React, { useState } from 'react';

type FinderProps = {
  onSearch: (searchTerm: string) => void;
};

const Finder: React.FC<FinderProps> = ({ onSearch }) => {
  const [inputValue, setInputValue] = useState('');

  const sendSearch = (searchTerm: string) => {
    onSearch(searchTerm);
  };

  return SearchInput({
    sendSearch,
    inputValue,
    onChange: setInputValue,
  });
};

export default Finder;
