import { Typography } from '@material-tailwind/react';
import React from 'react';
import {
  WhatsAppTemplateMessageComponent,
  WhatsAppTemplateMessageTextParameter,
} from 'src/api/generated';
import { translateTemplateValues } from 'src/modules/messaging/whatsapp/utils/template.util';
import LabelTextInput from 'src/modules/shared/components/atoms/inputs/label-text-input';

interface ComponentInputsProps {
  messageComponents: WhatsAppTemplateMessageComponent[];
  onComponentChange: (components: WhatsAppTemplateMessageComponent[]) => void;
}

const ComponentInputs: React.FC<ComponentInputsProps> = ({
  messageComponents,
  onComponentChange,
}) => {
  const handleTextParameterChange = (
    componentType: WhatsAppTemplateMessageComponent['type'],
    index: number,
    value: string
  ) => {
    const updatedComponents: WhatsAppTemplateMessageComponent[] =
      messageComponents.map((component) => {
        if (component.type.toLowerCase() !== componentType.toLowerCase()) {
          return component;
        }

        const currentParameters = component.parameters || [];
        const updatedParameters = [...currentParameters];

        const updatedParameter = {
          type: 'text',
          text: value,
        } as WhatsAppTemplateMessageTextParameter;
        updatedParameters[index] = updatedParameter;

        const updatedComponent = {
          ...component,
          parameters: updatedParameters,
        } as WhatsAppTemplateMessageComponent;

        return updatedComponent;
      });

    onComponentChange(updatedComponents);
  };

  const componentSectionTitle = (
    componentType: WhatsAppTemplateMessageComponent['type']
  ) => {
    // TODO: check when implementing i18n
    return translateTemplateValues('component', componentType);
  };

  return (
    <div className="space-y-4">
      {messageComponents.map((comp) => {
        if (!comp.parameters) return null;

        return (
          <div key={comp.type} className="space-y-2">
            <div className="flex items-center gap-2 px-3">
              <Typography className="text-sm">
                {componentSectionTitle(comp.type)}
              </Typography>
              <div className="h-[1px] flex-1 bg-gray-200" />
            </div>
            {comp.parameters.map((param, index) => {
              const type = param.type;
              const variableName = `{{${index + 1}}}`;
              if (type === 'text') {
                return (
                  <LabelTextInput
                    key={`${comp.type}-${index}`}
                    // TODO: check when implementing i18n
                    label={`Ingresa un valor para ${variableName}`}
                    variant="primary"
                    size="sm"
                    inputProps={{
                      refInputProps: {
                        value: param.text,
                        onChange: (e) =>
                          handleTextParameterChange(
                            comp.type,
                            index,
                            e.target.value
                          ),
                      },
                    }}
                  />
                );
              }
              // TODO: add support for other parameter types

              return null;
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ComponentInputs;
