// src/components/agents-calls-daily-chart/agents-calls-daily-chart.tsx

import React from 'react';
import BaseAgentCallsChart from '../base-agent-calls-chart';
import { useAgentCallsData } from 'hooks/charts/agent-calls';
import { CallSummaries } from 'src/types/call-summaries';

const AgentCallsDailyChart: React.FC<CallSummaries> = ({ summaries }) => {
  const chartData = useAgentCallsData(summaries, 'daily');

  return (
    <BaseAgentCallsChart
      labels={chartData?.labels || []}
      agentPeriodsCallSummaries={chartData?.agentPeriodsCallSummaries || []}
      // TODO: check when implementing i18n
      chartTitle="Llamadas Diarias por Agente"
    />
  );
};

export default AgentCallsDailyChart;
