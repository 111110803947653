// src/hooks/date.ts

import { useState, useCallback, useMemo, useEffect } from 'react';
import { SelectData } from 'tw-elements-react/dist/types/forms/Select/types';
import {
  getStartOfMonth,
  getMonthName,
  getStartOfDay,
  getStartOfWeek,
  getEndOfMonth,
  getEndOfDay,
} from 'src/utils/date';
import { useStringSetting } from './settings/settings';
import { SettingName } from 'src/api/generated';
import { useUpdateSetting } from './api/settings';

export const useSyncTimezone = () => {
  const {
    setting: backendTimezone,
    loading,
    error,
  } = useStringSetting(SettingName.TIMEZONE);
  const { updateSetting } = useUpdateSetting();

  useEffect(() => {
    if (loading || error || !backendTimezone) return;

    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (backendTimezone !== browserTimezone) {
      updateSetting({ name: SettingName.TIMEZONE, value: browserTimezone });
    }
  }, [backendTimezone, loading, error, updateSetting]);
};

interface MonthPickerValues {
  years: SelectData[];
  months: SelectData[];
}

export const useMonthPickerValues = (): MonthPickerValues => {
  const generateYearOptions = useCallback((): SelectData[] => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 6 }, (_, index) => ({
      text: (currentYear - 5 + index).toString(),
      value: (currentYear - 5 + index).toString(),
    }));
  }, []);

  const generateMonthOptions = useCallback(
    (): SelectData[] =>
      Array.from({ length: 12 }, (_, index) => ({
        text: getMonthName(index),
        value: index.toString(),
      })),
    []
  );

  return {
    years: useMemo(generateYearOptions, [generateYearOptions]),
    months: useMemo(generateMonthOptions, [generateMonthOptions]),
  };
};

interface SelectedYearMonth {
  selectedYear: number;
  selectedMonth: number;
  setSelectedDate: (date: Date) => void;
}

export const useSelectedYearMonth = (): SelectedYearMonth => {
  const currentDate = useMemo(() => getStartOfMonth(new Date()), []);
  const [selectedDate, setSelectedDate] = useState<Date>(currentDate);

  const setPastDate = useCallback((date: Date) => {
    const now = new Date();
    if (date > now) return setSelectedDate(now);
    setSelectedDate(date);
  }, []);

  return {
    selectedYear: selectedDate.getFullYear(),
    selectedMonth: selectedDate.getMonth(),
    setSelectedDate: setPastDate,
  };
};

interface DatePeriods {
  endOfToday: Date;
  startOfDay: Date;
  startOfWeek: Date;
  startOfMonth: Date;
  endOfMonth: Date;
}

export const useDatePeriods = (selectedDate: Date): DatePeriods => {
  const endOfToday = useMemo(() => {
    return getEndOfDay(new Date());
  }, []);
  const startOfDay = useMemo(() => {
    return getStartOfDay(endOfToday);
  }, [endOfToday]);
  const startOfWeek = useMemo(() => {
    return getStartOfWeek(endOfToday);
  }, [endOfToday]);
  const startOfMonth = useMemo(() => {
    return getStartOfMonth(selectedDate);
  }, [selectedDate]);
  const endOfMonth = useMemo(() => {
    const endDate = getEndOfMonth(selectedDate);
    if (endDate > endOfToday) return endOfToday;

    return endDate;
  }, [endOfToday, selectedDate]);

  return {
    endOfToday,
    startOfDay,
    startOfWeek,
    startOfMonth,
    endOfMonth,
  };
};
