import { ConversationStatus } from 'src/api/generated';

// TODO: check when implementing i18n
export const conversationStatusTranslations: Record<
  ConversationStatus | 'expired',
  string
> = {
  [ConversationStatus.OPEN]: 'Abierta',
  [ConversationStatus.CLOSED]: 'Cerrada',
  [ConversationStatus.ARCHIVED]: 'Archivada',
  [ConversationStatus.PENDING]: 'En espera',
  expired: 'Expirada',
};

const statusOrder: Record<ConversationStatus, number> = {
  [ConversationStatus.OPEN]: 1,
  [ConversationStatus.PENDING]: 2,
  [ConversationStatus.CLOSED]: 3,
  [ConversationStatus.ARCHIVED]: 4,
};

interface GetConversationStatusOptionsParams {
  includeAllOption?: boolean;
}

export const getConversationStatusOptions = (
  params?: GetConversationStatusOptionsParams
) => {
  const includeAllOption = params?.includeAllOption ?? false;

  const statusOptions = Object.values(ConversationStatus)
    .sort((a, b) => statusOrder[a] - statusOrder[b])
    .map((status) => ({
      content: conversationStatusTranslations[status],
      value: status,
    }));

  if (includeAllOption) {
    // TODO: check when implementing i18n
    return [{ content: 'Todas', value: '' }, ...statusOptions];
  }

  return statusOptions;
};
