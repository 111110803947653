import { Call, Device } from '@twilio/voice-sdk';
import { User } from 'src/api/generated';

export interface TwilioPhoneCallActionsProps {
  user: User | null;
  standardizedPhoneNumber: string | null;
  setPhoneNumber: (number: string) => void;
  setPhoneState: (state: string) => void;
  device: Device | null;
  call: Call | null;
  setCall: (call: Call | null) => void;
}

export interface TwilioPhonePadActionsProps {
  formattedPhoneNumber: string | null;
  setPhoneNumber: (number: string) => void;
  call: Call | null;
}

// https://www.twilio.com/docs/voice/sdks/error-codes
export enum TwilioErrorCodes {
  // 310xx Series: General Errors
  TRANSPORT_ERROR = 31009,
  CONNECTION_ERROR = 31005,
  UNKNOWN_ERROR = 31000,

  // 53xxx Series: Signaling Errors
  SIGNALING_CONNECTION_ERROR = 53000,
  MEDIA_CONNECTION_FAILED = 53405,

  // Deprecated errors
  ACCESS_TOKEN_EXPIRED = 20104,
  ACCESS_TOKEN_INVALID = 20101,
}

export const RESET_DEVICE_ERROR_CODES = new Set<number>([
  TwilioErrorCodes.TRANSPORT_ERROR,
  TwilioErrorCodes.CONNECTION_ERROR,
  TwilioErrorCodes.UNKNOWN_ERROR,
  TwilioErrorCodes.ACCESS_TOKEN_EXPIRED,
  TwilioErrorCodes.ACCESS_TOKEN_INVALID,
  TwilioErrorCodes.SIGNALING_CONNECTION_ERROR,
  TwilioErrorCodes.MEDIA_CONNECTION_FAILED,
]);
