// src/modules/messaging/whatsapp/hooks/api/integrations.ts

import { useMutation } from '@tanstack/react-query';
import {
  Integration,
  MessagingWhatsappIntegrationsService,
  WhatsAppIntegrationCreateBodyParams as WhatsAppIntegrationCreateParams,
} from 'src/api/generated';

interface UseWhatsAppIntegrationCreateResponse {
  loading: boolean;
  error: Error | null;
  integration?: Integration;
  createWhatsAppIntegration: (params: WhatsAppIntegrationCreateParams) => void;
}

export const useCreateWhatsAppIntegration = (
  onSuccess: () => void = () => {},
  onError: () => void = () => {}
): UseWhatsAppIntegrationCreateResponse => {
  const createWhatsAppIntegration = async (
    params: WhatsAppIntegrationCreateParams
  ): Promise<Integration> => {
    return await MessagingWhatsappIntegrationsService.createIntegration(params);
  };

  const mutation = useMutation({
    mutationFn: createWhatsAppIntegration,
    onSuccess: () => onSuccess(),
    onError: () => onError(),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    integration: mutation.data,
    createWhatsAppIntegration: mutation.mutate,
  };
};
