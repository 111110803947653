// src/components/organisms/featured-view/featured-view.tsx

import { Call, User } from 'src/api/generated';
import ViewContainer from 'components/atoms/view-container';
import { TableRowProps } from 'components/molecules/tables/table';
import { Typography } from '@material-tailwind/react';
import TableWithHeader from 'components/molecules/tables/table-with-header';
import {
  getDurationText,
  getStatusClass,
  isCallCompleted,
} from 'src/utils/calls';
import CallInfoModals from 'components/molecules/calls/call-info-modals';
import MonthPicker from 'components/molecules/month-picker';
import { useDatePeriods, useSelectedYearMonth } from 'hooks/date';
import { useMemo } from 'react';
import { formatDate } from 'src/modules/shared/utils/formatting/date.util';
import { useAgents } from 'hooks/api/users';
import { findUser } from 'src/utils/users';
import { formatFullName } from 'src/utils/formatting/name';
import { useFeaturedCalls } from 'hooks/api/calls/calls';
import ViewFilter from 'components/atoms/view-filter';
import TeamSelect from 'components/molecules/team-select';
import { useCurrentUser } from 'hooks/users';
import { useFilterTeams } from 'hooks/teams';
import { useTeams } from 'hooks/api/teams';

const FeaturedView: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const { users, loading: usersLoading, error: usersError } = useAgents();
  const { teams, loading: teamsLoading, error: teamsError } = useTeams();

  const { selectedYear, selectedMonth, setSelectedDate } =
    useSelectedYearMonth();
  const selectedDate = useMemo(
    () => new Date(selectedYear, selectedMonth),
    [selectedMonth, selectedYear]
  );

  const { selectedTeams, setSelectedTeams } = useFilterTeams(
    teams,
    currentUser
  );

  const { endOfToday, startOfDay, startOfWeek, startOfMonth, endOfMonth } =
    useDatePeriods(selectedDate);
  const {
    loading: dailyLoading,
    error: dailyError,
    calls: dailyCalls,
  } = useFeaturedCalls(
    startOfDay.toISOString(),
    endOfToday.toISOString(),
    { teamId: { value: selectedTeams } },
    10
  );
  const {
    loading: weeklyLoading,
    error: weeklyError,
    calls: weeklyCalls,
  } = useFeaturedCalls(
    startOfWeek.toISOString(),
    endOfToday.toISOString(),
    { teamId: { value: selectedTeams } },
    10
  );
  const {
    loading: monthlyLoading,
    error: monthlyError,
    calls: monthlyCalls,
  } = useFeaturedCalls(
    startOfMonth.toISOString(),
    endOfMonth.toISOString(),
    { teamId: { value: selectedTeams } },
    20
  );

  const columns = [
    {
      header: 'Posición',
      accessor: 'place',
      className: 'max-w-[4rem] max-h-[2rem]',
    },
    {
      header: 'Agente',
      accessor: 'agent',
    },
    {
      header: 'Fecha',
      accessor: 'date',
      className: 'max-w-[4rem]',
    },
    {
      header: 'Duración',
      accessor: 'duration',
    },
    {
      header: 'Resumen',
      accessor: 'summary',
    },
  ];

  const dailyRows: TableRowProps[] = useMemo(() => {
    return dailyCalls.map((call: Call, index: number) =>
      buildRow(call, users, index + 1)
    );
  }, [dailyCalls, users]);
  const weeklyRows: TableRowProps[] = useMemo(() => {
    return weeklyCalls.map((call: Call, index: number) =>
      buildRow(call, users, index + 1)
    );
  }, [weeklyCalls, users]);
  const monthlyRows: TableRowProps[] = useMemo(() => {
    return monthlyCalls.map((call: Call, index: number) =>
      buildRow(call, users, index + 1)
    );
  }, [monthlyCalls, users]);

  const loading =
    usersLoading ||
    teamsLoading ||
    dailyLoading ||
    weeklyLoading ||
    monthlyLoading;
  const error =
    usersError || teamsError || dailyError || weeklyError || monthlyError;

  const headerElement = (
    <ViewFilter>
      {teams.length > 0 && (
        <TeamSelect
          teams={teams}
          selectedTeams={selectedTeams}
          onTeamSelectionChange={setSelectedTeams}
        />
      )}
    </ViewFilter>
  );

  return (
    // TODO: check when implementing i18n
    <ViewContainer
      title="Llamadas destacadas"
      headerElement={headerElement}
      loading={loading}
      error={error}
    >
      <div className="flex flex-col xl:flex-row">
        <div className="flex flex-col flex-grow">
          <div className="p-6 pt-0">
            <TableWithHeader
              title="Top 10 Diario"
              columns={columns}
              data={dailyRows}
            />
          </div>
          <div className="p-6 pt-0">
            <TableWithHeader
              title="Top 10 Semanal"
              columns={columns}
              data={weeklyRows}
            />
          </div>
        </div>
        <div className="flex flex-col flex-grow">
          <div className="p-6 pt-0">
            <TableWithHeader
              title="Top 20 Mensual"
              columns={columns}
              data={monthlyRows}
            >
              <MonthPicker
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                setSelectedDate={setSelectedDate}
              />
            </TableWithHeader>
          </div>
        </div>
      </div>
    </ViewContainer>
  );
};

const buildRow = (call: Call, users: User[], place: number): TableRowProps => {
  const agent = findUser(call.userId, users);
  const agentName = formatFullName(agent?.firstName, agent?.lastName);

  const startTime = formatDate(call.startTime, 'DD-MM');

  const duration = getDurationText(call.status, call.duration);
  const statusClass = getStatusClass(call.status);
  const durationElement = () => {
    return (
      <Typography variant="small" className={`font-normal ${statusClass}`}>
        {duration}
      </Typography>
    );
  };

  const summaryElement = () => (
    <CallInfoModals
      callId={call.id}
      callAgentScore={call.agentScore}
      visible={isCallCompleted(call)}
    />
  );

  const tableRow: TableRowProps = {
    key: call.id.toString(),
    row: [
      {
        accesor: 'place',
        value: place,
      },
      {
        accesor: 'agent',
        value: agentName,
      },
      {
        accesor: 'date',
        value: startTime,
      },
      {
        accesor: 'duration',
        value: duration,
        element: durationElement(),
      },
      {
        accesor: 'summary',
        value: null,
        element: summaryElement(),
      },
    ],
  };
  return tableRow;
};

export default FeaturedView;
