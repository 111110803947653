import { useEffect, useRef, useState } from 'react';

/**
 * A custom hook that throttles a value by only updating it after a specified delay has passed.
 * This is useful for limiting the rate at which a value can change, for example when handling
 * frequent user input or API calls.
 *
 * @template T - The type of the value being throttled
 * @param {T} value - The value to throttle
 * @param {number} delay - The minimum time (in milliseconds) that must pass between updates
 * @returns {T} The throttled value that updates at most once per delay period
 *
 * @example
 * ```tsx
 * const [searchTerm, setSearchTerm] = useState('');
 * const throttledSearch = useThrottle(searchTerm, 1000);
 *
 * // throttledSearch will only update once per second, even if searchTerm changes more frequently
 * ```
 */
export const useThrottle = <T>(value: T, delay: number): T => {
  const [throttledValue, setThrottledValue] = useState<T>(value);
  const lastExecuted = useRef<number>(Date.now());

  useEffect(() => {
    const now = Date.now();
    const timeElapsed = now - lastExecuted.current;

    if (timeElapsed >= delay) {
      lastExecuted.current = now;
      setThrottledValue(value);
    } else {
      const timerId = setTimeout(() => {
        lastExecuted.current = Date.now();
        setThrottledValue(value);
      }, delay - timeElapsed);

      return () => clearTimeout(timerId);
    }
  }, [value, delay]);

  return throttledValue;
};
