/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enum-like object for AnalysisConfigurableType to identify configurable analysis types.
 * These types can reference either existing tables (e.g., AnalysisScore, AnalysisGoal)
 * or be used for specific non-table-based configurations (e.g., FEEDBACK_STRENGTH).
 * Type for the values of AnalysisConfigurableType, enabling type safety for analysisConfigurableType property.
 */
export enum AnalysisConfigurableType {
    ANALYSIS_SCORE = 'AnalysisScore',
    ANALYSIS_GOAL = 'AnalysisGoal',
    ANALYSIS_EXTRACTION = 'AnalysisExtraction',
    FORBIDDEN_WORD = 'ForbiddenWord',
    FEEDBACK_STRENGTH = 'FEEDBACK_STRENGTH',
    FEEDBACK_WEAKNESS = 'FEEDBACK_WEAKNESS',
    SHORT_SUMMARY = 'SHORT_SUMMARY',
    CALL_TYPE = 'CALL_TYPE',
    CALL_CATEGORY = 'CALL_CATEGORY',
}
