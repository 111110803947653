import React from 'react';
import { Typography, Card } from '@material-tailwind/react';
import ViewContainer from 'components/atoms/view-container';
import WhatsAppSignup from 'components/organisms/integrations/facebook/whatsapp-signup';

const SettingsView: React.FC = () => {
  // TODO: check when implementing i18n
  return (
    <ViewContainer title="Ajustes">
      <div className="flex flex-col px-4 space-y-4">
        <Typography variant="h5">Integraciones</Typography>
        <Card className="border rounded-md p-4">
          <Typography variant="h6">WhatsApp</Typography>

          <div className="flex flex-col space-y-2">
            <Typography variant="paragraph">
              Para comenzar a utilizar WhatsApp debes conectar tu cuenta de Meta
              y entregar a LlamadaPro los permisos necesarios.
            </Typography>
            <WhatsAppSignup />
          </div>
        </Card>
      </div>
    </ViewContainer>
  );
};

export default SettingsView;
