/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AssemblyAIWebhookParams = {
    transcript_id?: string;
    status: AssemblyAIWebhookParams.status;
    error?: string;
};

export namespace AssemblyAIWebhookParams {

    export enum status {
        COMPLETED = 'completed',
        ERROR = 'error',
    }


}

