import { formatPhoneNumber } from 'src/utils/formatting/phone';

interface PhoneNumberButtonProps {
  onPhoneNumberClick: (number: string) => void;
  number: string;
}

const PhoneNumberButton: React.FC<PhoneNumberButtonProps> = ({
  onPhoneNumberClick,
  number,
}) => {
  const classes = `
    text-sm text-blue-600 hover:text-blue-800 underline cursor-pointer
    whitespace-nowrap
  `;

  return (
    <button onClick={() => onPhoneNumberClick(number)} className={classes}>
      {formatPhoneNumber(number)}
    </button>
  );
};

export default PhoneNumberButton;
