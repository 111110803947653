// src/components/molecules/opportunity-task/opportunity-task.tsx

import { Chip } from '@material-tailwind/react';
import {
  useAddOpportunityTask,
  useCompleteOpportunityTask,
} from 'hooks/opportunity-tasks';
import { useCallback } from 'react';
import { addHours } from 'date-fns';
import NewOpportunityTaskOptions from '../new-opportunity-task-options';
import { ApiQueryParams } from 'src/api/generated';
import { formatTimeDistance } from 'src/modules/shared/utils/formatting/date.util';

interface OpportunityTaskChipProps {
  taskId?: number;
  opportunityId?: number;
  taskCompleted?: boolean;
  dueDate?: Date | null;
  isRecent?: boolean;
  tasksQueryParams?: ApiQueryParams;
}

const OpportunityTaskChip: React.FC<OpportunityTaskChipProps> = ({
  taskId,
  opportunityId,
  taskCompleted,
  dueDate,
  isRecent,
  tasksQueryParams,
}) => {
  const cacheKey = tasksQueryParams ? [tasksQueryParams] : {};
  const { addOpportunityTask } = useAddOpportunityTask(cacheKey);
  const { completeOpportunityTask } = useCompleteOpportunityTask(cacheKey);

  const addTask = useCallback(
    (hours: number) => {
      const time = addHours(new Date(), hours);
      addOpportunityTask(opportunityId || 0, time);
    },
    [addOpportunityTask, opportunityId]
  );

  const currentTime = new Date().getTime();

  if ((!dueDate || taskCompleted) && isRecent) {
    return <NewOpportunityTaskOptions addTaskDueInHours={addTask} />;
  }

  if ((!dueDate || taskCompleted) && !isRecent) {
    return null;
  }

  const formattedTime = formatTimeDistance(dueDate!);
  const chipColor = currentTime > dueDate!.getTime() ? 'red' : 'green';

  return (
    <div className="flex justify-center">
      <Chip
        open={!((!dueDate || taskCompleted) && !isRecent)}
        onClose={() => {
          completeOpportunityTask(taskId || 0);
        }}
        variant="ghost"
        className="w-fit px-4"
        color={chipColor}
        size="sm"
        value={formattedTime}
      />
    </div>
  );
};

export default OpportunityTaskChip;
