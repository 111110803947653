/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ParticipantsListResponse } from '../models/ParticipantsListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MessagingParticipantsService {

    /**
     * @param conversationId
     * @param tenantId
     * @returns ParticipantsListResponse Ok
     * @throws ApiError
     */
    public static getParticipants(
        conversationId: string,
        tenantId?: string,
    ): CancelablePromise<ParticipantsListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/messaging/conversations/{conversationId}/participants',
            path: {
                'conversationId': conversationId,
            },
            query: {
                'tenantId': tenantId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden.`,
            },
        });
    }

}
