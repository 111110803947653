import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { Typography } from '@material-tailwind/react';
import ErrorReporter from 'src/monitoring/errorReporter';

interface InfoErrorProps {
  error?: Error;
}

const InfoError: React.FC<InfoErrorProps> = ({ error }) => {
  if (error) {
    ErrorReporter.sendException(error);
  }

  return (
    <div className="flex flex-row gap-4 items-center justify-center min-w-[300px] p-6">
      <ExclamationCircleIcon
        strokeWidth={2}
        className="h-8 w-8 text-blue-600"
      />
      <Typography className="max-w-xs text-sm">
        {/* TODO: check when implementing i18n */}
        Ha ocurrido un error. Por favor intenta recargar el sitio.
      </Typography>
    </div>
  );
};

export default InfoError;
