import { TableRow, TableCell } from '@mui/material';
import { AnalysisValueView } from 'src/api/generated';
import {
  getExtractionDescription,
  getExtractionValue,
} from 'src/utils/analysisValues/extractions/extractions';

const ExtractionRow = ({ extraction }: { extraction: AnalysisValueView }) => (
  <TableRow key={extraction.id}>
    <TableCell component="th" scope="row" className="w-44">
      {getExtractionDescription(extraction)}
    </TableCell>
    <TableCell align="left">{getExtractionValue(extraction)}</TableCell>
  </TableRow>
);

export default ExtractionRow;
