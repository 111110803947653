import { useState, useEffect } from 'react';
import { useFunnels } from './api/funnels';
import { Funnel } from 'src/api/generated';

export const useSelectedFunnel = () => {
  const [selectedFunnel, setselectedFunnel] = useState<Funnel | null>(null);
  const { funnels, loading, error } = useFunnels();

  useEffect(() => {
    // TODO: select funnel to display when implementing multiple funnels
    // For now: always select the first funnel
    const funnel = funnels[0];
    if (!funnel) return;

    setselectedFunnel(funnel);
  }, [funnels, setselectedFunnel]);

  return { selectedFunnel, setselectedFunnel, loading, error };
};
