import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from './api';
import { Phone, PhonesService } from 'src/api/generated';

interface UpdateAssignedPhoneParams {
  phoneId: number;
}

interface UpdateAssignedPhoneResponse {
  loading: boolean;
  error: Error | null;
  updateAssignedPhone: (
    updateAssignedPhoneParams: UpdateAssignedPhoneParams
  ) => void;
}

export const useUpdateAssignedPhone = (
  onUpdateSuccess?: () => void,
  onUpdateError?: () => void
): UpdateAssignedPhoneResponse => {
  const queryClient = useQueryClient();

  const updateAssignedPhone = async ({
    phoneId,
  }: UpdateAssignedPhoneParams): Promise<void> => {
    await PhonesService.updateAssignedPhone({ phoneId });
  };

  const mutation = useMutation({
    mutationFn: updateAssignedPhone,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['phones/available/assigned'],
      });
      onUpdateSuccess && onUpdateSuccess();
    },
    onError: () => onUpdateError && onUpdateError(),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    updateAssignedPhone: mutation.mutate,
  };
};

interface AssignedPhoneResponse extends ApiResponse {
  phone: Phone | null;
}

export const useAssignedPhone = (): AssignedPhoneResponse => {
  const fetchPhone = async (): Promise<Phone> => {
    return await PhonesService.getAssignedPhone();
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['phones/available/assigned'],
    queryFn: fetchPhone,
  });

  return {
    loading: isLoading,
    error,
    phone: data || null,
    refetch,
  };
};
