// /src/utils/charts.ts

export const AGENT_COLORS = [
  '#FF8095', // Light Pink
  '#FFB080', // Peach
  '#FFFB80', // Light Yellow
  '#80FFB2', // Mint Green
  '#80B6FF', // Light Blue
  '#E780FF', // Lavender
  '#75A066', // Sage Green
  '#B080FF', // Wisteria
  '#80FFF2', // Pale Turquoise
  '#FEA6C8', // Pale Pink
  '#B5F080', // Pale Lime
  '#80E6E6', // Ice Blue
  '#ECE880', // Vanilla
  '#A6A0FF', // Light Periwinkle
  '#FF6A6A', // Pastel Red
  '#FFB347', // Pastel Orange
  '#77DD77', // Pastel Green
  '#CBAACB', // Pastel Purple
  '#77BFC7', // Pastel Teal
  '#FFD1DC', // Pastel Pink
  '#AEC6CF', // Pastel Blue
  '#BC8F8F', // Rosy Brown
  '#FFB6C1', // Light Coral
];

export const generateSolidColorIcon = (color: string, size: string) => {
  const svg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="45" fill="${color}" />
        </svg>
      `;

  return `data:image/svg+xml;base64,${btoa(svg)}`;
};

// TODO: check when implementing i18n
export const formatWeeklyLabel = (
  date: string,
  currentWeekStartFormatted: string
): string =>
  date === currentWeekStartFormatted
    ? 'Actual'
    : `Semana del ${date.substring(8, 10)}/${date.substring(5, 7)}`;

// used to determine the number of days or months to display in each chart
export const periodLengths = {
  daily: 7,
  weekly: 6,
  monthly: 3,
};
