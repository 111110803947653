/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SipTransferDestination = {
    /**
     * This is the description of the destination. This is used by the model to decide when to transfer the call to this destination.
     */
    description?: string;
    /**
     * This is the message to say before transferring the call to the destination.
     */
    message?: string;
    /**
     * This is the SIP URI to transfer the call to.
     */
    sipUri: string;
    type: SipTransferDestination.type;
};

export namespace SipTransferDestination {

    export enum type {
        SIP = 'sip',
    }


}

