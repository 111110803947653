import { Button } from '@material-tailwind/react';

interface DialerButtonProps {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  children: React.ReactNode;
}

const DialerButton: React.FC<DialerButtonProps> = ({
  onClick,
  className = '',
  disabled = false,
  children,
}) => {
  const buttonClasses = `w-[70px] p-0 mb-2 rounded-lg text-center
    h-10 text-2xl border transition-colors duration-200
    text-blue-600 bg-white hover:bg-gray-200 border-blue-600`;

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={`${buttonClasses} ${className}`}
    >
      {children}
    </Button>
  );
};

export default DialerButton;
