import { MessageStatus } from 'src/api/generated';

interface MessageStatusTranslations {
  tooltip: string;
}

// TODO: check when implementing i18n
export const messageStatusTranslations: Record<
  string,
  MessageStatusTranslations
> = {
  [MessageStatus.PENDING]: {
    tooltip: 'Pendiente',
  },
  [MessageStatus.FAILED]: {
    tooltip: 'Error',
  },
  [MessageStatus.SENT]: {
    tooltip: 'Enviado',
  },
  [MessageStatus.DELIVERED]: {
    tooltip: 'Entregado',
  },
  [MessageStatus.READ]: {
    tooltip: 'Leído',
  },
  [MessageStatus.DELETED]: {
    tooltip: 'Eliminado',
  },
  [MessageStatus.WARNING]: {
    tooltip: 'Advertencia',
  },
};

export function getMessageStatusTranslation(
  status: string,
  type: 'tooltip' = 'tooltip'
): string {
  return messageStatusTranslations[status]?.[type] ?? status;
}
