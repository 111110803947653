import { AnalysisConfigurableType, AnalysisValueView } from 'src/api/generated';
import {
  getTrimmedDescription,
  getTrimmedEvidence,
  getTrimmedValue,
} from '../analysisValues';

export const getGoals = (
  analysisValues: AnalysisValueView[]
): AnalysisValueView[] => {
  return analysisValues.filter(isGoalValue);
};

export const getGoalValue = (
  analysisValue: AnalysisValueView
): boolean | null => {
  if (!isGoalValue(analysisValue)) return null;

  const trimmedValue = getTrimmedValue(analysisValue);
  if (trimmedValue === null) return null;

  const parsedValue = parseBoolean(trimmedValue);
  return parsedValue;
};

export const getGoalDescription = (
  analysisValue: AnalysisValueView
): string | null => {
  if (!isGoalValue(analysisValue)) return null;

  const trimmedDescription = getTrimmedDescription(analysisValue);
  if (trimmedDescription === null) return null;

  return trimmedDescription;
};

export const getGoalEvidence = (
  analysisValue: AnalysisValueView
): string | null => {
  if (!isGoalValue(analysisValue)) return null;

  const trimmedEvidence = getTrimmedEvidence(analysisValue);
  if (trimmedEvidence === null) return null;

  return trimmedEvidence;
};

const isGoalValue = (analysisValue: AnalysisValueView): boolean => {
  return (
    analysisValue.analysisConfigurableType ===
    AnalysisConfigurableType.ANALYSIS_GOAL
  );
};

const parseBoolean = (value: string): boolean => {
  return value === 'true';
};
