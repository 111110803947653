import React from 'react';
import { Template } from 'src/api/generated';
import { BoltIcon } from '@heroicons/react/24/outline';

interface TemplateSuggestionItemProps {
  template: Template;
  isSelected: boolean;
  onClick: () => void;
}

export const TemplateSuggestionItem: React.FC<TemplateSuggestionItemProps> = ({
  template,
  isSelected,
  onClick,
}) => {
  const baseClasses = [
    'flex',
    'items-center',
    'gap-2',
    'w-full',
    'px-3',
    'py-1.5',
    'cursor-pointer',
    'hover:bg-gray-50',
    'transition-colors',
    isSelected ? 'bg-gray-50' : '',
  ].join(' ');

  return (
    <div className={baseClasses} onClick={onClick}>
      <BoltIcon className="h-4 w-4 text-gray-400 shrink-0" />
      <div className="min-w-0 flex-1">
        <div className="text-sm font-medium text-gray-700 truncate">
          {template.title}
        </div>
        <div className="text-xs text-gray-500 truncate">{template.content}</div>
      </div>
    </div>
  );
};
