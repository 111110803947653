import { TableRow, TableCell } from '@mui/material';
import { AnalysisValueView } from 'src/api/generated';
import ContactQualiChip from '../../contact-quali-chip';
import { getScoreValue } from 'src/utils/analysisValues/scores/scores';

const CONTACT_QUALI_SCORE_TITLE = 'Puntuación prospecto';

const QualiScoreRow = ({
  analysisValue,
}: {
  analysisValue: AnalysisValueView;
}) => {
  const scoreValue = getScoreValue(analysisValue);
  if (scoreValue === null) return null;

  return (
    <TableRow key={analysisValue.id}>
      <TableCell component="th" scope="row">
        {CONTACT_QUALI_SCORE_TITLE}
      </TableCell>
      <TableCell>
        <ContactQualiChip value={scoreValue} />
      </TableCell>
    </TableRow>
  );
};

export default QualiScoreRow;
