import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import { User } from 'src/api/generated';
import { useUsers } from './api/users';
import { FilterParam, generateFilterQueryString } from 'src/utils/queryParams';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import ErrorReporter from 'src/monitoring/errorReporter';

interface JwtPayloadWithPermissions extends JwtPayload {
  permissions?: string[];
}

interface UseCurrentUser {
  currentUser: User | null;
  loading: boolean;
}

export const useCurrentUser = (): UseCurrentUser => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const { user, isLoading: loadingAuth0 } = useAuth0();

  const email: string | undefined = user?.email;
  const filters: Record<string, FilterParam> = {};
  if (email) {
    filters.email = { value: email };
  }
  const filterBy = generateFilterQueryString(filters);
  const pageSize = 1;
  const page = 1;
  const { users, loading: loadingUsers } = useUsers({
    filterBy,
    page,
    pageSize,
  });

  useEffect(() => {
    if (!users) return;

    setCurrentUser(users[0]);
  }, [users]);

  const loading = loadingAuth0 || loadingUsers;

  return { currentUser, loading };
};

/**
 * Custom hook to retrieve the user's permissions.
 *
 * This hook uses Auth0 to silently fetch the access token, decodes it to extract the user's permissions,
 * and provides the permissions along with loading state.
 */
export const useUserPermissions = () => {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken = jwtDecode<JwtPayloadWithPermissions>(token);
        const userPermissions = decodedToken.permissions || [];
        setPermissions(userPermissions);
      } catch (error) {
        ErrorReporter.sendException(error);
      }
    };

    fetchPermissions();
  }, [getAccessTokenSilently]);

  return { permissions, loading: isLoading };
};

/**
 * Custom hook to retrieve the user's roles.
 *
 * This hook accesses the authenticated user from Auth0 and extracts roles from a custom claim.
 * Provides the roles along with loading state.
 */
export const useUserRoles = () => {
  const { user, isLoading } = useAuth0();
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    if (!user) return;

    setRoles(user['https://llamadapro.app/roles'] || []);
  }, [user]);

  return { roles, loading: isLoading };
};

export const useFilterAgent = (users: User[], currentUser: User | null) => {
  const [selectedAgentId, setSelectedAgentId] = useState<number | null>(null);

  useEffect(() => {
    const exists =
      currentUser && users && users.some((user) => user.id === currentUser.id);

    if (currentUser && exists) {
      setSelectedAgentId(currentUser.id);
    }
  }, [currentUser, users]);

  return { selectedAgentId, sendAgentSelection: setSelectedAgentId };
};
