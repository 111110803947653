import { AnalysisValueView } from 'src/api/generated';

export const getTrimmedValue = (
  analysisValue: AnalysisValueView
): string | null => {
  const value = analysisValue.value.trim();
  if (value.length === 0) return null;
  return value;
};

export const getTrimmedDescription = (
  analysisValue: AnalysisValueView
): string | null => {
  const description = analysisValue.description?.trim() || '';
  if (description.length === 0) return null;
  return description;
};

export const getTrimmedEvidence = (
  analysisValue: AnalysisValueView
): string | null => {
  if (!analysisValue.evidence) return null;
  const evidence = analysisValue.evidence.trim();
  if (evidence.length === 0) return null;
  return evidence;
};

export const getLatestAnalysisValue = (
  analysisValues: AnalysisValueView[]
): AnalysisValueView | null => {
  const findLatest = (prev: AnalysisValueView, current: AnalysisValueView) =>
    prev.createdAt > current.createdAt ? prev : current;

  const last = analysisValues.reduce(findLatest, {} as AnalysisValueView);
  if (last.id === undefined) return null;

  return last;
};

export const splitIntoChunks = (
  values: AnalysisValueView[],
  size: number
): AnalysisValueView[][] => {
  return values.reduce(
    (acc: AnalysisValueView[][], val: AnalysisValueView, i: number) => {
      if (i % size === 0) acc.push([]);
      acc[acc.length - 1].push(val);
      return acc;
    },
    []
  );
};
