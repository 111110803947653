import React from 'react';
import { Conversation } from 'src/api/generated';
import ConversationStatusDisplay from 'src/modules/messaging/components/atoms/conversations/conversation-status-display';
import {
  isConversationExpired,
  getConversationExpiration,
} from 'src/modules/messaging/utils/conversations/conversation.util';
import Label from 'src/modules/shared/components/atoms/inputs/label';
import { formatTimeDistance } from 'src/modules/shared/utils/formatting/date.util';

interface ConversationDetailsModalInfoProps {
  conversation: Conversation;
}

const ConversationDetailsModalInfo: React.FC<
  ConversationDetailsModalInfoProps
> = ({ conversation }) => {
  const conversationExpired = isConversationExpired(conversation);
  const expirationDate = getConversationExpiration(conversation);
  const timeBeforeExpiration = expirationDate
    ? formatTimeDistance(expirationDate)
    : '';

  return (
    <div className="flex flex-col space-y-2">
      <Label variant="primary" size="md">
        {/* TODO: check when implementing i18n */}
        Estado
      </Label>
      <div className="flex items-center px-4 space-x-2">
        <ConversationStatusDisplay status={conversation.status} size="md" />
        {conversationExpired && (
          <ConversationStatusDisplay status="expired" size="md" />
        )}
      </div>
      <div className="flex items-center px-4">
        {!conversationExpired && timeBeforeExpiration && (
          <span className="text-sm text-gray-500">
            La conversación expira en {timeBeforeExpiration}
          </span>
        )}
      </div>
    </div>
  );
};

export default ConversationDetailsModalInfo;
