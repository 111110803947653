import React from 'react';
import { Typography } from '@material-tailwind/react';
import { PhoneArrowDownLeftIcon } from '@heroicons/react/24/solid';
import { formatPhoneNumber } from 'src/utils/formatting/phone';
import { CallDirection } from 'src/utils/calls';

interface CallDirectionDisplayProps {
  direction: CallDirection;
  redirectedTo?: string | null;
}

const CallDirectionDisplay: React.FC<CallDirectionDisplayProps> = ({
  direction,
  redirectedTo,
}) => {
  const isIncoming = direction === 'incoming';
  const textColor = isIncoming ? 'text-green-600' : '';
  // TODO: check when implementing i18n
  const directionText = isIncoming ? 'Entrante' : 'Saliente';

  return (
    <div className="flex-col">
      <div className="flex items-center">
        <Typography className={`text-sm ${textColor}`}>
          {directionText}
        </Typography>
        {!!redirectedTo && (
          <PhoneArrowDownLeftIcon
            strokeWidth={2}
            className="h-4 w-4 ml-2 text-green-600"
          />
        )}
      </div>
      {!!redirectedTo && (
        <Typography className={`text-xs whitespace-nowrap ${textColor}`}>
          {formatPhoneNumber(redirectedTo)}
        </Typography>
      )}
    </div>
  );
};

export default CallDirectionDisplay;
