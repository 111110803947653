import { Participant, ParticipantRole } from 'src/api/generated';

export const getContactAddress = (
  participants: Participant[]
): string | undefined => {
  const agent = participants.find(
    (participant) => participant.role === ParticipantRole.CONTACT
  );
  if (!agent) return undefined;

  return agent.address;
};
