// src/components/views/reports-view/advanced-reports.tsx

import Retool from 'react-retool';
import MessageLoading from 'components/atoms/loading/message-loading';

interface AdvancedReportsProps {
  embedUrl: string | null;
}

const AdvancedReports: React.FC<AdvancedReportsProps> = ({ embedUrl }) => {
  return embedUrl ? <Retool url={embedUrl} /> : <MessageLoading />;
};

export default AdvancedReports;
