import { createFileRoute } from '@tanstack/react-router';
import AuthenticateRoute from 'components/organisms/app-layouts/authenticate-route';
import OpportunitiesView from 'components/views/opportunities-view';

export const Route = createFileRoute('/opportunities/')({
  component: OpportunitiesIndexComponent,
});

// Example to add nested routes
// https://tanstack.com/router/latest/docs/framework/react/examples/basic-file-based
function OpportunitiesIndexComponent() {
  return (
    <AuthenticateRoute>
      <OpportunitiesView />
    </AuthenticateRoute>
  );
}
