import Dialer from 'components/molecules/phone/dialer';
import OnCall from 'components/molecules/phone/on-call';
import STATES from 'states/index';
import IncomingCall from 'components/molecules/phone/incoming-call';
import Display from 'components/atoms/phone/display';
import CallButton from 'components/atoms/phone/call-button';
import MessageLoading from 'components/atoms/loading/message-loading';
import InfoError from 'components/atoms/info-error';
import {
  useCallProvider,
  useMatchContact,
  useSetPhoneNumberFromQueryParams,
} from 'hooks/phones/phones';
import { usePhoneContext } from 'src/contexts/phone-context';
import { formatPhoneNumber } from 'src/utils/formatting/phone';
import { useBooleanSetting } from 'hooks/settings/settings';
import { SettingName } from 'src/api/generated';
import ContactDisplay from 'components/molecules/phone/contact-display';
import { useUpdateSetting } from 'hooks/api/settings';
import { useAlert } from 'src/contexts/alert-context';

const Phone: React.FC = () => {
  const {
    setting: callWithoutInternetChoose,
    loading: withoutInternetChooseSettingLoading,
    error: withoutInternetChooseSettingError,
  } = useBooleanSetting(SettingName.CALL_WITHOUT_INTERNET_CHOOSE);
  const {
    setting: callingWithoutInternet,
    loading: withoutInternetSettingLoading,
    error: withoutInternetSettingError,
  } = useBooleanSetting(SettingName.CALL_WITHOUT_INTERNET);

  const { showAlert } = useAlert();
  // TODO: check when implementing i18n
  const handleSettingUpdateError = () => {
    showAlert(
      `Ocurrió un error cambiando el modo del teléfono. Por favor revisa tu conexión e intenta nuevamente.`,
      'error'
    );
  };
  const { updateSetting, loading: updateSettingLoading } = useUpdateSetting(
    undefined,
    handleSettingUpdateError
  );

  const {
    callActions,
    padActions,
    phoneConfigActions,
    loading: callProviderLoading,
    error: callProviderError,
    warnings,
    callerNumber,
    onCallNumber,
  } = useCallProvider();
  const {
    phoneState,
    formattedPhoneNumber,
    standardizedPhoneNumber,
    validNumberSelected,
    initialNumber,
    setPhoneNumber,
    isPhoneNumberEditable,
    resetPhoneNumber,
  } = usePhoneContext();
  useSetPhoneNumberFromQueryParams();
  const displayNumber = callerNumber || standardizedPhoneNumber;
  const { contact, loading: matchContactloading } =
    useMatchContact(displayNumber);
  const formattedCallerNumber = formatPhoneNumber(callerNumber);

  const loading =
    callProviderLoading ||
    withoutInternetSettingLoading ||
    withoutInternetChooseSettingLoading ||
    updateSettingLoading;
  const error =
    callProviderError ||
    withoutInternetSettingError ||
    withoutInternetChooseSettingError;

  if (loading || phoneState === STATES.PHONE.REGISTERING) {
    return <MessageLoading />;
  }

  if (error) {
    return <InfoError error={error as Error} />;
  }
  return (
    <div className="flex flex-col items-center justify-between w-[365px]">
      <ContactDisplay
        contact={contact}
        loading={matchContactloading}
        phoneConfigActions={phoneConfigActions}
        warnings={warnings}
        callingWithoutInternet={callingWithoutInternet}
      />
      <div className="flex flex-col justify-center items-center w-56 my-2 bg-white">
        {phoneState === STATES.PHONE.READY && (
          <>
            <Display
              value={formattedPhoneNumber}
              initialValue={initialNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
              onClear={resetPhoneNumber}
              disabled={!isPhoneNumberEditable}
            />
            <Dialer
              onDigitClick={padActions.onPadDigitClick}
              onDeleteClick={padActions.onPadDeleteClick}
              disabled={!isPhoneNumberEditable}
            />
            <CallButton
              startCall={callActions.startCall}
              disabled={!validNumberSelected}
              callWithoutInternetChoose={callWithoutInternetChoose}
              callingWithoutInternet={callingWithoutInternet}
              updateSetting={(useInternet) =>
                updateSetting({
                  name: SettingName.CALL_WITHOUT_INTERNET,
                  value: useInternet ? 'active' : 'inactive',
                })
              }
            />
          </>
        )}
        {phoneState === STATES.PHONE.INCOMING && (
          <IncomingCall
            onAccept={callActions.acceptCall}
            onReject={callActions.rejectCall}
            phoneNumber={formattedCallerNumber}
          />
        )}
        {phoneState === STATES.PHONE.ON_CALL && (
          <>
            <OnCall
              onHangUp={callActions.endCall}
              onMute={callActions.muteCall}
              phoneNumber={onCallNumber}
            />
            <Dialer
              onDigitClick={padActions.onCallPadDigitClick}
              fourthRowKeys={['*', '0', '#']}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Phone;
