import React from 'react';
import { CardHeader, Typography } from '@material-tailwind/react';

type ViewHeaderProps = {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
};

const ViewHeader: React.FC<ViewHeaderProps> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <CardHeader
      floated={false}
      shadow={false}
      className="rounded-none overflow-visible my-6 flex flex-col lg:flex-row gap-6 justify-between lg:items-center"
    >
      <div>
        <Typography variant="h5" className="text-blue-600">
          {title}
        </Typography>
        {subtitle && (
          <Typography color="gray" className="mt-1 font-normal">
            {subtitle}
          </Typography>
        )}
      </div>
      <div className="flex shrink-0 w-full lg:w-2/3 lg:justify-end">
        {children}
      </div>
    </CardHeader>
  );
};

export default ViewHeader;
