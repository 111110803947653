// src/components/molecules/new-opportunity-task-options/new-opportunity-task-options.tsx

import { Button, ButtonGroup, Typography } from '@material-tailwind/react';
import { addHours } from 'date-fns';
import DateTimePicker from 'components/molecules/date-time-picker';
import { getHoursToDate } from 'src/utils/date';
import { formatTimeDistance } from 'src/modules/shared/utils/formatting/date.util';

interface NewOpportunityTaskOptionsProps {
  addTaskDueInHours: (hours: number) => void;
}

const NewOpportunityTaskOptions: React.FC<NewOpportunityTaskOptionsProps> = ({
  addTaskDueInHours,
}) => {
  const formatButtonLabel = (hours: number): string => {
    const futureDate = addHours(new Date(), hours);
    return formatTimeDistance(futureDate, false);
  };

  const handleCustomDate = (date: Date | null) => {
    if (!date) return;

    const hours = getHoursToDate(date);
    addTaskDueInHours(hours);
  };

  const paddingClasses = 'px-1.5 py-0.5';
  const buttonClasses = `${paddingClasses} normal-case whitespace-nowrap`;

  return (
    <>
      <div className="flex flex-col">
        <div className="flex gap-2">
          {/* TODO: check when implementing i18n */}
          <Typography className="flex text-xs font-bold">
            ¿Quieres agendar llamada de seguimiento?
          </Typography>
        </div>
        <ButtonGroup variant="outlined" size="sm" className="mt-1">
          {[1, 4, 24].map((hour) => (
            <Button
              key={hour}
              onClick={() => addTaskDueInHours(hour)}
              className={buttonClasses}
            >
              {formatButtonLabel(hour)}
            </Button>
          ))}
          <DateTimePicker
            onAcceptValue={handleCustomDate}
            className={paddingClasses}
          />
        </ButtonGroup>
      </div>
    </>
  );
};

export default NewOpportunityTaskOptions;
