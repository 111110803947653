import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@material-tailwind/react';

type SidebarLogoutButtonProps = {
  className?: string;
};

const SidebarLogoutButton: React.FC<SidebarLogoutButtonProps> = ({
  className = '',
}) => {
  const { logout } = useAuth0();

  const baseClasses = `
    align-middle justify-center items-center transition-all
    select-none disabled:pointer-events-none
    focus:opacity-[0.85] active:opacity-[0.85]
    disabled:opacity-50 disabled:shadow-none
    focus:shadow-none active:shadow-none shadow-none drop-shadow-none
    shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20
    flex space-x-2 w-full py-3 px-4 rounded-lg
    bg-transparent hover:bg-blue-400
    font-sans font-bold text-center text-white normal-case text-md
  `;
  const classes = `${baseClasses} ${className}`;

  return (
    <Button
      onClick={() => {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      }}
      className={classes}
    >
      {/* TODO: check when implementing i18n */}
      Cerrar sesión
    </Button>
  );
};

export default SidebarLogoutButton;
