// src/hooks/api/useUsers.ts

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from './api';
import { ApiQueryParams, User, UsersService } from 'src/api/generated';

interface UsersResponse extends ApiResponse {
  users: User[];
}

export const useUsers = (queryParams: ApiQueryParams = {}): UsersResponse => {
  const fetchUsers = async (): Promise<User[]> => {
    const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

    return await UsersService.getUsers(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize
    );
  };

  const {
    data: users,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['users', queryParams],
    queryFn: fetchUsers,
  });

  return {
    loading: isLoading,
    error,
    users: users || [],
    refetch,
  };
};

export const useAgents = (): UsersResponse => {
  const fetchAgents = async (): Promise<User[]> => {
    return await UsersService.getAgents();
  };

  const {
    data: agents,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['agents'],
    queryFn: fetchAgents,
  });

  return {
    loading: isLoading,
    error,
    users: agents || [],
    refetch,
  };
};

interface UpdateUserParams {
  userId: number;
  updatedUser: Partial<User>;
}

interface UpdateUserResponse {
  loading: boolean;
  error: Error | null;
  updateUser: (updateUserParams: UpdateUserParams) => void;
}

export const useUpdateUser = (
  onUpdateSuccess?: () => void,
  onUpdateError?: () => void
): UpdateUserResponse => {
  const queryClient = useQueryClient();

  const updateUser = async ({
    userId,
    updatedUser,
  }: UpdateUserParams): Promise<User> => {
    return await UsersService.updateUser(userId, updatedUser);
  };

  const mutation = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['agents'] });
      onUpdateSuccess && onUpdateSuccess();
    },
    onError: () => onUpdateError && onUpdateError(),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    updateUser: mutation.mutate,
  };
};
