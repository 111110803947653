import { AnalysisValueView, ScoreConfigKey } from 'src/api/generated';
import { getLatestAnalysisValue } from '../../analysisValues';
import { getScoreValue, getScoresByConfigKey } from '../scores';

export const PERFORMANCE_SCORES = [
  ScoreConfigKey.AGENT_EXPERTISE,
  ScoreConfigKey.AGENT_CONFIDENCE_GENERATED,
  ScoreConfigKey.AGENT_OBJECTION_HANDLING,
  ScoreConfigKey.AGENT_CALL_CLOSING,
];

export const getTotalPerformanceValue = (
  analysisValues: AnalysisValueView[]
): number => {
  const scoreValues = PERFORMANCE_SCORES.map((scoreKey) => {
    const score = getScoresByConfigKey(analysisValues, scoreKey);
    const latestScore = getLatestAnalysisValue(score);
    return latestScore ? getScoreValue(latestScore) : 0;
  });

  return safeSum(scoreValues);
};

const safeSum = (numbers: (number | null)[]): number => {
  const sumNumbers = (acc: number, current: number | null) =>
    acc + (current ?? 0);

  return numbers.reduce(sumNumbers, 0);
};
