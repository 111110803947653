import { QueryFilters } from '@tanstack/react-query';
import { IModel, UpdateListCacheParams } from 'src/types/cache';

interface InfiniteQueryData<TModel> {
  pages: { items: TModel[] }[];
  pageParams: unknown[];
}

interface RegularQueryData<TModel> {
  count: number;
  data: TModel[];
  totalPages: number;
}

/**
 * Updates the list cache for both infinite and regular queries by matching non-exact query keys.
 *
 * @param queryClient - The query client instance used to manage the cache.
 * @param queryKey - The key used to identify the queries in the cache.
 * @param updatedData - The data that needs to be updated in the cache.
 */
export const updateListCache = <TModel extends IModel>({
  queryClient,
  queryKey,
  updatedData,
}: UpdateListCacheParams<TModel>) => {
  const filters: QueryFilters = {
    queryKey,
    exact: false,
  };

  queryClient.setQueriesData<
    InfiniteQueryData<TModel> | RegularQueryData<TModel> | undefined
  >(filters, (oldData) => {
    if (!oldData) return oldData;

    if (isInfiniteQueryData<TModel>(oldData)) {
      return updateInfiniteQueryCache(oldData, updatedData);
    }

    if (isRegularQueryData<TModel>(oldData)) {
      return updateRegularQueryCache(oldData, updatedData);
    }

    return oldData;
  });
};

/**
 * Type guard to check if the data is InfiniteQueryData.
 *
 * @param data - The data to check.
 * @returns True if data is InfiniteQueryData, false otherwise.
 */
const isInfiniteQueryData = <TModel>(
  data: unknown
): data is InfiniteQueryData<TModel> => {
  return (
    typeof data === 'object' &&
    data !== null &&
    'pages' in data &&
    Array.isArray((data as InfiniteQueryData<TModel>).pages)
  );
};

/**
 * Type guard to check if the data is RegularQueryData.
 *
 * @param data - The data to check.
 * @returns True if data is RegularQueryData, false otherwise.
 */
const isRegularQueryData = <TModel>(
  data: unknown
): data is RegularQueryData<TModel> => {
  return (
    typeof data === 'object' &&
    data !== null &&
    'data' in data &&
    Array.isArray((data as RegularQueryData<TModel>).data)
  );
};

/**
 * Updates the infinite query cache with the provided updated data.
 *
 * @param oldData - The existing infinite query data in the cache.
 * @param updatedData - The data that needs to be updated in the cache.
 * @returns The updated infinite query data.
 */
const updateInfiniteQueryCache = <TModel extends IModel>(
  oldData: InfiniteQueryData<TModel>,
  updatedData: TModel
) => {
  const newPages = oldData.pages.map((page) => {
    const newItems = page.items.map((item) =>
      item.id === updatedData.id ? updatedData : item
    );
    return { ...page, items: newItems };
  });

  const itemExists = newPages.some((page) =>
    page.items.some((item) => item.id === updatedData.id)
  );

  if (!itemExists && newPages.length > 0) {
    newPages[0].items = [updatedData, ...newPages[0].items];
  }

  return { ...oldData, pages: newPages };
};

/**
 * Updates the regular query cache with the provided updated data.
 *
 * @param oldData - The existing regular query data in the cache.
 * @param updatedData - The data that needs to be updated in the cache.
 * @returns The updated regular query data.
 */
const updateRegularQueryCache = <TModel extends IModel>(
  oldData: RegularQueryData<TModel>,
  updatedData: TModel
) => {
  const index = oldData.data.findIndex((item) => item.id === updatedData.id);
  let newDataArray = [...oldData.data];

  if (index !== -1) {
    newDataArray[index] = updatedData;
  } else {
    newDataArray = [updatedData, ...newDataArray];
    oldData.count += 1;
  }

  return {
    ...oldData,
    data: newDataArray,
    count: oldData.count,
  };
};
