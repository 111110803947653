import FixedDrawer from 'components/molecules/fixed-drawer';
import { PhoneIcon } from '@heroicons/react/24/solid';
import { usePhoneContext } from 'src/contexts/phone-context';
import { ReactNode, useEffect } from 'react';
import STATES from 'states/index';
import { Typography } from '@material-tailwind/react';

interface DrawerPhoneProps {
  id?: string;
  phoneComponent: ReactNode;
  headerComponent?: ReactNode;
  isOpen: boolean;
  toggle: (shouldOpen: boolean) => void;
  className?: string;
  bodyClassName?: string;
  style?: React.CSSProperties;
}

const DrawerPhone: React.FC<DrawerPhoneProps> = ({
  id,
  phoneComponent,
  headerComponent,
  isOpen,
  toggle,
  className = '',
  bodyClassName = '',
  style,
}) => {
  const { phoneState, keyboardVisible, setKeyboardVisible } = usePhoneContext();

  const incomingCall = phoneState === STATES.PHONE.INCOMING;

  useEffect(() => {
    if (incomingCall || keyboardVisible) {
      toggle(true);
    }
  }, [incomingCall, keyboardVisible, toggle]);

  const toggleDrawer = () => {
    setKeyboardVisible(!isOpen);
    toggle(!isOpen);
  };

  return (
    <FixedDrawer
      id={id}
      isOpen={isOpen}
      onToggle={toggleDrawer}
      headerContent={
        <>
          <PhoneIcon strokeWidth={2} className="h-6 w-6 text-blue-50" />
          <div className="flex flex-col justify-center items-center">
            {headerComponent || (
              <Typography className="text-blue-50 text-sm">
                {/* TODO: check when implementing i18n */}
                LlamadaPro
              </Typography>
            )}
          </div>
        </>
      }
      bodyContent={phoneComponent}
      className={className}
      bodyClassName={bodyClassName}
      style={style}
    />
  );
};

export default DrawerPhone;
