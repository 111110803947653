/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NumberTransferDestination = {
    /**
     * This is the description of the destination, used by the AI to choose when and how to transfer the call.
     */
    description?: string;
    /**
     * This is the message to say before transferring the call to the destination.
     */
    message?: string;
    /**
     * This is the phone number to transfer the call to.
     */
    number: string;
    type: NumberTransferDestination.type;
};

export namespace NumberTransferDestination {

    export enum type {
        NUMBER = 'number',
    }


}

