// src/hooks/api/recordings.ts

import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from './api';
import {
  CallsService,
  RecordingsService,
  SignedRecording,
} from 'src/api/generated';

interface RecordingResponse extends ApiResponse {
  recording: SignedRecording | null;
}

export const useRecording = (
  recordingId?: number,
  enabled = true
): RecordingResponse => {
  const fetchRecording = async (): Promise<SignedRecording | null> => {
    if (!recordingId) {
      return null;
    }

    return await RecordingsService.getSignedRecording(recordingId);
  };

  const {
    data: recording,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['signedRecording', recordingId],
    queryFn: fetchRecording,
    enabled,
  });

  return {
    loading,
    error,
    recording: recording || null,
    refetch,
  };
};

export const useCallRecording = (
  callId?: number,
  enabled = true
): RecordingResponse => {
  const fetchRecording = async (): Promise<SignedRecording | null> => {
    if (!callId) {
      return null;
    }

    return await CallsService.getCallSignedRecording(callId);
  };

  const {
    data: recording,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['calls/signedRecording', callId],
    queryFn: fetchRecording,
    enabled,
  });

  return {
    loading,
    error,
    recording: recording || null,
    refetch,
  };
};
