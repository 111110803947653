import { SortOrder } from 'components/molecules/tables/sorting-header';
import { useState } from 'react';
import { ApiQueryParams } from 'src/api/generated';
import { generateSortQueryString } from 'src/utils/queryParams';

export const useDataFilteringQueryParams = (
  defaultSortField: string | null,
  defaultSortOrder: SortOrder,
  defaultPageSize: number | null
) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(defaultPageSize || 10);
  const [sortField, setSortField] = useState<string | null>(
    defaultSortField || null
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    defaultSortOrder || null
  );

  const queryParams: ApiQueryParams = {
    page,
    pageSize,
  };
  if (sortField && sortOrder) {
    queryParams.sortBy = generateSortQueryString({ [sortField]: sortOrder });
  }

  const resetTable = () => {
    setSortOrder(defaultSortOrder);
    setSortField(defaultSortField);
    setPage(1);
  };

  const selectSortField = (sortField: string | null): void => {
    resetTable();
    setSortField(sortField);
  };

  return {
    dataFilteringQueryParams: queryParams,
    sortField,
    selectSortField,
    sortOrder,
    setSortOrder,
    page,
    setPage,
    pageSize,
    setPageSize,
  };
};
