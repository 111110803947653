// src/modules/messaging/whatsapp/hooks/api/templates.ts

import { useQuery } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from 'hooks/api/api';
import {
  Alias_WhatsAppTemplateListQueryParams as WhatsAppTemplatesListParams,
  MessagingWhatsappTemplatesService,
  WhatsAppTemplateListResponse,
  WhatsAppTemplate,
} from 'src/api/generated';
import { MakeOptional } from 'src/modules/shared/utils/types.util';

interface UseWhatsAppTemplatesListResponse extends ApiResponse {
  templates: WhatsAppTemplate[];
}

interface UseWhatsAppTemplatesListParams
  extends MakeOptional<WhatsAppTemplatesListParams, 'integrationProviderId'> {}

export const useWhatsAppTemplates = (
  params: UseWhatsAppTemplatesListParams
): UseWhatsAppTemplatesListResponse => {
  const fetchTemplates = async (): Promise<WhatsAppTemplateListResponse> => {
    const { tenantId, integrationProviderId } = params;
    if (!integrationProviderId) return [];

    return await MessagingWhatsappTemplatesService.getTemplates(
      integrationProviderId,
      tenantId
    );
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['messaging/whatsapp/templates', params],
    queryFn: fetchTemplates,
    enabled: !!params.integrationProviderId,
  });

  const templates = useMemoizedArrayData<WhatsAppTemplate>(data);

  return {
    loading,
    error,
    templates,
    refetch,
  };
};
