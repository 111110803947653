import React from 'react';
import { Typography } from '@material-tailwind/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

interface InputErrorProps {
  message?: string;
  className?: string;
}

const InputError: React.FC<InputErrorProps> = ({ message, className = '' }) => {
  if (!message) return null;

  return (
    <div className={`flex items-center gap-1 mt-1 ${className}`}>
      <ExclamationCircleIcon className="h-4 w-4 text-red-500" />
      <Typography variant="small" color="red" className="font-normal">
        {message}
      </Typography>
    </div>
  );
};

export default InputError;
