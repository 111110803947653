// src/components/molecules/analysis/contact-quali-chip.tsx

import { Chip } from '@material-tailwind/react';
import {
  getContactQualiLabel,
  getContactQualiLabelColor,
  isMissingContactQuali,
} from 'src/utils/analysisValues/scores/contactQuali';
import QualiValue from './quali-value';

interface ContactQualiChipProps {
  value: number | null;
}

const ContactQualiChip: React.FC<ContactQualiChipProps> = ({ value }) => {
  const label = getContactQualiLabel(value);
  const color = getContactQualiLabelColor(value);

  const icon = <QualiValue value={value} />;

  const isMissing = isMissingContactQuali(value);
  const missingClass = isMissing ? 'bg-white' : '';

  return (
    <Chip
      variant="ghost"
      className={`w-fit px-4 ${missingClass}`}
      color={color}
      size="sm"
      value={label}
      icon={icon}
    />
  );
};

export default ContactQualiChip;
