import React from 'react';
import { Participant } from 'src/api/generated';
import { Typography } from '@material-tailwind/react';
import Label from 'src/modules/shared/components/atoms/inputs/label';

interface ConversationDetailsModalParticipantListProps {
  participants: Participant[];
  mainParticipantAddress: string;
}

const ConversationDetailsModalParticipantList: React.FC<
  ConversationDetailsModalParticipantListProps
> = ({ participants, mainParticipantAddress }) => (
  <div className="flex flex-col space-y-2">
    <Label variant="primary" size="md">
      {/* TODO: check when implementing i18n */}
      Participantes
    </Label>
    <ul className="flex flex-col space-y-1 px-4">
      {participants.map((participant) => {
        const isMainParticipant =
          participant.address === mainParticipantAddress;
        const listItemClass = `flex items-center p-2 border-b ${
          isMainParticipant ? 'bg-blue-100 border-blue-300' : 'bg-gray-50'
        }`;
        const displayNameClass = `text-sm font-semibold ${
          isMainParticipant ? 'text-blue-600' : ''
        }`;

        return (
          <li key={participant.providerId} className={listItemClass}>
            <div className="flex-1">
              {participant.displayName && (
                <Typography className={displayNameClass}>
                  {participant.displayName}
                </Typography>
              )}
              <Typography className="text-xs text-gray-500">
                {participant.address}
              </Typography>
            </div>
          </li>
        );
      })}
    </ul>
  </div>
);

export default ConversationDetailsModalParticipantList;
