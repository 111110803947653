// src/components/molecules/charts/utils.ts

import { Options } from 'chartjs-plugin-datalabels/types/options';
import { Context } from 'chartjs-plugin-datalabels/types/context';

// NOTE: the library defines the "value" parameter as any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addSumOnTopFormatter = (_: number, context: Context) => {
  const chartData = context.chart.data;
  const datasets = chartData.datasets;

  const datasetDataArray: number[] = [];
  datasets.forEach((dataset) => {
    const datasetData = dataset.data;

    const data: number = datasetData[context.dataIndex] as number;
    datasetDataArray.push(data);
  });

  const sum = (total: number, datapoint: number) => total + datapoint;
  const datasetsDataSum = datasetDataArray.reduce(sum, 0);

  const isLastDataset = context.datasetIndex === datasetDataArray.length - 1;
  if (isLastDataset && datasetsDataSum > 0) {
    return datasetsDataSum;
  }
  return null;
};

// documentation reference: https://chartjs-plugin-datalabels.netlify.app/
export const totalDatalabelPlugin: Options = {
  anchor: 'end',
  align: 'top',
  offset: 0,
  font: {
    size: 11,
  },
  formatter: addSumOnTopFormatter,
};
