// src/hooks/analysis/summary/useSummaryContent.ts

import { useAnalysisValues } from './analysisValues';
import { useCopyableContent } from './copyableContent';
import { useExtractions } from './extractions';
import { useForbiddenWords } from './forbiddenWords';
import { useLatestContactQualiScore } from './latestContactQualiScore';
import { usePublicScores } from './publicScores';

interface UseSummaryContentProps {
  callId: number;
  enabled?: boolean;
  onCopyableContentChange?: (content: string) => void;
}

export const useSummaryContent = ({
  callId,
  enabled = true,
  onCopyableContentChange,
}: UseSummaryContentProps) => {
  const { analysisValues, loading } = useAnalysisValues({ callId, enabled });

  const extractions = useExtractions(analysisValues);
  const latestContactQualiScore = useLatestContactQualiScore(analysisValues);
  const publicScores = usePublicScores(analysisValues, latestContactQualiScore);
  const forbiddenWords = useForbiddenWords(analysisValues);

  useCopyableContent({
    loading,
    analysisValues,
    latestContactQualiScore,
    publicScores,
    extractions,
    onCopyableContentChange,
  });

  return {
    loading,
    extractions,
    latestContactQualiScore,
    publicScores,
    forbiddenWords,
  };
};
