// src/hooks/api/teams.ts

import { useQuery } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from './api';
import { ApiQueryParams, Team, TeamsService } from 'src/api/generated';

interface TeamsResponse extends ApiResponse {
  teams: Team[];
}

export const useTeams = (queryParams: ApiQueryParams = {}): TeamsResponse => {
  const fetchTeams = async (): Promise<Team[]> => {
    const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

    return await TeamsService.getTeams(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize
    );
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['teams', queryParams],
    queryFn: fetchTeams,
  });
  const teams = useMemoizedArrayData<Team>(data);

  return {
    loading: isLoading,
    error,
    teams,
    refetch,
  };
};
