// src/components/molecules/analysis/feedback-modal-content/strength-row/strength-row.tsx

import { TableRow, TableCell } from '@mui/material';
import { Chip } from '@material-tailwind/react';
import { HandThumbUpIcon } from '@heroicons/react/24/outline';
import { getStrengthValue } from 'src/utils/analysisValues/strengths/strengths';
import { AnalysisValueView } from 'src/api/generated';

const STRENGTH_COLOR = 'light-green';
// TODO: check when implementing i18n
const STRENGTH_TITLE = 'Fortaleza';

const StrengthRow = ({ strength }: { strength: AnalysisValueView }) => {
  const strengthValue = getStrengthValue(strength);
  if (!strengthValue) return null;

  return (
    <TableRow key={strength.id}>
      <TableCell component="th" scope="row">
        <div className="flex flex-col gap-2 m-2 items-center md:items-start">
          <Chip
            variant="ghost"
            className="w-fit px-4 py-1.5"
            color={STRENGTH_COLOR}
            size="sm"
            value={STRENGTH_TITLE}
            icon={<HandThumbUpIcon className="h-4 w-4" />}
          />
          <div className="text-justify">{strengthValue}</div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default StrengthRow;
