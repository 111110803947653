// src/modules/messaging/whatsapp/hooks/api/phones.ts

import { useQuery } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from 'hooks/api/api';
import {
  Alias_WhatsAppPhoneListQueryParams as WhatsAppPhonesListParams,
  MessagingWhatsappPhonesService,
  WhatsAppPhoneListResponse,
  IntegrationAddress,
} from 'src/api/generated';

interface UseWhatsAppPhoneListResponse extends ApiResponse {
  phones: IntegrationAddress[];
}

interface UseWhatsAppPhoneListParams extends WhatsAppPhonesListParams {
  enabled?: boolean;
}

export const useWhatsAppPhones = ({
  enabled = true,
  ...params
}: UseWhatsAppPhoneListParams = {}): UseWhatsAppPhoneListResponse => {
  const fetchPhones = async (): Promise<WhatsAppPhoneListResponse> => {
    const { tenantId } = params;

    return await MessagingWhatsappPhonesService.getPhones(tenantId);
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['messaging/whatsapp/phones', params],
    queryFn: fetchPhones,
    enabled,
  });

  const phones = useMemoizedArrayData<IntegrationAddress>(data);

  return {
    loading,
    error,
    phones,
    refetch,
  };
};
