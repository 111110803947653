import React from 'react';
import { Tabs, TabsHeader, Tab } from '@material-tailwind/react';

export type Filter = {
  column: string;
  value: number | string;
};

export type FilterOption = {
  label: string;
  filters: Filter[];
};

type FiltersProps = {
  filterOptions: FilterOption[];
  selectedFilter: FilterOption;
  onFilterChange: (filterValue: FilterOption) => void;
  headerElement?: React.ReactNode;
};

const Filters: React.FC<FiltersProps> = ({
  filterOptions,
  selectedFilter,
  onFilterChange,
  headerElement,
}) => {
  if (filterOptions.length < 2) return '';

  const handleFilterChange = (label: string) => {
    const filter: FilterOption | undefined = filterOptions.find(
      (filter) => filter.label === label
    );
    if (!filter) {
      return onFilterChange(filterOptions[0]);
    }
    onFilterChange(filter);
  };

  return (
    <div className="flex w-full justify-between">
      <Tabs
        // key required to re-render the component when selectedFilter changes
        // https://github.com/creativetimofficial/material-tailwind/issues/364
        key={`${selectedFilter.label}-tab`}
        value={selectedFilter.label}
        className="w-fit whitespace-nowrap m-3 mt-0 overflow-visible"
      >
        <TabsHeader className="items-center">
          {filterOptions.map((filter) => (
            <Tab
              key={filter.label}
              value={filter.label}
              onClick={() => handleFilterChange(filter.label)}
              className="px-8"
            >
              {filter.label}
            </Tab>
          ))}
        </TabsHeader>
      </Tabs>
      <div id="filter-header" className="flex m-3 mt-0">
        {headerElement}
      </div>
    </div>
  );
};

export default Filters;
