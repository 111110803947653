// src/hooks/api/calls/call-logs.ts

import { useQuery } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from '../api';
import {
  ApiQueryParams,
  CallLog,
  CallsService,
  PaginatedData_CallLog_,
} from 'src/api/generated';

interface CallLogsResponse extends ApiResponse {
  callLogs: CallLog[];
  totalPages: number;
}

export const useCallLogs = (
  queryParams: ApiQueryParams = {}
): CallLogsResponse => {
  const fetchCallLogs = async (): Promise<PaginatedData_CallLog_> => {
    const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;
    return await CallsService.getCallLogs(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize
    );
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['calls/logs', queryParams],
    queryFn: fetchCallLogs,
  });
  const callLogs = useMemoizedArrayData<CallLog>(data?.data);
  const totalPages = data?.totalPages || 1;

  return {
    loading,
    error,
    callLogs,
    totalPages,
    refetch,
  };
};
