// src/components/molecules/agent-select/agent-select.tsx

import Select from 'components/atoms/select';
import { SelectData } from 'tw-elements-react/dist/types/forms/Select/types';
import { SettingName, User } from 'src/api/generated';
import { formatFullName } from 'src/utils/formatting/name';
import { useFeatureFlagActive } from 'hooks/settings/feature-flags';
import { useCurrentUser } from 'hooks/users';

interface AgentSelectProps {
  agents: User[];
  selectedAgent: number | null;
  onAgentSelectionChange: (selectedAgent: number | null) => void;
}

const AgentSelect: React.FC<AgentSelectProps> = ({
  agents,
  selectedAgent,
  onAgentSelectionChange,
}) => {
  // TODO: fix when implementing admin/profiles
  const { currentUser } = useCurrentUser();
  const isAdmin = currentUser?.profile === 'admin';
  const showAllCalls = useFeatureFlagActive(
    SettingName.FEATURE_FLAG_AGENT_SHOW_ALL_CALLS
  );
  const onlyShowCurrentUserCalls = !isAdmin && !showAllCalls;

  const ALL_AGENTS = -1;

  const formatAgent = (agent: User) => ({
    text: formatFullName(agent.firstName, agent.lastName),
    value: agent.id,
  });

  const generateData = () => {
    if (onlyShowCurrentUserCalls) {
      return [currentUser].map((agent) => {
        if (!agent) return {};
        return formatAgent(agent);
      });
    }

    // TODO: check when implementing i18n
    const defaultData = [{ text: 'Todos los agentes', value: ALL_AGENTS }];
    return defaultData.concat(agents.map(formatAgent));
  };

  const handleAgentSelectionChange = (
    selectedData: SelectData | SelectData[]
  ) => {
    const selectAllAgents = () => onAgentSelectionChange(null);

    if (!selectedData || Array.isArray(selectedData)) {
      return selectAllAgents();
    }

    const agentId = selectedData.value as number;
    if (agentId === ALL_AGENTS) {
      return selectAllAgents();
    }

    const selectOneAgent = () => onAgentSelectionChange(agentId);
    selectOneAgent();
  };

  return (
    <Select
      data={generateData()}
      search
      // TODO: check when implementing i18n
      searchLabel="Buscar agentes..."
      label="Filtrar por agente"
      value={selectedAgent || ALL_AGENTS}
      onValueChange={handleAgentSelectionChange}
      selectAll
      // TODO: check when implementing i18n
      noResultsText="No hay resultados"
      containerClassName="max-w-[16rem]"
    />
  );
};

export default AgentSelect;
