import React from 'react';
import { TESelect } from 'tw-elements-react';
import { SelectProps as TESelectProps } from 'tw-elements-react/dist/types/forms/Select/types';

interface SelectProps extends TESelectProps {
  containerClassName?: string;
}

const Select: React.FC<SelectProps> = (props) => {
  const { className, containerClassName, ...rest } = props;

  const classes = `
    text-sm ${className || ''}
  `;

  return (
    <div className={`relative flex-grow ${containerClassName}`}>
      <TESelect {...rest} className={classes} />
    </div>
  );
};

export default Select;
