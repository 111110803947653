/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Interface representing a message status DTO.
 */
export enum MessageStatus {
    PENDING = 'pending',
    SENT = 'sent',
    FAILED = 'failed',
    DELIVERED = 'delivered',
    READ = 'read',
    DELETED = 'deleted',
    WARNING = 'warning',
}
