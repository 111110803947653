// src/components/molecules/calls/recording-player/recording-player.tsx

import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Spinner } from '@material-tailwind/react';
import InlinePlayButton from 'components/atoms/buttons/inline-play-button';
import InlineCancelButton from 'components/atoms/buttons/inline-cancel-button';
import style from './recording-player.module.css';
import { useCallRecording } from 'hooks/api/recordings';
import { SettingName } from 'src/api/generated';
import { useCurrentUser } from 'hooks/users';
import { useFeatureFlagActive } from 'hooks/settings/feature-flags';

interface RecordingPlayerProps {
  callId?: number;
  className?: string;
  buttonClassName?: string;
  iconClassName?: string;
  playerWidth?: number;
  playerHeight?: number;
}

const RecordingPlayer: React.FC<RecordingPlayerProps> = ({
  callId,
  className = '',
  buttonClassName = '',
  iconClassName = '',
  playerWidth = 300,
  playerHeight = 40,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { recording, loading } = useCallRecording(callId, open);

  // TODO: fix when implementing admin/profiles
  const { currentUser } = useCurrentUser();
  const isAdmin = currentUser?.profile === 'admin';
  const adminDownloadRecordingsActive = useFeatureFlagActive(
    SettingName.FEATURE_FLAG_ADMIN_DOWNLOAD_RECORDINGS
  );

  const controlsList =
    isAdmin && adminDownloadRecordingsActive ? '' : 'nodownload';

  const recordingUrl = recording?.url;

  const openPlayer = () => {
    setOpen(true);
  };
  const closePlayer = () => {
    setOpen(false);
  };

  const noRecording = !callId;

  const inlineButtonsClasses = `
    w-10 h-10
    ${noRecording ? 'invisible' : ''}
    ${buttonClassName}
  `;
  const inlineIconsClasses = `w-6 h-6  text-blue-gray-700 ${iconClassName}`;
  const playerContainerMargin = `left-[-${playerWidth}px]`;
  const smallPlayer = playerWidth < 250;
  const playerClasses = smallPlayer ? style.small : '';

  if (loading) {
    return (
      <div
        className={`flex w-10 h-10 bg-gray-100 rounded-md items-center justify-center ${className}`}
      >
        <Spinner />
      </div>
    );
  }
  if (!open) {
    return (
      <InlinePlayButton
        onClick={openPlayer}
        className={inlineButtonsClasses}
        iconClassName={inlineIconsClasses}
      />
    );
  }
  return (
    <div className="relative w-fit rounded-md">
      <>
        {open && recordingUrl && (
          <div
            className={`absolute right-0 bg-gray-100 rounded-md pr-5 ${playerContainerMargin} ${className}`}
          >
            <ReactPlayer
              url={recordingUrl}
              controls={true}
              config={{
                file: {
                  forceAudio: true,
                  attributes: {
                    controlsList,
                  },
                },
              }}
              width={`${playerWidth}px`}
              height={`${playerHeight}px`}
              className={playerClasses}
            />
          </div>
        )}
        {open && (
          <InlineCancelButton
            onClick={closePlayer}
            className={inlineButtonsClasses}
            iconClassName={inlineIconsClasses}
          />
        )}
      </>
    </div>
  );
};

export default RecordingPlayer;
