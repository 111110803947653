import React from 'react';
import { Button, Typography } from '@material-tailwind/react';

type PaginatorProps = {
  currentPage: number;
  totalPages: number;
  onPageChange: (value: number) => void;
};

const Paginator: React.FC<PaginatorProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  if (totalPages < 2) return '';

  const goToPreviousPage = () => {
    const previousPage: number = Math.max(currentPage - 1, 1);
    onPageChange(previousPage);
  };
  const goToNextPage = () => {
    const nextPage: number = Math.min(currentPage + 1, totalPages);
    onPageChange(nextPage);
  };

  return (
    <div className="flex flex-col items-center border-t border-blue-gray-50 p-4">
      <div className="flex gap-2 m-2">
        <Button
          variant="outlined"
          size="sm"
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
        >
          Anterior
        </Button>
        <Button
          variant="outlined"
          size="sm"
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
        >
          Siguiente
        </Button>
      </div>
      <Typography variant="small" color="blue-gray" className="font-normal">
        Página {currentPage} de {totalPages}
      </Typography>
    </div>
  );
};

export default Paginator;
