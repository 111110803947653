/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type representing a participant role.
 */
export enum ParticipantRole {
    USER = 'user',
    CONTACT = 'contact',
    SYSTEM = 'system',
}
