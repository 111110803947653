import AppExtensionsSDK, { Command } from '@pipedrive/app-extensions-sdk';

export const hideFloatingWindow = async (sdk: AppExtensionsSDK) => {
  console.info('Hiding floating window');
  await sdk.execute(Command.HIDE_FLOATING_WINDOW, {});
};

export const showFloatingWindow = async (sdk: AppExtensionsSDK) => {
  console.info('Showing floating window');
  await sdk.execute(Command.SHOW_FLOATING_WINDOW, {});
};

// other pipedrive actions

// export const openActivityModal = async (sdk: AppExtensionsSDK) => {
//   console.info('Opening activity modal');
//   await sdk.execute(Command.OPEN_MODAL, {
//     type: Modal.ACTIVITY,
//   });
// };

// export const redirectToContact = async (sdk: AppExtensionsSDK, id) => {
//   console.info(`Redirecting to ${id}`);
//   await sdk.execute(Command.REDIRECT_TO, { view: View.CONTACTS, id });
// };

// export const setNotification = async (sdk: AppExtensionsSDK, number) => {
//   console.info(`Updating notification count to ${number}`);
//   await sdk.execute(Command.SET_NOTIFICATION, {
//     number,
//   });
// };
