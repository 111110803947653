import React from 'react';
import ReactPlayer from 'react-player';

interface AudioMediaProps {
  url: string;
  playerWidth?: number;
  playerHeight?: number;
  containerClassName?: string;
  className?: string;
  controlsList?: string;
}

export const AudioMedia: React.FC<AudioMediaProps> = ({
  url,
  playerWidth = 300,
  playerHeight = 40,
  containerClassName = '',
  className = '',
  controlsList = '',
}) => {
  const containerClasses = `bg-gray-100 rounded-lg left-[-250px] ${containerClassName}`;
  const playerClasses = `${className}`;

  return (
    <div className={containerClasses}>
      <ReactPlayer
        url={url}
        controls={true}
        config={{
          file: {
            forceAudio: true,
            attributes: {
              controlsList,
            },
          },
        }}
        width={`${playerWidth}px`}
        height={`${playerHeight}px`}
        className={playerClasses}
      />
    </div>
  );
};

export default AudioMedia;
