// src/components/molecules/analysis/contact-quali-chip/quali-value/quali-value.tsx

import { Typography } from '@material-tailwind/react';
import { isMissingContactQuali } from 'src/utils/analysisValues/scores/contactQuali';

interface QualiValueProps {
  value?: number | null;
}

const QualiValue: React.FC<QualiValueProps> = ({ value }) => {
  const valueText = isMissingContactQuali(value) ? '' : value;

  return (
    <Typography className="text-xs font-black ml-2 leading-4">
      {valueText}
    </Typography>
  );
};

export default QualiValue;
