import { useEffect, useState } from 'react';
/**
 * @module TemplateSuggestions
 * Provides functionality for template suggestions in messaging input
 */

import { Template, ApiQueryParams } from 'src/api/generated';
import { useTemplates } from '../api/templates';

/** Maximum number of template suggestions to display per page */
const SUGGESTIONS_PAGE_SIZE = 5;

/**
 * Props for the useTemplateSuggestions hook
 */
interface UseTemplateSuggestionsProps {
  inputValue: string;
  triggerCharacter?: string;
  onSelect?: (template: Template) => void;
}

/**
 * Return type for the useTemplateSuggestions hook
 */
interface UseTemplateSuggestionsReturn {
  isOpen: boolean;
  suggestions: Template[];
  selectedIndex: number;
  loading: boolean;
  hasMorePages: boolean;
  handleKeyDown: (e: React.KeyboardEvent) => void;
  handleSelect: (template: Template) => void;
  fetchMore: () => void;
  handleClose: () => void;
}

// TODO: move some of this logic to /hooks/scroll.ts, and refactor
// TODO: fix double fetch
/**
 * Hook that provides template suggestions based on user input.
 *
 * @param props.inputValue - The current value of the input field.
 * @param props.triggerCharacter - The character that triggers the suggestions.
 * @param props.onSelect - The function to call when a suggestion is selected.
 * @returns The state and handlers for template suggestions.
 */
export const useTemplateSuggestions = ({
  inputValue,
  triggerCharacter = '/',
  onSelect,
}: UseTemplateSuggestionsProps): UseTemplateSuggestionsReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [accumulatedTemplates, setAccumulatedTemplates] = useState<Template[]>(
    []
  );

  const queryParams: ApiQueryParams | undefined = isOpen
    ? {
        searchBy: searchTerm,
        page,
        pageSize: SUGGESTIONS_PAGE_SIZE,
      }
    : undefined;

  const { templates, loading, totalPages } = useTemplates(queryParams, isOpen);
  const hasMorePages = page < totalPages;

  useEffect(() => {
    if (!templates) return;

    if (page === 1) {
      setAccumulatedTemplates(templates);
      return;
    }

    setAccumulatedTemplates((prev) => {
      const newTemplates = templates.filter((newTemplate) => {
        const isUnique = !prev.some(
          (existingTemplate) => existingTemplate.id === newTemplate.id
        );
        return isUnique;
      });

      return [...prev, ...newTemplates];
    });
  }, [templates, page]);

  useEffect(() => {
    const lastWord = inputValue.trim().split(' ').pop() || '';
    const isTriggerActive = lastWord.startsWith(triggerCharacter);

    if (!isTriggerActive) {
      handleClose();
      return;
    }

    const newSearchTerm = lastWord.slice(triggerCharacter.length);
    if (newSearchTerm === searchTerm && isOpen) return;

    setSearchTerm(newSearchTerm);
    setPage(1);
    setSelectedIndex(0);
    if (!isOpen) {
      setAccumulatedTemplates([]);
      setIsOpen(true);
    }
  }, [inputValue, searchTerm, triggerCharacter, isOpen]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!isOpen) return;

    const keyActions: Record<string, () => void> = {
      ArrowDown: handleArrowDown,
      ArrowUp: handleArrowUp,
      Enter: handleEnter,
      Escape: handleEscape,
    };

    const action = keyActions[e.key];
    if (!action) return;

    e.preventDefault();
    action();
  };

  const handleArrowDown = () => {
    const nextIndex = selectedIndex + 1;
    const isNearEnd = nextIndex >= accumulatedTemplates.length - 2;
    const shouldFetchMore = isNearEnd && hasMorePages && !loading;

    if (shouldFetchMore) {
      fetchMore();
    }
    if (selectedIndex >= accumulatedTemplates.length - 1) return;

    setSelectedIndex(nextIndex);
  };

  const handleArrowUp = () => {
    setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleEnter = () => {
    const selectedTemplate = accumulatedTemplates[selectedIndex];
    if (!selectedTemplate) return;

    handleSelect(selectedTemplate);
  };

  const handleEscape = () => {
    handleClose();
  };

  const handleSelect = (template: Template) => {
    onSelect?.(template);
    handleClose();
  };

  const fetchMore = () => {
    if (loading || !hasMorePages) return;

    setPage((prev) => prev + 1);
  };

  const handleClose = () => {
    setIsOpen(false);
    setSelectedIndex(0);
    setSearchTerm('');
    setPage(1);
    setAccumulatedTemplates([]);
  };

  return {
    isOpen,
    suggestions: accumulatedTemplates,
    selectedIndex,
    loading,
    hasMorePages,
    handleKeyDown,
    handleSelect,
    fetchMore,
    handleClose,
  };
};
