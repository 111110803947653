import { AnalysisConfigurableType, AnalysisValueView } from 'src/api/generated';
import { getTrimmedValue } from '../analysisValues';

export const getForbiddenWords = (
  analysisValues: AnalysisValueView[]
): AnalysisValueView[] => {
  return analysisValues.filter(isForbiddenWordValue);
};

export const getForbiddenWordValue = (
  analysisValue: AnalysisValueView
): string | null => {
  if (!isForbiddenWordValue(analysisValue)) return null;

  const trimmedValue = getTrimmedValue(analysisValue);
  return trimmedValue;
};

const isForbiddenWordValue = (analysisValue: AnalysisValueView): boolean => {
  return (
    analysisValue.analysisConfigurableType ===
    AnalysisConfigurableType.FORBIDDEN_WORD
  );
};
