import {
  isValidPhoneNumber as isValidPhone,
  CountryCode,
} from 'libphonenumber-js';

/**
 * Check if string represents a valid phone number
 * @param phoneNumber - phone number to validate
 * @param defaultCountry - default country code. Used to validate phone numbers without country code
 * @returns true if the phone number is valid
 */
export const isValidPhoneNumber = (
  phoneNumber?: string | null,
  defaultCountry?: CountryCode
) => {
  if (!phoneNumber) {
    return false;
  }

  return isValidPhone(phoneNumber, defaultCountry);
};
