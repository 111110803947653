// src/hooks/api/call-sessions.ts

import {
  CreateCallSessionParams,
  CreateTwilioCallSessionResponse,
  DefaultService,
} from '../../../api/generated';
import { useMutation } from '@tanstack/react-query';

interface CreateCallSessionResponse {
  loading: boolean;
  error: Error | null;
  callSession?: CreateTwilioCallSessionResponse | null;
  createCallSession: (createCallSessionParams: CreateCallSessionParams) => void;
}

export const useCreateCallSession = (
  onUpdateSuccess?: () => void,
  onUpdateError?: () => void
): CreateCallSessionResponse => {
  const createCallSession = async (
    createCallSessionParams: CreateCallSessionParams
  ): Promise<CreateTwilioCallSessionResponse | null> => {
    return await DefaultService.createCallSession(createCallSessionParams);
  };

  const mutation = useMutation({
    mutationFn: createCallSession,
    onSuccess: () => {
      onUpdateSuccess && onUpdateSuccess();
    },
    onError: () => onUpdateError && onUpdateError(),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    callSession: mutation.data,
    createCallSession: mutation.mutate,
  };
};
