// src/components/molecules/analysis/feedback-modal-content/goal-row/goal-row.tsx

import { TableRow, TableCell } from '@mui/material';
import { Chip } from '@material-tailwind/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { AnalysisValueView } from 'src/api/generated';
import {
  getGoalValue,
  getGoalEvidence,
} from 'src/utils/analysisValues/goals/goals';

const GOAL_COLOR = 'orange';
// TODO: check when implementing i18n
const GOAL_TITLE = 'Objetivo logrado';

const GoalRow = ({ goal }: { goal: AnalysisValueView }) => {
  const goalValue = getGoalValue(goal);
  if (!goalValue) return null;

  return (
    <TableRow key={goal.id}>
      <TableCell component="th" scope="row">
        <div className="flex flex-col gap-2 m-2 items-center md:items-start">
          <Chip
            variant="ghost"
            className="w-fit px-4 py-1.5"
            color={GOAL_COLOR}
            size="sm"
            value={GOAL_TITLE}
            icon={<CheckIcon className="h-4 w-4" />}
          />
          <div className="text-justify">{getGoalEvidence(goal)}</div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default GoalRow;
