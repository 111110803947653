import { useState } from 'react';
import { ApiQueryParams } from 'src/api/generated';

export const useSearch = () => {
  const [search, setSearch] = useState('');

  const queryParams: ApiQueryParams = {
    searchBy: search,
  };

  return {
    searchQueryParams: queryParams,
    sendSearch: setSearch,
  };
};
