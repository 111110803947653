import { Spinner } from '@material-tailwind/react';

interface LoadingProps {
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({ className = '' }) => {
  return (
    <div className={`flex w-full justify-center ${className}`}>
      <Spinner color="blue" />
    </div>
  );
};

export default Loading;
