import { Task } from 'src/api/generated';

export const isRecentTask = (_: number) => {
  // TODO: possibly implement feature flag with setting
  // if (!timeSinceLastCall) {
  //   return false;
  // }
  // const expireSuggestionInMins = 30;
  // const isRecent = timeSinceLastCall < 1000 * 60 * expireSuggestionInMins;
  // return isRecent;
  return true;
};

export const getOpportunityTaskSortingValue = (task: Task | null) => {
  const taskExists = !!task;
  if (taskExists && task.dueDate) {
    const dueDateTime = new Date(task.dueDate).getTime();
    return dueDateTime;
  } else if (taskExists) {
    return Number.POSITIVE_INFINITY - 1;
  }
  return Number.POSITIVE_INFINITY;
};
