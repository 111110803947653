import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, createFileRoute } from '@tanstack/react-router';
import LandingView from 'components/views/landing-view';

export const Route = createFileRoute('/')({
  component: LandingComponent,
});

// Example to add nested routes
// https://tanstack.com/router/latest/docs/framework/react/examples/basic-file-based
function LandingComponent() {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <Navigate to="/calls" search />;
  }

  return <LandingView />;
}
