import LoginButton from 'components/molecules/buttons/login-button';

function LandingView() {
  return (
    <>
      <header className="z-10 relative max-w-screen-lg xl:max-w-screen-xl mx-auto text-center">
        <h2 className="text-2xl sm:text-4xl leading-none font-bold tracking-tight text-blue-600">
          Bienvenido a <span className="text-gold opacity-75">LlamadaPro</span>
        </h2>
        <h1 className="text-4xl lg:text-5xl leading-none font-extrabold tracking-tight mb-8 sm:mb-10 text-blue-600">
          Tu aliado en las llamadas telefónicas
        </h1>
        <p className="max-w-screen-lg text-lg sm:text-xl  text-gray-500 font-medium mb-10 sm:mb-11 mx-auto">
          Olvídate de tomar notas. Resume automáticamente las conversaciones con
          tus prospectos.
        </p>

        <div className="flex justify-center">
          <LoginButton className="max-w-xs" />
        </div>
      </header>
    </>
  );
}

export default LandingView;
