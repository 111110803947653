// src/components/molecules/charts/agent-select/agent-select.tsx

import Select from 'components/atoms/select';
import { AgentInfo } from 'src/types/call-summaries';
import { SelectData } from 'tw-elements-react/dist/types/forms/Select/types';
import { AGENT_COLORS, generateSolidColorIcon } from 'src/utils/charts';

interface AgentSelectProps {
  agents: AgentInfo[];
  selectedAgents: number[];
  onAgentSelectionChange: (selectedAgents: number[]) => void;
  disabled?: boolean;
}

const AgentSelect: React.FC<AgentSelectProps> = ({
  agents,
  selectedAgents,
  onAgentSelectionChange,
  disabled,
}) => {
  const generateData = () => {
    return agents.map((agent) => ({
      text: agent.name,
      value: agent.id,
      icon: generateSolidColorIcon(
        AGENT_COLORS[agent.id % AGENT_COLORS.length],
        '16'
      ),
    }));
  };

  const handleAgentSelectionChange = (
    selectedData: SelectData | SelectData[]
  ) => {
    const selectedValues = Array.isArray(selectedData)
      ? selectedData.map((data) => data.value as number)
      : [selectedData.value as number];

    onAgentSelectionChange(selectedValues);
  };

  return (
    <Select
      data={generateData()}
      multiple
      search
      // TODO: check when implementing i18n
      searchLabel="Buscar..."
      label="Filtrar por agentes"
      value={selectedAgents}
      onValueChange={handleAgentSelectionChange}
      selectAll
      // TODO: check when implementing i18n
      selectAllLabel="Seleccionar todos"
      noResultsText="No hay resultados"
      optionsSelectedLabel="agentes seleccionados"
      containerClassName="max-w-[16rem] py-2"
      disabled={disabled}
    />
  );
};

export default AgentSelect;
