import React, { forwardRef } from 'react';
import Label from '../label';
import TextInput from '../text-input';
import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';

interface LabelTextInputProps {
  label: string;
  variant?: ComponentVariant;
  size?: ComponentSize;
  className?: string;
  labelProps?: React.ComponentProps<typeof Label>;
  inputProps?: React.ComponentProps<typeof TextInput>;
  labelPosition?: 'side' | 'top';
}

const LabelTextInput = forwardRef<HTMLInputElement, LabelTextInputProps>(
  (
    {
      label,
      variant = 'primary',
      size = 'md',
      className = '',
      labelProps = {},
      inputProps = {},
      labelPosition = 'side',
    },
    ref
  ) => {
    const containerClasses = {
      side: 'flex-col sm:flex-row sm:items-center justify-between',
      top: 'flex-col relative',
    };

    const labelClasses = {
      top: [
        'absolute -top-[0.6rem] left-3 z-10',
        'text-xs text-gray-600 font-medium leading-none',
        'px-0.5',
        'bg-gradient-to-b from-white via-white to-transparent',
        'pb-1',
      ].join(' '),
      side: '',
    };

    const topLabelSize = ['xs', 'sm'].includes(size) ? '2xs' : 'xs';
    const labelSize = labelPosition === 'top' ? topLabelSize : size;

    return (
      <div
        className={`flex ${containerClasses[labelPosition]} ${
          labelPosition === 'top' ? '' : 'gap-2'
        } ${className}`}
      >
        <div className={labelPosition === 'side' ? 'sm:w-1/3' : ''}>
          <Label
            variant={variant}
            size={labelSize}
            className={labelClasses[labelPosition]}
            {...labelProps}
          >
            {label}
          </Label>
        </div>
        <div className="flex-1">
          <TextInput ref={ref} variant={variant} size={size} {...inputProps} />
        </div>
      </div>
    );
  }
);

LabelTextInput.displayName = 'LabelTextInput';

export default LabelTextInput;
