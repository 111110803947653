import React, { forwardRef } from 'react';
import InputError from '../input-error';
import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';

interface TextAreaProps {
  variant?: ComponentVariant;
  size?: ComponentSize;
  className?: string;
  error?: string;
  refInputProps?: React.ComponentProps<'textarea'>;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      variant = 'primary',
      size = 'md',
      className = '',
      error,
      refInputProps = {},
    },
    ref
  ) => {
    const { disabled = false } = refInputProps;

    const baseClasses = [
      'flex-grow',
      'border',
      'rounded-md',
      'focus:outline-none',
      'focus:ring-1',
      'resize-none',
      disabled ? 'bg-gray-100 cursor-not-allowed opacity-75' : '',
    ];

    const effectiveVariant = error ? 'danger' : variant;

    const variantClasses: Record<ComponentVariant, string> = {
      primary: disabled ? '' : 'focus:ring-blue-500 border-gray-300',
      secondary: disabled ? '' : 'focus:ring-gray-500 border-gray-400',
      danger: disabled ? '' : 'focus:ring-red-500 border-red-300',
      warning: disabled ? '' : 'focus:ring-yellow-500 border-yellow-300',
      plain: disabled ? '' : 'focus:ring-gray-500 border-gray-300',
    };

    const sizeClasses: Record<ComponentSize, string> = {
      lg: 'h-28 p-3 text-md leading-tight',
      md: 'h-20 p-2.5 text-sm leading-tight',
      sm: 'h-10 p-1 text-sm leading-tight',
      xs: 'h-6 p-1 text-xs leading-tight',
      '2xs': 'h-5 p-1 text-xs leading-tight',
    };

    const classes = [
      ...baseClasses,
      variantClasses[effectiveVariant],
      sizeClasses[size],
      className,
    ].join(' ');

    return (
      <div className="flex flex-1 flex-col w-full">
        <textarea ref={ref} className={classes} {...refInputProps} />
        <InputError message={error} />
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
