import { createFileRoute } from '@tanstack/react-router';
import AuthenticateRoute from 'components/organisms/app-layouts/authenticate-route';
import ReportsView from 'components/views/reports-view';

export const Route = createFileRoute('/reports/')({
  component: ReportsIndexComponent,
});

// Example to add nested routes
// https://tanstack.com/router/latest/docs/framework/react/examples/basic-file-based
function ReportsIndexComponent() {
  return (
    <AuthenticateRoute>
      <ReportsView />
    </AuthenticateRoute>
  );
}
