/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type representing the header component type in a WhatsApp template
 */
export enum WhatsAppTemplateHeaderComponentType {
    HEADER = 'header',
}
