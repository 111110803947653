import { MessageDirection } from 'src/api/generated';

interface GetMessageDirectionOptionsParams {
  includeAllOption?: boolean;
}

// TODO: check when implementing i18n
export const messageDirectionTranslations: Record<MessageDirection, string> = {
  [MessageDirection.INBOUND]: 'Entrante',
  [MessageDirection.OUTBOUND]: 'Saliente',
};

export const getMessageDirectionOptions = (
  params?: GetMessageDirectionOptionsParams
) => {
  const includeAllOption = params?.includeAllOption ?? false;

  const directionOptions = Object.values(MessageDirection).map((direction) => ({
    content: messageDirectionTranslations[direction],
    value: direction,
  }));

  if (includeAllOption) {
    // TODO: check when implementing i18n
    return [{ content: 'Todas', value: '' }, ...directionOptions];
  }

  return directionOptions;
};
