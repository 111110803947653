import { usePhoneContext } from 'src/contexts/phone-context';
import {
  CallCallbacks,
  CallProvider,
  PhoneCallActions,
  PhoneConfigActions,
  PhonePadActions,
  StartCallParams,
} from '../../types';
import { useStartBridgeCall } from 'hooks/api/calls/start-calls';
import { Call, NewBridgeCall } from 'src/api/generated';
import STATES from 'states/index';
import { useEffect, useState } from 'react';
import { useAlert } from 'src/contexts/alert-context';
import { resolvePhoneNumber, updatePreparedCallData } from '../call.provider';
import { mapBridgeCallToCallData } from './mappings';

export interface PhoneCallActionsProps {
  startBridgeCall: (newCallBridgeCall: NewBridgeCall) => void;
  standardizedPhoneNumber: string | null;
  setPhoneNumber: (number: string) => void;
  setPhoneState: (number: string) => void;
}

export interface PhonePadActionsProps {
  formattedPhoneNumber: string | null;
  setPhoneNumber: (number: string) => void;
}

const usePhoneCallActions = ({
  startBridgeCall,
  standardizedPhoneNumber,
  setPhoneNumber,
}: PhoneCallActionsProps): PhoneCallActions => {
  const [preparedCallData, setPreparedCallData] = useState<StartCallParams>({});

  const startCall = async ({
    phoneNumber,
    callProviderData,
    callCustomData,
  }: StartCallParams = {}) => {
    const preparedNumberWasManuallyChanged = preparedCallData.phoneNumber
      ? standardizedPhoneNumber !== preparedCallData.phoneNumber
      : false;

    const resolvedPhoneNumber = resolvePhoneNumber(
      setPhoneNumber,
      standardizedPhoneNumber,
      phoneNumber
    );
    if (!resolvedPhoneNumber) {
      console.info(`Invalid phone number: ${resolvedPhoneNumber}`);
      return;
    }

    const resolvedPreparedData = preparedNumberWasManuallyChanged
      ? undefined
      : preparedCallData;

    const providerData =
      callProviderData || resolvedPreparedData?.callProviderData;
    const customData = callCustomData || resolvedPreparedData?.callCustomData;

    startBridgeCall({
      to: resolvedPhoneNumber,
      callProviderData: providerData,
      callCustomData: customData,
    });

    const callDataUsed: StartCallParams = {
      phoneNumber: resolvedPhoneNumber,
      callProviderData: providerData,
      callCustomData: customData,
    };
    updatePreparedCallData(
      setPreparedCallData,
      callDataUsed,
      preparedNumberWasManuallyChanged
    );
  };

  const prepareCall = (params: StartCallParams = {}) => {
    const { phoneNumber, callProviderData, callCustomData } = params;

    const clearData = phoneNumber === undefined;
    if (clearData) {
      setPhoneNumber('');
      setPreparedCallData({});
      return;
    }

    setPhoneNumber(phoneNumber);
    setPreparedCallData({
      phoneNumber,
      callProviderData,
      callCustomData,
    });
  };

  const getPreparedCallData = (): StartCallParams | null => {
    const { phoneNumber, callProviderData, callCustomData } = preparedCallData;

    const hasData = !!phoneNumber || !!callProviderData || !!callCustomData;
    if (hasData) return preparedCallData;

    return null;
  };

  // Unsupported actions for this provider
  const acceptCall = () => {};
  const rejectCall = () => {};
  const endCall = () => {};
  const muteCall = (_isMuted: boolean) => {};

  return {
    startCall,
    prepareCall,
    getPreparedCallData,
    acceptCall,
    rejectCall,
    endCall,
    muteCall,
  };
};

const usePhonePadActions = ({
  formattedPhoneNumber,
  setPhoneNumber,
}: PhonePadActionsProps): PhonePadActions => {
  const onPadDigitClick = (digit: string) => {
    setPhoneNumber((formattedPhoneNumber || '') + digit);
  };

  const onPadDeleteClick = () => {
    if (!formattedPhoneNumber) return;

    setPhoneNumber(formattedPhoneNumber.slice(0, -1));
  };

  // Unsupported action for this provider
  const onCallPadDigitClick = (_digit: string) => {};

  return {
    onPadDigitClick,
    onPadDeleteClick,
    onCallPadDigitClick,
  };
};

export const useBridgeCallProvider = (
  callCallbacks?: CallCallbacks
): CallProvider => {
  const { showAlert, showModalAlert } = useAlert();

  const { onCallStarted } = callCallbacks || {};

  const onSuccessCallStarted = (call: Call) => {
    const callData = mapBridgeCallToCallData(call);
    onCallStarted && onCallStarted(callData);

    const message = `
    Acepta la llamada en tu teléfono para llamar automáticamente al contacto.
    Llamando al número ${formattedPhoneNumber}.
    `;
    const title = 'Ahora recibirás una llamada';
    const durationInMs = 10000;
    showModalAlert(message, title, undefined, durationInMs);
  };

  const isMissingIndividualPhoneNumberError = (error: Error | null) => {
    if (!error) return false;
    return error.name === 'ApiError' && error.stack?.includes('status: 422');
  };
  const onFailCallStarted = (error?: Error | null) => {
    if (!error) return;
    if (isMissingIndividualPhoneNumberError(error)) {
      showAlert(
        `No se puede completar la llamada por teléfono. Por favor, configure ` +
          `su teléfono personal para redirigir la llamada y vuelva a intentarlo.`,
        'error'
      );
    }
  };

  const {
    standardizedPhoneNumber,
    formattedPhoneNumber,
    setPhoneState,
    setPhoneNumber,
  } = usePhoneContext();
  const { loading, error, startBridgeCall } = useStartBridgeCall(
    onSuccessCallStarted,
    onFailCallStarted
  );

  const handledError = isMissingIndividualPhoneNumberError(error)
    ? null
    : error;

  // Using external phone for bridge provider
  const phoneConfigActions: PhoneConfigActions = {
    // TODO: check when implementing i18n
    selectedInputDevice: 'Teléfono',
    setSelectedInputDevice: (_deviceId: string) => {},
    selectedOutputDevice: 'Teléfono',
    setSelectedOutputDevice: (_deviceId: string) => {},
    selectedDeviceBitrate: 0,
    setDeviceBitrate: (_bitrate: number) => {},
  };

  const callActions = usePhoneCallActions({
    startBridgeCall,
    standardizedPhoneNumber,
    setPhoneNumber,
    setPhoneState,
  });
  const padActions = usePhonePadActions({
    formattedPhoneNumber,
    setPhoneNumber,
  });

  useEffect(() => {
    setPhoneState(STATES.PHONE.READY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    callActions,
    padActions,
    phoneConfigActions,
    loading,
    error: handledError,
    warnings: [],
    name: 'bridge',
    callerNumber: undefined,
    onCallNumber: formattedPhoneNumber,
  };
};
