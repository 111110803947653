// src/components/atoms/badge-tooltip-button/badge-tooltip-button.tsx

import { Badge, Button, Tooltip } from '@material-tailwind/react';
import { color } from '@material-tailwind/react/types/components/badge';

interface BadgeTooltipButtonProps {
  buttonText: string;
  badgeText: string | null;
  badgeColor?: color;
  tooltipContent: React.ReactNode | null;
  onClick: () => void;
}

const BadgeTooltipButton: React.FC<BadgeTooltipButtonProps> = ({
  buttonText,
  badgeText = null,
  badgeColor = 'red',
  tooltipContent = null,
  onClick,
}) => {
  if (!tooltipContent) {
    return (
      <Button
        variant="outlined"
        size="sm"
        onClick={onClick}
        className="py-0 whitespace-nowrap border-blue-gray-200 text-normal"
      >
        {buttonText}
      </Button>
    );
  }
  return (
    <Tooltip
      content={<div className="w-80">{tooltipContent}</div>}
      placement="left"
    >
      <Badge
        content={badgeText}
        overlap="square"
        placement="top-end"
        color={badgeColor}
        withBorder
      >
        <Button
          variant="outlined"
          size="sm"
          onClick={onClick}
          className="py-0 whitespace-nowrap border-blue-gray-200 text-normal"
        >
          {buttonText}
        </Button>
      </Badge>
    </Tooltip>
  );
};

export default BadgeTooltipButton;
