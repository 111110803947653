import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@material-tailwind/react';

interface ChevronButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const ChevronButton: React.FC<ChevronButtonProps> = ({
  onClick,
  children,
  className = '',
  disabled = false,
}) => {
  const classes = `
    text-md rounded h-4 w-4 p-4 bg-white border border-blue-gray-100
    hover:border-blue-gray-600 text-gray-500 hover:text-gray-600 shadow-none
    hover:shadow-none ${className}
  `;

  return (
    <IconButton
      onClick={onClick}
      className={classes}
      ripple={true}
      disabled={disabled}
    >
      {children ? (
        children
      ) : (
        <ChevronLeftIcon strokeWidth="2" className="h-4 w-4" />
      )}
    </IconButton>
  );
};

export default ChevronButton;
