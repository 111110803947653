// src/hooks/cache.ts

import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export const useInvalidateCache = () => {
  const queryClient = useQueryClient();

  const invalidateCalls = useCallback(
    () => queryClient.invalidateQueries({ queryKey: ['calls'] }),
    [queryClient]
  );
  const invalidateContactsWithCalls = useCallback(
    () => queryClient.invalidateQueries({ queryKey: ['contacts-with-calls'] }),
    [queryClient]
  );
  const invalidateOpportunities = useCallback(
    () => queryClient.invalidateQueries({ queryKey: ['opportunities'] }),
    [queryClient]
  );
  return {
    invalidateCalls,
    invalidateContactsWithCalls,
    invalidateOpportunities,
  };
};
