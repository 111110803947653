import React from 'react';
import { Conversation, Participant } from 'src/api/generated';
import CopyModal from 'src/modules/shared/components/molecules/modals/copy-modal/index';
import MessageLoading from 'components/atoms/loading/message-loading';
import InfoError from 'components/atoms/info-error';
import ConversationDetailsModalInfo from './conversation-details-modal-info';
import ConversationDetailsModalParticipantList from './conversation-details-modal-participant-list';

interface ConversationDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  conversation: Conversation;
  participants: Participant[];
  loading: boolean;
  error: Error | null;
}

const ConversationDetailsModal: React.FC<ConversationDetailsModalProps> = ({
  isOpen,
  onClose,
  conversation,
  participants,
  loading,
  error,
}) => {
  const renderContent = () => {
    if (loading) return <MessageLoading />;
    if (error) return <InfoError error={error} />;
    return (
      <div className="flex flex-col w-full space-y-6 pb-4">
        <ConversationDetailsModalInfo conversation={conversation} />
        <ConversationDetailsModalParticipantList
          participants={participants}
          mainParticipantAddress={conversation.mainParticipant.address}
        />
      </div>
    );
  };

  return (
    <CopyModal
      isOpen={isOpen}
      onClose={onClose}
      title="Detalles de la Conversación"
    >
      {renderContent()}
    </CopyModal>
  );
};

export default ConversationDetailsModal;
