import { Chip, Tooltip } from '@material-tailwind/react';
import { colors } from '@material-tailwind/react/types/generic';

interface ClickableTooltipChipProps {
  chipContent: string;
  chipColor: colors;
  chipClasses: string;
  tooltipContent?: string;
  href?: string;
}

const ClickableTooltipChip: React.FC<ClickableTooltipChipProps> = ({
  chipContent,
  chipColor,
  chipClasses,
  tooltipContent,
  href,
}) => {
  const chipIcon = (
    <span
      className={`mx-auto mt-1 block h-2 w-2 rounded-full ${chipClasses}`}
    />
  );

  const chip = (
    <Chip
      variant="ghost"
      color={chipColor}
      size="sm"
      value={chipContent}
      className="ml-2 h-7"
      icon={chipIcon}
    />
  );

  const chipWithTooltip = tooltipContent ? (
    <Tooltip
      content={<div className="w-96">{tooltipContent}</div>}
      placement="top"
    >
      {chip}
    </Tooltip>
  ) : (
    chip
  );

  if (href) {
    return (
      <a
        href={href}
        className="hover:underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        {chipWithTooltip}
      </a>
    );
  }

  return chipWithTooltip;
};

export default ClickableTooltipChip;
