import { standardizePhoneNumber } from 'src/utils/formatting/phone';
import { StartCallParams } from '../types';

export const resolvePhoneNumber = (
  setPhoneNumber: (number: string) => void,
  currentNumber: string | null,
  priorityNumber?: string
): string | null => {
  const priorityNumberUnset = priorityNumber === undefined;
  if (priorityNumberUnset) return currentNumber;

  setPhoneNumber(priorityNumber);
  return standardizePhoneNumber(priorityNumber);
};

export const updatePreparedCallData = (
  setPreparedCallData: (params: StartCallParams) => void,
  callDataUsed: StartCallParams,
  shouldClearData: boolean
) => {
  if (shouldClearData) {
    setPreparedCallData({});
    return;
  }

  setPreparedCallData(callDataUsed);
};
