import DialerButton from '../dialer-button';

interface DeleteButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  onClick,
  disabled = false,
}) => {
  const className = `text-white bg-red-400 hover:bg-red-700 border-red-700`;

  return (
    <DialerButton onClick={onClick} className={className} disabled={disabled}>
      ←
    </DialerButton>
  );
};

export default DeleteButton;
