import { AnalysisValueView, ScoreConfigKey } from 'src/api/generated';
import { getLatestAnalysisValue } from '../analysisValues';
import { getScores } from './scores';

const QUALIFICATION_LABELS = {
  MISSING: 'Por Calificar',
  LOW: 'No Calificado',
  MID: 'Parcialmente Calificado',
  HIGH: 'Calificado',
};

export const getLatestContactQualiScore = (
  analysisValues: AnalysisValueView[]
): AnalysisValueView | null => {
  const contactQualiScores = getContactQualiScores(analysisValues);
  const latestContactQualiScore = getLatestAnalysisValue(contactQualiScores);
  return latestContactQualiScore;
};

export const isMissingContactQuali = (value?: number | null) =>
  value === null || value === -1;

export const getContactQualiLabel = (contactQualiValue: number | null) => {
  // TODO: use maxValue to hardcode percentages instead of fixed values?
  // const maxValue = getScoreMaxValue(analysisValue);
  if (isMissingContactQuali(contactQualiValue)) {
    return QUALIFICATION_LABELS.MISSING;
  }
  if (contactQualiValue! < 3) return QUALIFICATION_LABELS.LOW;
  if (contactQualiValue! < 8) return QUALIFICATION_LABELS.MID;
  return QUALIFICATION_LABELS.HIGH;
};

export const getContactQualiLabelColor = (contactQualiValue: number | null) => {
  if (isMissingContactQuali(contactQualiValue)) return 'gray';
  if (contactQualiValue! < 8) return 'light-green';
  return 'green';
};

const getContactQualiScores = (
  analysisValues: AnalysisValueView[]
): AnalysisValueView[] => {
  return getScores(analysisValues).filter(
    (value) => value.configKey === ScoreConfigKey.CONTACT_QUALIFICATION_SCORE
  );
};
