import {
  WhatsAppTemplate,
  WhatsAppTemplateBodyComponent,
  WhatsAppTemplateComponent,
  WhatsAppTemplateFooterComponent,
  WhatsAppTemplateHeaderComponent,
  WhatsAppTemplateMessageBodyComponent,
  WhatsAppTemplateMessageComponent,
  WhatsAppTemplateMessageHeaderComponent,
  WhatsAppTemplateMessageTextParameter,
  WhatsAppTemplateMessageTextParameterType,
  WhatsAppTemplateBodyComponentType,
  WhatsAppTemplateHeaderComponentType,
} from 'src/api/generated';

// TODO: check when implementing i18n
export const translateTemplateValues = (key: string, value: string): string => {
  const translations: Record<string, Record<string, string>> = {
    language: {
      en_US: 'Inglés (EE.UU.)',
      es_ES: 'Español (España)',
      fr_FR: 'Francés (Francia)',
      de_DE: 'Alemán (Alemania)',
      // Add more language translations as needed
    },
    status: {
      APPROVED: 'Aprobado',
      IN_APPEAL: 'En apelación',
      PENDING: 'Pendiente',
      REJECTED: 'Rechazado',
      PENDING_DELETION: 'Pendiente de eliminación',
      DELETED: 'Eliminado',
      DISABLED: 'Deshabilitado',
      PAUSED: 'Pausado',
      LIMIT_EXCEEDED: 'Límite excedido',
      ARCHIVED: 'Archivado',
    },
    category: {
      ACCOUNT_UPDATE: 'Actualización de cuenta',
      PAYMENT_UPDATE: 'Actualización de pago',
      PERSONAL_FINANCE_UPDATE: 'Actualización de finanzas personales',
      SHIPPING_UPDATE: 'Actualización de envío',
      RESERVATION_UPDATE: 'Actualización de reserva',
      ISSUE_RESOLUTION: 'Resolución de problemas',
      APPOINTMENT_UPDATE: 'Actualización de cita',
      TRANSPORTATION_UPDATE: 'Actualización de transporte',
      TICKET_UPDATE: 'Actualización de ticket',
      ALERT_UPDATE: 'Actualización de alerta',
      AUTO_REPLY: 'Respuesta automática',
      TRANSACTIONAL: 'Transaccional',
      OTP: 'Contraseña de un solo uso',
      UTILITY: 'Utilidad',
      MARKETING: 'Marketing',
      AUTHENTICATION: 'Autenticación',
    },
    component: {
      header: 'Encabezado',
      body: 'Cuerpo',
      footer: 'Pie de página',
    },
  };

  return translations[key]?.[value] || value;
};

export const PLACEHOLDER_PREFIX = '{{';
export const PLACEHOLDER_SUFFIX = '}}';

/**
 * Extracts numbered placeholders from a text
 * @param text - The text containing placeholders like {{1}}, {{2}}, etc.
 * @returns An array of unique placeholders found in the text
 */
export const extractPlaceholders = (text: string): string[] => {
  const regex = new RegExp(
    `${PLACEHOLDER_PREFIX}\\d+${PLACEHOLDER_SUFFIX}`,
    'g'
  );
  const matches = text.match(regex) || [];
  const uniquePlaceholders = Array.from(new Set(matches));
  return uniquePlaceholders;
};

/**
 * Builds the WhatsApp template message components from the template
 * @param template - The WhatsApp template
 * @returns The WhatsApp template message components
 */
export const buildMessageComponents = (
  template: WhatsAppTemplate | undefined
): WhatsAppTemplateMessageComponent[] => {
  if (!template) return [];

  const messageComponents: (WhatsAppTemplateMessageComponent | undefined)[] =
    template.components.map((component: WhatsAppTemplateComponent) => {
      const componentType = component.type.toLowerCase();
      if (
        componentType === WhatsAppTemplateHeaderComponentType.HEADER ||
        componentType === WhatsAppTemplateBodyComponentType.BODY
      ) {
        // Handle components with text content (header, body)
        const text =
          'text' in component && component.text ? component.text : '';
        const placeholders = extractPlaceholders(text);
        if (placeholders.length === 0) return;

        const emptyParameters: WhatsAppTemplateMessageTextParameter[] =
          placeholders.map(() => ({
            type: WhatsAppTemplateMessageTextParameterType.TEXT,
            text: '',
          }));

        const messageComponent:
          | WhatsAppTemplateMessageHeaderComponent
          | WhatsAppTemplateMessageBodyComponent = {
          type: componentType,
          parameters: emptyParameters,
        };

        return messageComponent;
      }

      // Handle components without text (like buttons)
      // Not implemented yet
      return;
    });

  const filteredMessageComponents = messageComponents.filter(
    (component): component is WhatsAppTemplateMessageComponent =>
      component !== undefined
  );
  return filteredMessageComponents;
};

/**
 * Note: duplicated in shared library
 * TODO: check how to install shared library in frontend
 */

export const ESCAPED_PLACEHOLDER_PREFIX = '\\{\\{';
export const ESCAPED_PLACEHOLDER_SUFFIX = '\\}\\}';

/**
 * Replaces numbered placeholders in a text with corresponding values
 * @param text - The template text containing placeholders like {{1}}, {{2}}, etc.
 * @param values - Array of values to replace the placeholders with
 * @param replacer - Optional string to wrap around replaced values
 * @returns The text with placeholders replaced by values
 */
export const replacePlaceholders = (
  text: string,
  values: string[],
  replacer: string = ''
): string => {
  return values.reduce((text, value, index) => {
    if (!value) return text;

    const regex = new RegExp(
      `${ESCAPED_PLACEHOLDER_PREFIX}${index + 1}${ESCAPED_PLACEHOLDER_SUFFIX}`,
      'g'
    );
    return text.replace(regex, `${replacer}${value}${replacer}`);
  }, text);
};

/**
 * Builds the text content for a template component by replacing placeholders with parameter values
 * @param templateComponent - The template component containing the text template
 * @param messageComponent - Optional message component containing parameter values
 * @param replacer - Optional string to wrap around replaced values
 * @returns The component text with parameters replaced
 */
export const buildComponentText = (
  templateComponent:
    | WhatsAppTemplateHeaderComponent
    | WhatsAppTemplateBodyComponent
    | WhatsAppTemplateFooterComponent,
  messageComponent?:
    | WhatsAppTemplateMessageHeaderComponent
    | WhatsAppTemplateMessageBodyComponent,
  replacer: string = ''
): string => {
  const componentText = templateComponent.text || '';

  const componentParameters = messageComponent?.parameters || [];
  const textParameters = componentParameters.filter(
    (param): param is WhatsAppTemplateMessageTextParameter =>
      param.type.toLowerCase() === 'text'
  );
  const parameterValues = textParameters.map((value) => value.text);

  const replacedText = replacePlaceholders(
    componentText,
    parameterValues,
    replacer
  );
  return replacedText;
};

/**
 * Builds the header text for a WhatsApp template message
 * @param template - The WhatsApp template
 * @param messageComponents - Array of message components containing parameter values
 * @param replacer - Optional string to wrap around replaced values
 * @returns The header text with parameters replaced
 */
export const buildHeaderText = (
  template: WhatsAppTemplate,
  messageComponents: WhatsAppTemplateMessageComponent[],
  replacer: string = ''
): string => {
  const templateHeaderComponent = template.components.find(
    (comp) => comp.type.toLowerCase() === 'header'
  ) as WhatsAppTemplateHeaderComponent | undefined;
  if (!templateHeaderComponent) return '';

  const messageHeaderComponent = messageComponents.find(
    (comp) => comp.type.toLowerCase() === 'header'
  ) as WhatsAppTemplateMessageHeaderComponent | undefined;

  return buildComponentText(
    templateHeaderComponent,
    messageHeaderComponent,
    replacer
  );
};

/**
 * Builds the body text for a WhatsApp template message
 * @param template - The WhatsApp template
 * @param messageComponents - Array of message components containing parameter values
 * @param replacer - Optional string to wrap around replaced values
 * @returns The body text with parameters replaced
 */
export const buildBodyText = (
  template: WhatsAppTemplate,
  messageComponents: WhatsAppTemplateMessageComponent[],
  replacer: string = ''
): string => {
  const templateBodyComponent = template.components.find(
    (comp) => comp.type.toLowerCase() === 'body'
  ) as WhatsAppTemplateBodyComponent | undefined;
  if (!templateBodyComponent) return '';

  const messageBodyComponent = messageComponents.find(
    (comp) => comp.type.toLowerCase() === 'body'
  ) as WhatsAppTemplateMessageBodyComponent | undefined;

  return buildComponentText(
    templateBodyComponent,
    messageBodyComponent,
    replacer
  );
};

/**
 * Builds the footer text for a WhatsApp template message
 * @param template - The WhatsApp template
 * @returns The footer text
 */
export const buildFooterText = (template: WhatsAppTemplate): string => {
  const templateFooterComponent = template.components.find(
    (comp) => comp.type.toLowerCase() === 'footer'
  ) as WhatsAppTemplateFooterComponent | undefined;
  if (!templateFooterComponent) return '';

  return templateFooterComponent.text || '';
};

/**
 * Builds a complete template message using component-based parameters
 * @param template - The WhatsApp template
 * @param messageComponents - Array of message components containing parameter values
 * @param replacer - Optional string to wrap around replaced values
 * @returns The complete message with all components assembled and parameters replaced
 */
export const buildTemplateMessageFromComponents = (
  template: WhatsAppTemplate,
  messageComponents: WhatsAppTemplateMessageComponent[] = [],
  replacer: string = ''
): string => {
  const headerText = buildHeaderText(template, messageComponents, replacer);
  const bodyText = buildBodyText(template, messageComponents, replacer);
  const footerText = buildFooterText(template);

  return [headerText, bodyText, footerText].filter(Boolean).join('\n');
};
