/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TemplateCreateBodyParams } from '../models/TemplateCreateBodyParams';
import type { TemplateCreateResponse } from '../models/TemplateCreateResponse';
import type { TemplateDeleteResponse } from '../models/TemplateDeleteResponse';
import type { TemplateGetResponse } from '../models/TemplateGetResponse';
import type { TemplateListResponse } from '../models/TemplateListResponse';
import type { TemplateUpdateBodyParams } from '../models/TemplateUpdateBodyParams';
import type { TemplateUpdateResponse } from '../models/TemplateUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MessagingTemplatesService {

    /**
     * @param filterBy Filter criteria in the format "field:value;field2<value2;field3:[value3,value4,value5]" for exact matches.
     * @param searchBy Search criteria in the format "name lastname" for partial matches.
     * @param sortBy Sorting criteria in the format "field:asc;field2:desc".
     * @param page The page number in pagination.
     * @param pageSize The number of items per page in pagination.
     * @returns TemplateListResponse Ok
     * @throws ApiError
     */
    public static getTemplates(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<TemplateListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/messaging/templates',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns TemplateCreateResponse Ok
     * @throws ApiError
     */
    public static createTemplate(
        requestBody: TemplateCreateBodyParams,
    ): CancelablePromise<TemplateCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/messaging/templates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id
     * @returns TemplateGetResponse Ok
     * @throws ApiError
     */
    public static getTemplate(
        id: number,
    ): CancelablePromise<TemplateGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/messaging/templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns TemplateUpdateResponse Ok
     * @throws ApiError
     */
    public static updateTemplate(
        id: number,
        requestBody: TemplateUpdateBodyParams,
    ): CancelablePromise<TemplateUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/messaging/templates/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @returns TemplateDeleteResponse Ok
     * @throws ApiError
     */
    public static deleteTemplate(
        id: number,
    ): CancelablePromise<TemplateDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/messaging/templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

}
