/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ScoreConfigKey {
    CONTACT_QUALIFICATION_SCORE = 'contact.qualificationScore',
    AGENT_EXPERTISE = 'agent.expertise',
    AGENT_CONFIDENCE_GENERATED = 'agent.confidenceGenerated',
    AGENT_OBJECTION_HANDLING = 'agent.objectionHandling',
    AGENT_CALL_CLOSING = 'agent.callClosing',
    ALERT = 'alert',
}
