import { useState, useEffect } from 'react';
import { Typography, IconButton } from '@material-tailwind/react';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import Icons from 'components/atoms/icons';
import { Squares2X2Icon } from '@heroicons/react/24/outline';

interface OnCallProps {
  onHangUp: () => void;
  onMute?: (muteState: boolean) => void;
  isKeypadOpen?: boolean;
  onKeypad?: (keypadState: boolean) => void;
  phoneNumber: string;
}

function formatCallDuration(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
}

const OnCall: React.FC<OnCallProps> = ({
  onHangUp,
  onMute,
  isKeypadOpen = false,
  onKeypad,
  phoneNumber,
}) => {
  const [callDuration, setCallDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCallDuration((prev) => prev + 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleMuteToggle = () => {
    if (!onMute) return;
    const newMutedState = !isMuted;
    setIsMuted(newMutedState);
    onMute(newMutedState);
  };

  const handleKeypadToggle = () => {
    if (!onKeypad) return;
    onKeypad(!isKeypadOpen);
  };

  return (
    <div className="flex flex-col flex-1 justify-center items-center overflow-auto px-4 text-blue-600">
      <div className="text-center mb-2">
        <Typography variant="small" className="block">
          {formatCallDuration(callDuration)}
        </Typography>
        <Typography variant="h5">{phoneNumber || '-'}</Typography>
        <Typography
          variant="small"
          className={`${
            isMuted ? 'bg-blue-500 text-white' : 'invisible'
          } rounded px-1 text-xs mt-1 h-5 flex items-center justify-center`}
        >
          {/* TODO: check when implementing i18n */}
          Micrófono silenciado
        </Typography>
      </div>
      <div className="flex items-center w-full space-x-2">
        <div className="flex space-x-2">
          {onMute && (
            <IconButton
              onClick={handleMuteToggle}
              className={`${isMuted ? 'bg-blue-500' : 'bg-gray-500'}`}
            >
              {isMuted ? <Icons.MicWhite /> : <Icons.MicOff />}
            </IconButton>
          )}
          {onKeypad && (
            <IconButton
              onClick={handleKeypadToggle}
              className={`${isKeypadOpen ? 'bg-blue-500' : 'bg-gray-500'}`}
            >
              <Squares2X2Icon className="w-6 h-6" />
            </IconButton>
          )}
        </div>
        <Button onClick={onHangUp} size="sm">
          <Icons.HangUp />
          <Typography variant="small" className="ml-2">
            {/* TODO: check when implementing i18n */}
            Finalizar
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default OnCall;
