import { CallData } from 'hooks/phones/types';
import { Call } from 'src/api/generated';

export const mapBridgeCallToCallData = (call: Call): CallData => {
  return {
    providerId: call.providerId,
    providerName: call.providerName || 'bridge',
    // TODO: implement agent for bridge calls
    // agent: call.agentEmail,
    to: call.to,
    direction: call.direction,
    // TODO: implement callProviderData for bridge calls
    callProviderData: {},
    callCustomData: call.customData || {},
  };
};
