import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

interface MediaSkeletonProps {
  className?: string;
  isLoading: boolean;
  hasError?: boolean;
}

const MediaSkeleton: React.FC<MediaSkeletonProps> = ({
  className,
  isLoading,
  hasError,
}) => {
  const skeletonClasses = `
    w-full h-full min-w-[27rem] min-h-[27rem]
    bg-gray-300 rounded-lg relative
    ${className} ${hasError ? '' : 'animate-pulse'}
  `;
  const errorIconClasses = `
    absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
    h-8 w-8 text-gray-500
  `;

  if (!isLoading && !hasError) return null;

  return (
    <div className={skeletonClasses}>
      {hasError && (
        <ExclamationCircleIcon strokeWidth={2} className={errorIconClasses} />
      )}
    </div>
  );
};

export default MediaSkeleton;
