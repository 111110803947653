// src/components/molecules/analysis/text-modal-content/text-modal-content.tsx

import { Typography } from '@material-tailwind/react';

interface TextModalContentProps {
  content: string;
}

const TextModalContent: React.FC<TextModalContentProps> = ({ content }) => {
  return (
    <Typography className="font-normal whitespace-pre-line m-2 md:my-4">
      {content}
    </Typography>
  );
};

export default TextModalContent;
