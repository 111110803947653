/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WhatsAppTemplateListResponse } from '../models/WhatsAppTemplateListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MessagingWhatsappTemplatesService {

    /**
     * @param integrationProviderId
     * @param tenantId
     * @returns WhatsAppTemplateListResponse Ok
     * @throws ApiError
     */
    public static getTemplates(
        integrationProviderId: string,
        tenantId?: string,
    ): CancelablePromise<WhatsAppTemplateListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/messaging/whatsapp/templates',
            query: {
                'integrationProviderId': integrationProviderId,
                'tenantId': tenantId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden.`,
            },
        });
    }

}
