// src/modules/messaging/hooks/api/participants.ts

import { useQuery } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from 'hooks/api/api';
import {
  Alias_ParticipantListQueryParams as ParticipantsListQueryParams,
  Participant,
  ParticipantsListResponse,
  MessagingParticipantsService,
} from 'src/api/generated';

interface ParticipantsResponse extends ApiResponse {
  participants: Participant[];
}

interface ParticipantsListParams extends ParticipantsListQueryParams {
  conversationId: string;
}

export const useParticipants = (
  params: ParticipantsListParams
): ParticipantsResponse => {
  const fetchParticipants = async (): Promise<ParticipantsListResponse> => {
    const { conversationId } = params;

    return await MessagingParticipantsService.getParticipants(conversationId);
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['messaging/participants', params],
    queryFn: fetchParticipants,
  });

  const participants = useMemoizedArrayData<Participant>(data);

  return {
    loading,
    error,
    participants,
    refetch,
  };
};
