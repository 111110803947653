// src/components/calls-summary-weekly-chart.tsx

import React from 'react';
import BaseCallsSummaryChart from '../base-calls-summary-chart';
import { useCallSummaryData } from 'hooks/charts/call-summaries';
import { CallSummaries } from 'src/types/call-summaries';

const CallsSummaryWeeklyChart: React.FC<CallSummaries> = ({ summaries }) => {
  const chartData = useCallSummaryData(summaries, 'weekly');

  return (
    <BaseCallsSummaryChart
      labels={chartData.labels}
      effectiveCalls={chartData.effectiveCalls}
      missedCalls={chartData.missedCalls}
      voicemailCalls={chartData.voicemailCalls}
      // TODO: check when implementing i18n
      chartTitle="Llamadas Semanales"
    />
  );
};

export default CallsSummaryWeeklyChart;
