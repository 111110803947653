import { FilterOption } from 'components/molecules/tables/filters';
import { SortOrder } from 'components/molecules/tables/sorting-header/sorting-header';
import { TableRowProps } from 'components/molecules/tables/table';
import { filterData, sortData } from 'components/molecules/tables/utils';
import { useState, useMemo, useCallback } from 'react';

export const usePagination = (
  data: TableRowProps[],
  rowsPerPage: number = 8
) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(
    () => Math.ceil(data.length / rowsPerPage),
    [data, rowsPerPage]
  );

  const paginateData = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return data.slice(startIndex, endIndex);
  };

  return {
    paginatedData: paginateData(),
    totalPages,
    currentPage,
    setCurrentPage,
  };
};

const useControlledSorting = (
  data: TableRowProps[],
  controlledSortField: string | null,
  setControlledSortField: (field: string | null) => void,
  controlledSortOrder: SortOrder | null,
  setControlledSortOrder: (order: SortOrder | null) => void
) => {
  const sortedData = useMemo(() => {
    return sortData(data, controlledSortField, controlledSortOrder);
  }, [data, controlledSortField, controlledSortOrder]);

  return {
    sortedData,
    controlledSortField,
    setControlledSortField,
    controlledSortOrder,
    setControlledSortOrder,
  };
};

export const useSorting = (
  data: TableRowProps[],
  defaultSortField?: string | null,
  defaultSortOrder?: SortOrder | null,
  controlledSortField?: string | null,
  setControlledSortField?: (field: string | null) => void,
  controlledSortOrder?: SortOrder | null,
  setControlledSortOrder?: (order: SortOrder | null) => void
) => {
  const [uncontrolledSortField, setUncontrolledSortField] = useState<
    string | null
  >(defaultSortField || null);
  const [uncontrolledSortOrder, setUncontrolledSortOrder] = useState<SortOrder>(
    defaultSortOrder || null
  );

  const isControlled =
    controlledSortField !== undefined &&
    setControlledSortField !== undefined &&
    controlledSortOrder !== undefined &&
    setControlledSortOrder !== undefined;

  const sortField = isControlled ? controlledSortField : uncontrolledSortField;
  const sortOrder = isControlled ? controlledSortOrder : uncontrolledSortOrder;
  const setSortField = isControlled
    ? setControlledSortField
    : setUncontrolledSortField;
  const setSortOrder = isControlled
    ? setControlledSortOrder
    : setUncontrolledSortOrder;

  const { sortedData } = useControlledSorting(
    data,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder
  );

  return { sortedData, sortField, setSortField, sortOrder, setSortOrder };
};

export const useControlSorting = (
  defaultSortField?: string | null,
  defaultSortOrder?: SortOrder | null
) => {
  const [sortField, setSortField] = useState<string | null>(
    defaultSortField || null
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    defaultSortOrder || null
  );

  return {
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
  };
};

const useControlledFiltering = (
  data: TableRowProps[],
  filterOptions: FilterOption[],
  countRecords: boolean = true,
  controlledFilterIndex: number,
  setControlledFilterIndex: (selectedIndex: number) => void
) => {
  const filterOptionsWithCount = useMemo(
    () =>
      filterOptions.map((filter) => {
        if (!countRecords) {
          return filter;
        }

        const fileterdData = filterData(data, filter);
        const count = fileterdData.length;

        const filterWithCount: FilterOption = {
          ...filter,
          label: `${filter.label} (${count})`,
        };
        return filterWithCount;
      }),
    [countRecords, data, filterOptions]
  );

  const selectedFilter = filterOptionsWithCount[controlledFilterIndex] || 0;
  const setSelectedFilter = useCallback(
    (selected: FilterOption) => {
      const newIndex = filterOptionsWithCount.indexOf(selected);
      if (newIndex < 0) {
        return;
      }
      setControlledFilterIndex(newIndex);
    },
    [filterOptionsWithCount, setControlledFilterIndex]
  );

  const filteredData = useMemo(() => {
    return filterData(data, selectedFilter);
  }, [data, selectedFilter]);

  return {
    filteredData,
    selectedFilter,
    setSelectedFilter,
    filterOptionsWithCount,
  };
};

export const useFiltering = (
  data: TableRowProps[],
  filterOptions: FilterOption[],
  defaultIndex?: number,
  countRecords: boolean = true,
  controlledFilterIndex?: number,
  setControlledFilterIndex?: (selectedIndex: number) => void
) => {
  const initialIndex =
    defaultIndex && filterOptions.length > defaultIndex ? defaultIndex : 0;
  const [selectedUncontrolledIndex, setSelectedUncontrolledIndex] =
    useState<number>(initialIndex);

  const isControlled =
    controlledFilterIndex !== undefined &&
    setControlledFilterIndex !== undefined;
  const selectedControlledUncontrolled = isControlled
    ? controlledFilterIndex
    : selectedUncontrolledIndex;
  const setSelectedControlledUncontrolled = isControlled
    ? setControlledFilterIndex
    : setSelectedUncontrolledIndex;

  const {
    filteredData,
    selectedFilter,
    setSelectedFilter,
    filterOptionsWithCount,
  } = useControlledFiltering(
    data,
    filterOptions,
    countRecords,
    selectedControlledUncontrolled,
    setSelectedControlledUncontrolled
  );

  return {
    filteredData,
    selectedFilter,
    setSelectedFilter,
    filterOptionsWithCount,
  };
};
