import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { useUserPermissions, useUserRoles } from './users';

export const useRefreshAuthToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  const refresh = useCallback(async () => {
    const cacheMode: 'on' | 'off' = 'off';
    await getAccessTokenSilently({ cacheMode });
  }, [getAccessTokenSilently]);

  return { refresh };
};

/**
 * Hook to check if the user has the required permissions.
 *
 * @param requiredPermissions - A string or an array of strings representing the required permissions.
 * @returns An object containing:
 * - hasPermission: A boolean indicating if the user has all the required permissions.
 * - loading: A boolean indicating if the permissions are still loading.
 */
export const useHasPermissions = (requiredPermissions: string | string[]) => {
  const { permissions, loading } = useUserPermissions();

  const requiredPermissionsArray = Array.isArray(requiredPermissions)
    ? requiredPermissions
    : [requiredPermissions];

  const hasPermission = requiredPermissionsArray.every((permission) =>
    permissions.includes(permission)
  );

  return { hasPermission, loading };
};

/**
 * Hook to check if the user has the required roles.
 *
 * @param requiredRoles - A string or an array of strings representing the required roles.
 * @returns An object containing:
 * - hasRole: A boolean indicating if the user has all the required roles.
 * - loading: A boolean indicating if the roles are still loading.
 */
export const useHasRoles = (requiredRoles: string | string[]) => {
  const { roles, loading } = useUserRoles();

  const requiredRolesArray = Array.isArray(requiredRoles)
    ? requiredRoles
    : [requiredRoles];

  const hasRole = requiredRolesArray.every((role) => roles.includes(role));

  return { hasRole, loading };
};
