/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ToolCallFunction } from './ToolCallFunction';

export type ToolCall = {
    /**
     * This is the unique identifier for the tool call.
     */
    id: string;
    /**
     * This is the function the model called.
     */
    function: ToolCallFunction;
    /**
     * This is the type of tool the model called.
     */
    type: ToolCall.type;
};

export namespace ToolCall {

    /**
     * This is the type of tool the model called.
     */
    export enum type {
        FUNCTION = 'function',
    }


}

