// src/modules/messaging/hooks/api/messages.ts

import { useInfiniteQuery } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from 'hooks/api/api';
import {
  Alias_MessageListQueryParams as MessagesListQueryParams,
  Message,
  MessagesListResponse,
  MessagingMessagesService,
} from 'src/api/generated';
import { SEC_IN_MS } from 'src/utils/date';

interface MessagesResponse extends ApiResponse {
  messages: Message[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
}

interface MessagesListParams extends MessagesListQueryParams {
  conversationId: string;
}

export const useMessages = (params: MessagesListParams): MessagesResponse => {
  const fetchMessages = async ({
    pageParam,
  }: {
    pageParam: string | undefined;
  }): Promise<MessagesListResponse> => {
    const { conversationId, limit, direction } = params;

    return await MessagingMessagesService.getMessages(
      conversationId,
      limit,
      pageParam,
      direction
    );
  };
  const getNextPageParam = (lastPage: MessagesListResponse) =>
    lastPage.nextPageToken;

  const {
    data,
    error,
    isLoading: loading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['messaging/messages', params],
    queryFn: fetchMessages,
    getNextPageParam,
    initialPageParam: undefined,
    refetchInterval: SEC_IN_MS * 5,
  });

  const messages = useMemoizedArrayData<Message>(
    data?.pages.flatMap((page) => page.items) || []
  );

  return {
    loading,
    error,
    messages,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};
