import React from 'react';
import { Typography } from '@material-tailwind/react';
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

export type TableColumnProps = {
  header: string | JSX.Element;
  accessor: string;
  className?: string;
};

export type TableCellProps = {
  accesor: string | null;
  value: string | number | Date | null;
  element?: JSX.Element;
};
export type TableRowProps = {
  key: string;
  row: TableCellProps[];
};

type TableProps = {
  columns: TableColumnProps[];
  data: TableRowProps[];
};

const Table: React.FC<TableProps> = ({ columns, data }) => {
  const renderCell = (
    tableRow: TableRowProps,
    column: TableColumnProps
  ): string | JSX.Element => {
    const cell = tableRow.row.find(
      (cell: TableCellProps) => cell.accesor === column.accessor
    );
    if (!cell) return '';

    if (cell.element) {
      return cell.element;
    }

    return (
      <Typography variant="small" color="blue-gray" className="font-normal">
        {cell.value?.toString() || ''}
      </Typography>
    );
  };

  return (
    <TableContainer component={Paper}>
      <MuiTable className="w-full table-auto text-left">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.accessor}
                className="border-y border-blue-gray-100 bg-blue-gray-50/50"
              >
                {typeof column.header === 'string' ? (
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {column.header}
                  </Typography>
                ) : (
                  column.header
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length === 0 && (
            <TableRow className="border-b border-blue-gray-50">
              <TableCell colSpan={100} className="text-center p-4 pb-6">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {/* TODO: check when implementing i18n */}
                  No se encontraron datos.
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {data.map((tableRow) => (
            <TableRow
              key={tableRow.key}
              className="border-b border-blue-gray-50"
            >
              {columns.map((column) => (
                <TableCell
                  key={`${tableRow.key}-${column.accessor}`}
                  className={`${column.className || ''}`}
                  size="small"
                >
                  {renderCell(tableRow, column)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
