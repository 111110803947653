import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  IconButton,
} from '@material-tailwind/react';

interface CallMenuProps {
  callingWithoutInternet: boolean;
  updateSetting: (value: boolean) => void;
  iconButtonClasses?: string;
}

const CallMenu = ({
  callingWithoutInternet,
  updateSetting,
  iconButtonClasses,
}: CallMenuProps) => {
  const handleMenuItemClick = (useInternet: boolean) => {
    if (callingWithoutInternet === useInternet) return;

    updateSetting(useInternet);
  };

  const selectedItemClasses = 'bg-blue-500 hover:!bg-blue-600';
  const selectedTextClasses = 'text-blue-50';

  return (
    <Menu>
      <MenuHandler>
        <IconButton
          size="md"
          variant="text"
          className={`w-7 rounded-none rounded-r-lg bg-green-500 hover:bg-green-600 active:bg-green-600 ${iconButtonClasses}`}
        >
          <ChevronUpDownIcon className={'h-5 w-5 text-blue-50'} />
        </IconButton>
      </MenuHandler>
      <MenuList className="p-2">
        <MenuItem
          onClick={() => handleMenuItemClick(false)}
          className={`flex items-center ${callingWithoutInternet ? '' : selectedItemClasses}`}
        >
          <span
            className={`${callingWithoutInternet ? '' : selectedTextClasses}`}
          >
            Llamar por internet
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick(true)}
          className={`flex items-center ${callingWithoutInternet ? selectedItemClasses : ''}`}
        >
          <span
            className={`${callingWithoutInternet ? selectedTextClasses : ''}`}
          >
            Llamar por teléfono
          </span>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default CallMenu;
