// src/components/molecules/calls/call-info-modals/call-info-modals.tsx

import { useState } from 'react';
import { SettingName } from 'src/api/generated';
import RecordingPlayer from '../recording-player';
import SummaryModalContent from 'components/molecules/analysis/summary-modal-content';
import FeedbackModalContent from 'components/molecules/analysis/feedback-modal-content';
import ButtonModal from 'src/modules/shared/components/molecules/modals/button-modal';
import {
  AcademicCapIcon,
  ChatBubbleBottomCenterTextIcon,
  StarIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import TranscriptionModalContent from 'components/molecules/analysis/transcription-modal-content';
import { useFeatureFlagActive } from 'hooks/settings/feature-flags';
import { Tooltip } from '@mui/material';
import { MIN_PERFORMANCE_SCORE } from 'src/utils/analysisValues/scores/scores';

interface CallInfoModalsProps {
  callId: number;
  callAgentScore: number;
  buttonClassName?: string;
  iconClassName?: string;
  playerHeight?: number;
  playerWidth?: number;
  visible?: boolean;
}

const CallInfoModals: React.FC<CallInfoModalsProps> = ({
  callId,
  callAgentScore,
  buttonClassName = '',
  iconClassName = '',
  playerHeight = 40,
  playerWidth = 300,
  visible = true,
}) => {
  const [copyableSummary, setCopyableSummary] = useState<string>('');
  const [copyableTranscription, setCopyableTranscription] =
    useState<string>('');

  const showRecordings = useFeatureFlagActive(
    SettingName.FEATURE_FLAG_SHOW_RECORDINGS
  );
  const showTranscriptions = useFeatureFlagActive(
    SettingName.FEATURE_FLAG_SHOW_TRANSCRIPTIONS
  );
  // TODO: chen when implementing i18n
  const transcriptionModalTitle = `Transcripción de la llamada`;
  const summaryModalTitle = `Resumen de la llamada`;
  const feedbackModalTitle = 'Feedback de la llamada';

  if (!visible) return null;

  const isFeatured = callAgentScore >= MIN_PERFORMANCE_SCORE;

  return (
    <div className="flex justify-center items-center">
      {isFeatured ? (
        <Tooltip
          title={'Llamada destacada'}
          placement="top"
          PopperProps={{
            // workaround to avoid tooltip being hidden behind modal
            style: { zIndex: 10000 },
          }}
        >
          <StarIcon className={`h-6 w-6 text-blue-600 mr-4 ${iconClassName}`} />
        </Tooltip>
      ) : (
        <div className="h-6 w-6 mr-4" />
      )}

      {showRecordings && (
        <RecordingPlayer
          callId={callId}
          iconClassName={iconClassName}
          buttonClassName={buttonClassName}
          playerHeight={playerHeight}
          playerWidth={playerWidth}
        />
      )}

      {showTranscriptions && (
        <ButtonModal
          icon={
            <ChatBubbleBottomCenterTextIcon
              strokeWidth={2}
              className={`h-6 w-6 text-blue-gray-700 ${iconClassName}`}
            />
          }
          title={transcriptionModalTitle}
          className={buttonClassName}
          copyableContent={copyableTranscription}
        >
          {(enabled: boolean) => (
            <TranscriptionModalContent
              callId={callId}
              enabled={enabled}
              onCopyableContentChange={setCopyableTranscription}
            />
          )}
        </ButtonModal>
      )}

      <ButtonModal
        icon={
          <UserIcon
            strokeWidth={2}
            className={`h-6 w-6 text-blue-gray-700 ${iconClassName}`}
          />
        }
        title={summaryModalTitle}
        className={buttonClassName}
        copyableContent={copyableSummary}
        bodyClassName={'!p-0'}
      >
        {(enabled: boolean) => (
          <SummaryModalContent
            callId={callId}
            enabled={enabled}
            onCopyableContentChange={setCopyableSummary}
          />
        )}
      </ButtonModal>

      <ButtonModal
        icon={
          <AcademicCapIcon
            strokeWidth={2}
            className={`h-6 w-6 text-blue-gray-700 ${iconClassName}`}
          />
        }
        title={feedbackModalTitle}
        className={buttonClassName}
        bodyClassName={'!p-0'}
      >
        {(enabled: boolean) => (
          <FeedbackModalContent callId={callId} enabled={enabled} />
        )}
      </ButtonModal>
    </div>
  );
};

export default CallInfoModals;
