import React from 'react';
import { Button } from '@material-tailwind/react';

interface IncomingCallProps {
  onAccept: () => void;
  onReject: () => void;
  phoneNumber: string;
}

const IncomingCall: React.FC<IncomingCallProps> = ({
  onAccept,
  onReject,
  phoneNumber,
}) => {
  return (
    <div className="incoming-call-container p-4 text-blue-600 font-bold flex flex-col items-center justify-center">
      <div className="call-info text-center mb-3">
        <h2 className="text-xl">Llamada entrante</h2>
        <p>{phoneNumber}</p>
      </div>
      <div className="flex space-x-4">
        <Button
          size="lg"
          onClick={onAccept}
          className="p-2 rounded bg-green-500 hover:bg-green-600 text-white"
        >
          Aceptar
        </Button>
        <Button
          size="lg"
          onClick={onReject}
          className="p-2 rounded bg-red-500 hover:bg-red-600 text-white"
        >
          Rechazar
        </Button>
      </div>
    </div>
  );
};

export default IncomingCall;
