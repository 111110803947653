import LogoutButton from 'components/molecules/buttons/logout-button';
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import { baseFrontendUrl } from 'src/utils/urls';

const FRONTEND_URL = baseFrontendUrl();

interface PhoneFooterProps {
  redirectUrlAfterLogout?: string;
}

const PhoneFooter: React.FC<PhoneFooterProps> = ({
  redirectUrlAfterLogout,
}) => {
  return (
    <div className="flex justify-between p-1 px-4 border border-t-blue-200">
      <LogoutButton
        redirectUrlAfterLogout={redirectUrlAfterLogout}
        className="w-auto !p-2 rounded-full"
      >
        <ArrowLeftOnRectangleIcon strokeWidth={2} className="h-5 w-5" />
      </LogoutButton>

      <a
        href={FRONTEND_URL}
        target="_blank"
        rel="noreferrer"
        className="flex items-center underline text-blue-600 text-sm"
      >
        {/* TODO: check when implementing i18n */}
        Ir a LlamadaPro
      </a>
    </div>
  );
};

export default PhoneFooter;
