import { useEffect, useState } from 'react';

/**
 * Hook that forces a component to re-render at a specified interval
 * @param intervalMs - Interval in milliseconds between re-renders
 */
export const usePeriodicRerender = (intervalMs: number = 5000): void => {
  const [, setTick] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTick((tick) => tick + 1);
    }, intervalMs);

    return () => clearInterval(interval);
  }, [intervalMs]);
};
