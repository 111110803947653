import React from 'react';
import Modal from 'src/modules/shared/components/molecules/modals/modal';

interface MediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const MediaModal: React.FC<MediaModalProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      dialogClassName="!max-w-full !max-h-full w-auto"
      body={children}
    />
  );
};

export default MediaModal;
