import { AnalysisValueView, CallAnalysis } from 'src/api/generated';

export const getCallAnalysisValues = (
  analysisValues: AnalysisValueView[],
  callAnalysis?: CallAnalysis | null
) => {
  if (!callAnalysis) return [];

  return analysisValues.filter(
    (analysisValue) => analysisValue.callAnalysisId === callAnalysis.id
  );
};
