import React, { forwardRef } from 'react';
import Label from '../label';
import TextArea from '../text-area';
import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';

interface LabelTextAreaProps {
  label: string;
  variant?: ComponentVariant;
  size?: ComponentSize;
  className?: string;
  labelProps?: React.ComponentProps<typeof Label>;
  inputProps?: React.ComponentProps<typeof TextArea>;
}

const LabelTextArea = forwardRef<HTMLTextAreaElement, LabelTextAreaProps>(
  (
    {
      label,
      variant = 'primary',
      size = 'md',
      className = '',
      labelProps = {},
      inputProps = {},
    },
    ref
  ) => {
    return (
      <div
        className={`flex flex-col sm:flex-row sm:items-start gap-2 ${className}`}
      >
        <div className="sm:w-1/3">
          <Label variant={variant} size={size} {...labelProps}>
            {label}
          </Label>
        </div>
        <div className="flex-1">
          <TextArea ref={ref} variant={variant} size={size} {...inputProps} />
        </div>
      </div>
    );
  }
);

LabelTextArea.displayName = 'LabelTextArea';

export default LabelTextArea;
