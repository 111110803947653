/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type HangServerMessage = {
    /**
     * This is the timestamp of the message.
     */
    timestamp?: string;
    /**
     * These are the live artifacts of the call.
     */
    artifact?: Record<string, any>;
    /**
     * This is the main `call` object of the call.
     */
    call: Record<string, any>;
    /**
     * The customer associated with the call. This either directly matches `call.customer` or is expanded from `call.customerId`.
     */
    customer: Record<string, any>;
    /**
     * The phone number associated with the call. This either directly matches `call.phoneNumber` or is expanded from `call.phoneNumberId`.
     */
    phoneNumber?: Record<string, any>;
    /**
     * This is the type of the message. "hang" is sent when the assistant is hanging due to a delay. The delay can be caused by many factors, such as:
     * - the model is too slow to respond
     * - the voice is too slow to respond
     * - the tool call is still waiting for a response from your server
     * - etc.
     */
    type: HangServerMessage.type;
};

export namespace HangServerMessage {

    /**
     * This is the type of the message. "hang" is sent when the assistant is hanging due to a delay. The delay can be caused by many factors, such as:
     * - the model is too slow to respond
     * - the voice is too slow to respond
     * - the tool call is still waiting for a response from your server
     * - etc.
     */
    export enum type {
        HANG = 'hang',
    }


}

