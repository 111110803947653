// src/modules/shared/hooks/modal.ts

import { useEffect } from 'react';

interface UseModalProps {
  isOpen: boolean;
  onClose: () => void;
}

/**
 * Hook to handle escape key press to close the modal.
 * @param isOpen - Boolean indicating if the modal is open.
 * @param onClose - Function to close the modal.
 */
const useEscapeKeyClose = ({ isOpen, onClose }: UseModalProps) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
    }
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, onClose]);
};

/**
 * Hook to manage body overflow when the modal is open.
 * @param isOpen - Boolean indicating if the modal is open.
 */
const useBodyOverflowControl = (isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);
};

/**
 * Custom hook to manage modal behavior.
 * @param isOpen - Boolean indicating if the modal is open.
 * @param onClose - Function to close the modal.
 */
export const useModal = ({ isOpen, onClose }: UseModalProps) => {
  useEscapeKeyClose({ isOpen, onClose });
  useBodyOverflowControl(isOpen);
};

import { useCallback } from 'react';
import { useAlert } from 'src/contexts/alert-context';

/**
 * Custom hook to copy text to clipboard and show a notification.
 * @returns A function to copy text to clipboard.
 */
export const useCopyToClipboard = () => {
  const { showAlert } = useAlert();

  return useCallback(
    async (text: string) => {
      if (!text) return;

      try {
        await navigator.clipboard.writeText(text);
        // TODO: check when implementing i18n
        showAlert('Texto copiado al portapapeles.', 'success');
      } catch (err) {
        // TODO: check when implementing i18n
        showAlert('Error al copiar el texto.', 'error');
      }
    },
    [showAlert]
  );
};
