// src/hooks/api/companies.ts

import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from './api';
import { CompaniesService, Company } from 'src/api/generated';

interface CurrentCompanyResponse extends ApiResponse {
  currentCompany: Company | null;
}

export const useCurrentCompany = (): CurrentCompanyResponse => {
  const fetchCompany = async (): Promise<Company> => {
    return await CompaniesService.getCurrentCompany();
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['company/current'],
    queryFn: fetchCompany,
  });

  return {
    loading: isLoading,
    error,
    currentCompany: data || null,
    refetch,
  };
};
