// src/utils/urls.ts

export const baseFrontendUrl = () => {
  if (import.meta.env.DEV) {
    return 'http://localhost:3000';
  }
  if (import.meta.env.VITE_ENV_MODE_STAGE_API_URL) {
    return 'https://stage.llamadapro.app/';
  }

  return 'https://llamadapro.app/';
};
