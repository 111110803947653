import {
  AnalysisConfigurableType,
  AnalysisValueView,
  ScoreConfigKey,
} from 'src/api/generated';
import {
  getTrimmedDescription,
  getTrimmedEvidence,
  getTrimmedValue,
} from '../analysisValues';
import { PERFORMANCE_SCORES } from './performance/performance';

export const MIN_PERFORMANCE_SCORE = 20;

const HIDDEN_SCORES: ScoreConfigKey[] = [...PERFORMANCE_SCORES];

export const getScores = (
  analysisValues: AnalysisValueView[]
): AnalysisValueView[] => {
  return analysisValues.filter(isScoreValue);
};

export const getPublicScores = (
  analysisValues: AnalysisValueView[]
): AnalysisValueView[] => {
  return getScores(analysisValues).filter(
    (value) => !HIDDEN_SCORES.includes(value.configKey as ScoreConfigKey)
  );
};

export const getScoresByConfigKey = (
  analysisValues: AnalysisValueView[],
  scoreConfigKey: ScoreConfigKey
): AnalysisValueView[] => {
  return getScores(analysisValues).filter(
    (value) => value.configKey === scoreConfigKey
  );
};

export const getScoreValue = (
  analysisValue: AnalysisValueView
): number | null => {
  if (!isScoreValue(analysisValue)) return null;

  const trimmedValue = getTrimmedValue(analysisValue);
  if (trimmedValue === null) return null;

  const parsedValue = parseInt(trimmedValue);
  return parsedValue;
};

export const getScoreDescription = (
  analysisValue: AnalysisValueView
): string | null => {
  if (!isScoreValue(analysisValue)) return null;

  const trimmedDescription = getTrimmedDescription(analysisValue);
  if (trimmedDescription === null) return null;

  return trimmedDescription;
};

export const getScoreEvidence = (
  analysisValue: AnalysisValueView
): string | null => {
  if (!isScoreValue(analysisValue)) return null;

  const trimmedEvidence = getTrimmedEvidence(analysisValue);
  if (trimmedEvidence === null) return null;

  return trimmedEvidence;
};

export const getScoreMaxValue = (
  analysisValue: AnalysisValueView
): number | null => {
  if (!isScoreValue(analysisValue)) return null;

  const maxValue = analysisValue.scoreMaxValue;
  if (!maxValue) return null;

  return maxValue;
};

const isScoreValue = (analysisValue: AnalysisValueView): boolean => {
  return (
    analysisValue.analysisConfigurableType ===
    AnalysisConfigurableType.ANALYSIS_SCORE
  );
};
