// src/hooks/analysis/summary/publicScores.ts

import { useMemo } from 'react';
import { AnalysisValueView } from 'src/api/generated';
import { getPublicScores } from 'src/utils/analysisValues/scores/scores';

export const usePublicScores = (
  analysisValues: AnalysisValueView[],
  latestContactQualiScore: AnalysisValueView | null
) => {
  return useMemo(() => {
    const scores = getPublicScores(analysisValues);
    if (latestContactQualiScore) {
      return scores.filter((score) => score.id !== latestContactQualiScore.id);
    }
    return scores;
  }, [analysisValues, latestContactQualiScore]);
};
