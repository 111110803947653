// src/components/molecules/download-button/download-button.tsx

import { IconButton, Spinner, Tooltip } from '@material-tailwind/react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { useDownloads } from 'hooks/api/downloads';
import { DownloadType, SettingName } from 'src/api/generated';
import { useEffect } from 'react';
import { useFeatureFlagActive } from 'hooks/settings/feature-flags';

interface DownloadButtonProps {
  downloadType: DownloadType;
  className?: string;
  iconClassName?: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  downloadType = DownloadType.CALLS,
  className,
  iconClassName,
}) => {
  const { url, loading, refetch: fetchDownload } = useDownloads(downloadType);
  const downloadCallsActive = useFeatureFlagActive(SettingName.DOWNLOADS_CALLS);

  const downloadUrl = (url: string) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (!url) return;

    downloadUrl(url);
  }, [url]);

  const handleDownloadClick = () => {
    if (url) return downloadUrl(url);
    fetchDownload();
  };

  if (!downloadCallsActive) return null;

  return (
    <Tooltip
      content={'Descargar todas las llamadas.'}
      placement={'bottom-start'}
      className="border border-blue-gray-50 shadow-xl ml-1 shadow-black/10 break-normal"
    >
      <IconButton
        onClick={handleDownloadClick}
        variant="text"
        className={`w-8 h-8 ${className}`}
        disabled={loading}
      >
        {loading ? (
          <Spinner color="blue" />
        ) : (
          <ArrowDownTrayIcon
            strokeWidth={2}
            className={`h-6 w-6 text-blue-600 ${iconClassName}`}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default DownloadButton;
