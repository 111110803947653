import { Textarea } from '@material-tailwind/react';
import React from 'react';

interface TextAreaProps {
  defaultValue?: string;
  onChange: (newValue: string) => void;
  className?: string;
  placeholder?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  defaultValue,
  onChange,
  className = '',
  placeholder = '',
}) => {
  const classes = `
    border-t-blue-gray-200 focus:border-t-blue-500 focus:border-blue-500
    h-8 ${className}
  `;
  return (
    <div className="w-full">
      <Textarea
        variant="outlined"
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={(e) => onChange(e.target.value)}
        className={classes}
        labelProps={{ className: `hidden` }}
        containerProps={{ className: 'h-auto h-8' }}
      />
    </div>
  );
};

export default TextArea;
