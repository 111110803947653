import { Filter, FilterOption } from 'components/molecules/tables/filters';
import { SortOrder } from 'components/molecules/tables/sorting-header';
import {
  TableRowProps,
  TableCellProps,
} from 'components/molecules/tables/table';
import { equalsInsensitiveUnspaced } from 'src/utils/strings';

const findCell = (
  tableRow: TableRowProps,
  columnAccesor: string
): TableCellProps | undefined => {
  const cell = tableRow.row.find(
    (cell: TableCellProps) => cell.accesor === columnAccesor
  );
  if (!cell) return;

  return cell;
};

export const filterData = (
  data: TableRowProps[],
  filterOption: FilterOption
): TableRowProps[] => {
  const rowMatchesFilters = (row: TableRowProps, filter: Filter): boolean => {
    const cell = findCell(row, filter.column);
    if (!cell) return false;

    return equalsInsensitiveUnspaced(
      cell?.value?.toString() || '',
      filter.value.toString()
    );
  };

  const filteredData = data.filter((row) => {
    const keepRow: boolean = filterOption.filters.every((filter: Filter) =>
      rowMatchesFilters(row, filter)
    );

    return keepRow;
  });

  return filteredData;
};

export const sortData = (
  data: TableRowProps[],
  sortField: string | null,
  order: SortOrder
): TableRowProps[] => {
  if (!sortField || !order) return data;

  const sortFunction = (a: TableRowProps, b: TableRowProps) => {
    const cellA = findCell(a, sortField)?.value;
    const cellB = findCell(b, sortField)?.value;
    if (
      cellA === undefined ||
      cellA === null ||
      cellB === undefined ||
      cellB === null
    ) {
      return 0;
    }

    if (cellA < cellB) return order === 'asc' ? -1 : 1;
    if (cellA > cellB) return order === 'asc' ? 1 : -1;
    return 0;
  };
  const sortedData = data.sort(sortFunction);

  return sortedData;
};

export const paginateData = (
  data: TableRowProps[],
  currentPage: number,
  rowsPerPage: number
): TableRowProps[] => {
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = data.slice(startIndex, endIndex);

  return paginatedData;
};
