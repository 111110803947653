import React, { ComponentProps, ReactNode } from 'react';
import Loading from '../../loading';
import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';

interface SelectOption {
  content: ReactNode | string;
  value: string;
}

interface SelectProps extends Omit<ComponentProps<'select'>, 'size'> {
  options: SelectOption[];
  variant?: ComponentVariant;
  size?: ComponentSize;
  loading?: boolean;
  className?: string;
  containerClassName?: string;
}

const Select: React.FC<SelectProps> = ({
  options,
  variant = 'primary',
  size = 'md',
  loading = false,
  className = '',
  containerClassName = '',
  ...rest
}) => {
  const baseClasses = [
    'flex-1',
    'flex',
    'border',
    'rounded-md',
    'focus:outline-none',
    'focus:ring-1',
    'box-border',
    'leading-normal',
  ];

  const variantClasses: Record<ComponentVariant, string> = {
    primary: 'focus:ring-blue-500 border-gray-300',
    secondary: 'focus:ring-gray-500 border-gray-400',
    danger: 'focus:ring-red-500 border-red-300',
    warning: 'focus:ring-yellow-500 border-yellow-300',
    plain: 'focus:ring-gray-500 border-gray-300',
  };

  const sizeClasses: Record<ComponentSize, string> = {
    lg: 'py-2 leading-[1.9rem] px-4 text-md min-w-[140px]',
    md: 'py-2 leading-[1.4rem] px-4 text-sm min-w-[140px]',
    sm: 'py-1.5 leading-[1.15rem] px-3 text-sm min-w-[120px]',
    xs: 'py-1 leading-[1.15rem] px-2 text-xs min-w-[100px]',
    '2xs': 'py-0.5 leading-[1.15rem] px-1.5 text-xs min-w-[80px]',
  };

  const classes = [
    ...baseClasses,
    variantClasses[variant],
    sizeClasses[size],
    className,
  ].join(' ');

  const containerClasses = [
    'flex',
    'flex-1',
    'justify-end',
    'items-center',
    containerClassName,
  ].join(' ');

  return (
    <div className={containerClasses}>
      <select className={classes} disabled={loading} {...rest}>
        {options.map(({ content, value }) => (
          <option key={value} value={value}>
            {content}
          </option>
        ))}
      </select>
      {loading && <Loading className="ml-2" />}
    </div>
  );
};

export default Select;
