// src/components/molecules/month-picker/month-picker.tsx

import Select from 'components/atoms/select';
import { useMonthPickerValues } from 'hooks/date';
import { SelectData } from 'tw-elements-react/dist/types/forms/Select/types';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import ChevronButton from 'components/atoms/buttons/chevron-button';
import { useCallback, useMemo } from 'react';

interface MonthPickerProps {
  selectedYear: number;
  selectedMonth: number;
  setSelectedDate: (date: Date) => void;
}

const MonthPicker: React.FC<MonthPickerProps> = ({
  selectedYear,
  selectedMonth,
  setSelectedDate,
}) => {
  const { years, months } = useMonthPickerValues();

  const handleYearChange = useCallback(
    (selectedData: SelectData | SelectData[]) => {
      if (
        !Array.isArray(selectedData) &&
        typeof selectedData?.value === 'string'
      ) {
        const newYear = parseInt(selectedData.value);
        const newDate = new Date(newYear, selectedMonth);
        setSelectedDate(newDate);
      }
    },
    [selectedMonth, setSelectedDate]
  );

  const handleMonthChange = useCallback(
    (selectedData: SelectData | SelectData[]) => {
      if (
        !Array.isArray(selectedData) &&
        typeof selectedData?.value === 'string'
      ) {
        const newMonth = parseInt(selectedData.value);
        const newDate = new Date(selectedYear, newMonth);
        setSelectedDate(newDate);
      }
    },
    [selectedYear, setSelectedDate]
  );

  const isCurrentDate = useMemo(() => {
    const currentDate = new Date();
    const isCurrentMonth = selectedMonth === currentDate.getMonth();
    const isCurrentYear = selectedYear === currentDate.getFullYear();

    return isCurrentMonth && isCurrentYear;
  }, [selectedYear, selectedMonth]);

  const updateDateWithOffset = (monthOffset: number) => {
    const newDate = new Date(selectedYear, selectedMonth + monthOffset);
    setSelectedDate(newDate);
  };

  const handleLeftArrowClick = () => updateDateWithOffset(-1);
  const handleRightArrowClick = () => updateDateWithOffset(1);

  return (
    <div className="flex flex-row w-80 items-center justify-between gap-2">
      <ChevronButton onClick={handleLeftArrowClick}>
        <ChevronLeftIcon strokeWidth="2" className="h-4 w-4" />
      </ChevronButton>
      <Select
        data={months}
        search
        // TODO: check when implementing i18n
        searchLabel="Buscar..."
        label="Elegir mes"
        value={selectedMonth.toString()}
        onValueChange={handleMonthChange}
        noResultsText="No hay resultados"
      />
      <Select
        data={years}
        search
        // TODO: check when implementing i18n
        searchLabel="Buscar..."
        label="Elegir año"
        value={selectedYear.toString()}
        onValueChange={handleYearChange}
        noResultsText="No hay resultados"
      />
      <ChevronButton onClick={handleRightArrowClick} disabled={isCurrentDate}>
        <ChevronRightIcon strokeWidth="2" className="h-4 w-4" />
      </ChevronButton>
    </div>
  );
};

export default MonthPicker;
