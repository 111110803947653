// src/hooks/api/downloads.ts

import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from './api';
import { DownloadsService, DownloadType } from 'src/api/generated';

interface DownloadsResponse extends ApiResponse {
  url?: string | null;
}

export const useDownloads = (downloadType: DownloadType): DownloadsResponse => {
  const fetchDownloads = async (): Promise<string | null> => {
    return await DownloadsService.createDownload(downloadType);
  };

  const {
    data: url,
    error,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ['downloads', downloadType],
    queryFn: fetchDownloads,
    enabled: false,
  });

  return {
    loading: isLoading || isRefetching,
    error,
    url,
    refetch,
  };
};
