import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';
import Button from '../../atoms/buttons/button';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  variant?: ComponentVariant;
  size?: ComponentSize;
  className?: string;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  variant = 'plain',
  size = 'md',
  className = '',
}) => {
  const sizeClasses: Record<ComponentSize, string> = {
    lg: 'gap-6',
    md: 'gap-4',
    sm: 'gap-3',
    xs: 'gap-2',
    '2xs': 'gap-2',
  };

  const iconSizeClasses: Record<ComponentSize, string> = {
    lg: 'h-5 w-5',
    md: 'h-4 w-4',
    sm: 'h-4 w-4',
    xs: 'h-3 w-3',
    '2xs': 'h-3 w-3',
  };

  const baseClasses = [
    'flex',
    'justify-between',
    'items-center',
    'mb-2',
    sizeClasses[size],
    className,
  ].join(' ');

  return (
    <div className={baseClasses}>
      <Button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        variant={variant}
        size={size}
      >
        <ChevronLeftIcon className={iconSizeClasses[size]} />
        {/* TODO: check when implementing i18n */}
        Anterior
      </Button>
      <span className="text-sm">
        {/* TODO: check when implementing i18n */}
        Página {currentPage} de {totalPages}
      </span>
      <Button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        variant={variant}
        size={size}
      >
        {/* TODO: check when implementing i18n */}
        Siguiente
        <ChevronRightIcon className={iconSizeClasses[size]} />
      </Button>
    </div>
  );
};

export default Pagination;
