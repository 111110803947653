import { useState, useEffect } from 'react';
import OnCall from 'components/molecules/phone/on-call';
import STATES from 'states/index';
import IncomingCall from 'components/molecules/phone/incoming-call';
import Display from 'components/atoms/phone/display';
import Dialer from 'components/molecules/phone/dialer';
import MessageLoading from 'components/atoms/loading/message-loading';
import InfoError from 'components/atoms/info-error';
import { useMatchContact } from 'hooks/phones/phones';
import { usePhoneContext } from 'src/contexts/phone-context';
import { formatPhoneNumber } from 'src/utils/formatting/phone';
import IconCallButton from 'components/atoms/phone/icon-call-button';
import { CallProvider } from 'hooks/phones/types';
import { useBooleanSetting } from 'hooks/settings/settings';
import { SettingName } from 'src/api/generated';
import ContactDisplay from 'components/molecules/phone/contact-display';
import { useUpdateSetting } from 'hooks/api/settings';
import { useAlert } from 'src/contexts/alert-context';

interface CompactPhoneProps {
  callProvider: CallProvider;
}

const CompactPhone: React.FC<CompactPhoneProps> = ({
  callProvider: {
    callActions,
    padActions,
    loading: callProviderLoading,
    error: callProviderError,
    callerNumber,
    onCallNumber,
    phoneConfigActions,
  },
}) => {
  const {
    setting: callWithoutInternetChoose,
    loading: withoutInternetChooseSettingLoading,
    error: withoutInternetChooseSettingError,
  } = useBooleanSetting(SettingName.CALL_WITHOUT_INTERNET_CHOOSE);
  const {
    setting: callingWithoutInternet,
    loading: withoutInternetSettingLoading,
    error: withoutInternetSettingError,
  } = useBooleanSetting(SettingName.CALL_WITHOUT_INTERNET);

  const { showAlert } = useAlert();
  // TODO: check when implementing i18n
  const handleSettingUpdateError = () => {
    showAlert(
      'Ocurrió un error cambiando el modo del teléfono. Por favor revisa tu conexión e intenta nuevamente.',
      'error'
    );
  };
  const { updateSetting, loading: updateSettingLoading } = useUpdateSetting(
    undefined,
    handleSettingUpdateError
  );

  const {
    phoneState,
    formattedPhoneNumber,
    standardizedPhoneNumber,
    validNumberSelected,
    initialNumber,
    setPhoneNumber,
    isPhoneNumberEditable,
    resetPhoneNumber,
  } = usePhoneContext();
  const displayNumber = callerNumber || standardizedPhoneNumber;
  const { contact, loading: matchContactloading } =
    useMatchContact(displayNumber);
  const formattedCallerNumber = formatPhoneNumber(callerNumber);

  const [isDialerOpen, setIsDialerOpen] = useState(false);

  useEffect(() => {
    if (phoneState !== STATES.PHONE.ON_CALL && isDialerOpen) {
      setIsDialerOpen(false);
    }
  }, [phoneState, isDialerOpen]);

  const loading =
    callProviderLoading ||
    withoutInternetSettingLoading ||
    withoutInternetChooseSettingLoading ||
    updateSettingLoading;
  const error =
    callProviderError ||
    withoutInternetSettingError ||
    withoutInternetChooseSettingError;

  const containerClasses =
    'relative flex flex-col items-center w-[298px] h-[370px] pt-2';

  if (loading || phoneState === STATES.PHONE.REGISTERING) {
    return (
      <div className={containerClasses}>
        <MessageLoading />
      </div>
    );
  }

  if (error) {
    return (
      <div className={containerClasses}>
        <InfoError error={error as Error} />
      </div>
    );
  }

  return (
    <div className={containerClasses}>
      <div className="flex-shrink-0 w-full min-h-[120px] flex flex-col justify-center">
        {phoneState === STATES.PHONE.READY && (
          <div className="flex gap-2 p-2 items-center justify-between w-full max-w-full">
            <div className="flex-1 overflow-hidden">
              <Display
                value={formattedPhoneNumber}
                initialValue={initialNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
                onClear={resetPhoneNumber}
                disabled={!isPhoneNumberEditable}
                className="w-full"
              />
            </div>
            <div className="flex-none">
              <IconCallButton
                startCall={callActions.startCall}
                disabled={!validNumberSelected}
                callWithoutInternetChoose={callWithoutInternetChoose}
                callingWithoutInternet={callingWithoutInternet}
                updateSetting={(useInternet) =>
                  updateSetting({
                    name: SettingName.CALL_WITHOUT_INTERNET,
                    value: useInternet ? 'active' : 'inactive',
                  })
                }
              />
            </div>
          </div>
        )}
        {phoneState === STATES.PHONE.INCOMING && (
          <IncomingCall
            onAccept={callActions.acceptCall}
            onReject={callActions.rejectCall}
            phoneNumber={formattedCallerNumber}
          />
        )}
        {phoneState === STATES.PHONE.ON_CALL && (
          <OnCall
            onHangUp={callActions.endCall}
            onMute={callActions.muteCall}
            isKeypadOpen={isDialerOpen}
            onKeypad={setIsDialerOpen}
            phoneNumber={onCallNumber}
          />
        )}
      </div>
      <div className="flex-grow relative w-full overflow-auto min-h-0">
        <ContactDisplay
          contact={contact}
          loading={matchContactloading}
          phoneConfigActions={phoneConfigActions}
          callingWithoutInternet={callingWithoutInternet}
        />
        {phoneState === STATES.PHONE.ON_CALL && isDialerOpen && (
          <div className="absolute inset-0 justify-center bg-white">
            <Dialer
              onDigitClick={padActions.onCallPadDigitClick}
              fourthRowKeys={['*', '0', '#']}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CompactPhone;
