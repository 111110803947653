import React from 'react';
import { Alert as MaterialAlert } from '@material-tailwind/react';

export type AlertType = 'success' | 'error' | 'warning';
interface AlertProps {
  message: string;
  type: AlertType;
}

const backgroundColor: Record<AlertType, string> = {
  success: 'bg-green-700',
  error: 'bg-red-700',
  warning: 'bg-yellow-700',
};
const fontColor: Record<AlertType, string> = {
  success: 'text-gray-100',
  error: 'text-gray-100',
  warning: 'text-gray-800',
};

const Alert: React.FC<AlertProps> = ({ message, type }) => {
  const classes = `rounded-none ${backgroundColor[type]} ${fontColor[type]}`;
  return <MaterialAlert className={classes}>{message}</MaterialAlert>;
};

export default Alert;
