// src/modules/shared/components/molecules/modals/modal/index.tsx

import React, { ReactNode } from 'react';
import { useModal } from 'src/modules/shared/hooks/modal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Button from '../../../atoms/buttons/button';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  dialogClassName?: string;
  headerClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  header,
  body,
  footer,
  dialogClassName = '',
  headerClassName = '',
  bodyClassName = '',
  footerClassName = '',
}) => {
  useModal({ isOpen, onClose });

  if (!isOpen) return null;

  const overlayClasses = `
    fixed inset-0 z-[100] flex items-center justify-center p-0 md:p-4 
    max-w-full max-h-full overflow-auto
  `;
  const backdropClasses = 'fixed inset-0 bg-black opacity-50';
  const dialogClasses = `
    relative bg-white rounded-lg shadow-xl flex flex-col max-w-full max-h-full 
    w-full sm:max-w-2xl sm:max-h-[80%]  ${dialogClassName}
  `;
  const headerClasses = `
    flex-none flex px-4 py-2 sm:py-3 items-center justify-between border-b ${headerClassName}
  `;
  const bodyClasses = `
    flex-1 flex px-2 md:py-2 md:px-4 overflow-auto ${bodyClassName}
  `;
  const footerClasses = `
    flex-none py-2 md:py-4 justify-end border-t ${footerClassName}
  `;

  return (
    <div className={overlayClasses}>
      <div
        className={backdropClasses}
        onClick={onClose}
        aria-hidden="true"
      ></div>

      <div className={dialogClasses}>
        {header && (
          <div className={headerClasses}>
            {header}
            <Button onClick={onClose} variant="plain" size="xs" className="">
              <XMarkIcon strokeWidth={2} className="h-6 w-6" />
            </Button>
          </div>
        )}

        {body && <div className={bodyClasses}>{body}</div>}

        {footer && <div className={footerClasses}>{footer}</div>}
      </div>
    </div>
  );
};

export default Modal;
