import {
  AnalysisConfigurableType,
  AnalysisValueView,
  ExtractionConfigKey,
} from 'src/api/generated';
import { getTrimmedDescription, getTrimmedValue } from '../analysisValues';

export const getExtractions = (
  analysisValues: AnalysisValueView[]
): AnalysisValueView[] => {
  return analysisValues.filter(isExtractionValue);
};

export const findExtractionByConfigKey = (
  configKey: ExtractionConfigKey,
  analysisValues: AnalysisValueView[]
): { found: AnalysisValueView | null; rest: AnalysisValueView[] } => {
  const extractions = getExtractions(analysisValues);

  const found = extractions.filter(
    (analysisValue) => analysisValue.configKey === configKey
  );
  const rest = extractions.filter(
    (analysisValue) => analysisValue.configKey !== configKey
  );

  if (found.length === 0) return { found: null, rest };
  return { found: found[0], rest };
};

export const getExtractionValue = (
  analysisValue: AnalysisValueView
): string | null => {
  if (!isExtractionValue(analysisValue)) return null;

  const trimmedValue = getTrimmedValue(analysisValue);
  // TODO (future improvement): consider using "ExtractionAnswerType" from the
  // "AnalysisExtraction" backend model to identify the type of the extraction value
  // instead of just returning the string value.
  return trimmedValue;
};

export const getExtractionDescription = (
  analysisValue: AnalysisValueView
): string | null => {
  if (!isExtractionValue(analysisValue)) return null;

  const trimmedDescription = getTrimmedDescription(analysisValue);
  if (trimmedDescription === null) return null;

  return trimmedDescription;
};

const isExtractionValue = (analysisValue: AnalysisValueView): boolean => {
  return (
    analysisValue.analysisConfigurableType ===
    AnalysisConfigurableType.ANALYSIS_EXTRACTION
  );
};
