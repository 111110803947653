import { PhoneArrowDownLeftIcon } from '@heroicons/react/24/solid';
import TitledTooltip from 'components/atoms/tooltips/titled-tooltip';

const NoInternetModeTooltip: React.FC = () => {
  // TODO: check when implementing i18n
  const title = 'Estás llamando sin internet';
  const description = `
    Todas las llamadas que realices se redirigirán a tu teléfono. Asegúrate que tu número de
    redirección personal esté configurado correctamente en el panel de usuarios.
  `;

  return (
    <TitledTooltip
      title={title}
      description={description}
      icon={<PhoneArrowDownLeftIcon className="h-5 w-5 text-blue-gray-600" />}
    >
      <PhoneArrowDownLeftIcon className="h-5 w-5 text-blue-600 hover:text-blue-300 cursor-pointer" />
    </TitledTooltip>
  );
};

export default NoInternetModeTooltip;
