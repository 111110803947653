// src/components/molecules/charts/base-agent-calls-chart/base-agent-calls-chart.tsx

import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import { Typography } from '@material-tailwind/react';
import { AGENT_COLORS } from 'src/utils/charts';
import { BaseAgentCallsChartProps } from 'src/types/call-summaries';
import { totalDatalabelPlugin } from '../utils';

const getChartOptions = () => ({
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: totalDatalabelPlugin,
  },
  scales: {
    y: { stacked: true },
    x: { stacked: true },
  },
});

const BaseAgentCallsChart: React.FC<BaseAgentCallsChartProps> = ({
  labels,
  agentPeriodsCallSummaries,
  chartTitle,
}) => {
  const chartData = useMemo(
    (): ChartData<'bar'> => ({
      labels,
      datasets: agentPeriodsCallSummaries.map((agentSummary) => {
        const summedCalls = agentSummary.effectiveCalls.map(
          (call, index) =>
            call +
            agentSummary.missedCalls[index] +
            agentSummary.voicemailCalls[index]
        );

        return {
          label: agentSummary.agentSummary.name,
          data: summedCalls,
          backgroundColor:
            AGENT_COLORS[agentSummary.agentSummary.id % AGENT_COLORS.length],
        };
      }),
    }),
    [agentPeriodsCallSummaries, labels]
  );

  const options = useMemo(() => getChartOptions(), []);

  return (
    <div>
      <Typography variant="h6" className="m-2 text-blue-600">
        {chartTitle}
      </Typography>
      <Bar options={options} data={chartData} />
    </div>
  );
};

export default BaseAgentCallsChart;
