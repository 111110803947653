import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { TableRow, TableCell, Tooltip } from '@mui/material';
import { AnalysisValueView } from 'src/api/generated';
import { getForbiddenWordValue } from 'src/utils/analysisValues/forbiddenWords/forbiddenWords';

const ForbiddenWordsRow = ({
  forbiddenWords,
}: {
  forbiddenWords: AnalysisValueView[];
}) => (
  <TableRow key="forbiddenWordsRow">
    <TableCell component="th" scope="row" className="w-44">
      <div className="flex flex-row justify-around">
        <Tooltip
          title={
            'Palabras o frases que se dijeron en la llamada, pero deberías intentar no decir.'
          }
          placement="right"
          PopperProps={{
            // workaround to avoid tooltip being hidden behind modal
            style: { zIndex: 10000 },
          }}
        >
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
        </Tooltip>
        <p>Evita decir esto</p>
      </div>
    </TableCell>
    <TableCell align="left">
      {forbiddenWords
        .map((forbiddenWord) => getForbiddenWordValue(forbiddenWord))
        .join(', ')}
    </TableCell>
  </TableRow>
);

export default ForbiddenWordsRow;
