// src/components/molecules/analysis/feedback-modal-content/weakness-row/weakness-row.tsx

import { TableRow, TableCell } from '@mui/material';
import { Chip } from '@material-tailwind/react';
import { AcademicCapIcon } from '@heroicons/react/24/outline';
import { getWeaknessValue } from 'src/utils/analysisValues/weaknesses/weaknesses';
import { AnalysisValueView } from 'src/api/generated';

const WEAKNESS_COLOR = 'light-blue';
// TODO: check when implementing i18n
const WEAKNESS_TITLE = 'Sugerencia';

const WeaknessRow = ({ weakness }: { weakness: AnalysisValueView }) => {
  const weaknessValue = getWeaknessValue(weakness);
  if (!weaknessValue) return null;

  return (
    <TableRow key={weakness.id}>
      <TableCell component="th" scope="row">
        <div className="flex flex-col gap-2 m-2 items-center md:items-start">
          <Chip
            variant="ghost"
            className="w-fit px-4 py-1.5"
            color={WEAKNESS_COLOR}
            size="sm"
            value={WEAKNESS_TITLE}
            icon={<AcademicCapIcon className="h-4 w-4" />}
          />
          <div className="text-justify">{weaknessValue}</div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default WeaknessRow;
