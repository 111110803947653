import React, { useState } from 'react';
import { Button } from '@material-tailwind/react';
import { WhatsAppSessionInfo } from 'hooks/integrations/facebook/types';
import {
  useInitFacebookSDK,
  useSetPostMessageListener,
  useStoreWhatsAppIntegration,
  useWhatsAppSignup,
} from 'hooks/integrations/facebook';
import InfoError from 'components/atoms/info-error';
import Loading from 'components/atoms/loading/loading';
import { formatDate } from 'src/modules/shared/utils/formatting/date.util';

const WhatsAppSignup: React.FC = () => {
  const [sessionInfo, setSessionInfo] = useState<WhatsAppSessionInfo | null>(
    null
  );

  useInitFacebookSDK();
  useSetPostMessageListener(setSessionInfo);

  const { loginResponse, launchWhatsAppSignup } = useWhatsAppSignup();

  const { loading, error, integration } = useStoreWhatsAppIntegration({
    sessionInfo,
    loginResponse,
  });

  const fbButtonClasses = `
    bg-[#1877f2] border-0 rounded-md text-white 
    font-sans font-bold text-xs px-4 py-3 max-w-md
  `;

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <InfoError error={error} />;
  }

  return (
    <div className="flex flex-col space-y-2">
      {integration && (
        <div className="mt-4">
          <h3 className="font-bold">Integración enlazada</h3>
          <p>Proveedor: {integration.providerName}</p>
          <p>Identificador: {integration.providerId}</p>
          <p>Activada en: {formatDate(integration.activatedAt)}</p>
        </div>
      )}
      <Button onClick={launchWhatsAppSignup} className={fbButtonClasses}>
        Conectar con Facebook
      </Button>
    </div>
  );
};

export default WhatsAppSignup;
