/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCrmIntegrationParams } from '../models/CreateCrmIntegrationParams';
import type { CreateTwilioCallSessionParams } from '../models/CreateTwilioCallSessionParams';
import type { CreateTwilioCallSessionResponse } from '../models/CreateTwilioCallSessionResponse';
import type { CrmIntegration } from '../models/CrmIntegration';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * @param requestBody
     * @returns any Ok
     * @throws ApiError
     */
    public static createCallSession(
        requestBody: CreateTwilioCallSessionParams,
    ): CancelablePromise<CreateTwilioCallSessionResponse | null> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/calls/call-sessions/twilio',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns CrmIntegration Ok
     * @throws ApiError
     */
    public static createIntegration(
        requestBody: CreateCrmIntegrationParams,
    ): CancelablePromise<CrmIntegration> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crm-integrations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

}
