import { FilterParam, generateFilterQueryString } from 'src/utils/queryParams';
import { ApiQueryParams, FunnelStatus, User } from 'src/api/generated';
import { FilterOption } from 'components/molecules/tables/filters';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearch } from './search';
import { useFilterAgent } from './users';

export const useOpportunitiesQueryParams = (
  agentsInFilter: User[],
  currentUser: User | null,
  selectedFunnelId: number | undefined
) => {
  const { selectedAgentId, sendAgentSelection } = useFilterAgent(
    agentsInFilter,
    currentUser
  );

  const filters: Record<string, FilterParam> = {};
  if (selectedFunnelId) {
    filters.funnelId = { value: selectedFunnelId };
  }
  if (selectedAgentId) {
    filters.userId = { value: selectedAgentId };
  }

  const filterBy = generateFilterQueryString(filters);
  const { searchQueryParams, sendSearch } = useSearch();

  const queryParams: ApiQueryParams = {
    filterBy,
    ...searchQueryParams,
  };

  return {
    queryParams,
    selectedAgentId,
    sendAgentSelection,
    sendSearch,
  };
};

export const useFilterOptions = (funnelStatuses: FunnelStatus[]) => {
  const generateFilterOptions = useCallback(() => {
    const filterOptions: FilterOption[] = funnelStatuses.map((funnelStatus) => {
      const statusColumn = {
        label: funnelStatus.name,
        filters: [{ column: 'funnelStatus', value: funnelStatus.id }],
      };

      return statusColumn;
    });
    // TODO: check when implementing i18n
    filterOptions.unshift({
      label: 'Todos',
      filters: [],
    });

    return filterOptions;
  }, [funnelStatuses]);

  const filterOptions = useMemo(generateFilterOptions, [generateFilterOptions]);
  const defaultSelectedFilter: number = filterOptions.length > 1 ? 1 : 0;
  const [currentFilterIndex, setCurrentFilterIndex] = useState<number>(
    defaultSelectedFilter
  );

  useEffect(() => {
    setCurrentFilterIndex(defaultSelectedFilter);
  }, [defaultSelectedFilter]);

  return { currentFilterIndex, setCurrentFilterIndex, filterOptions };
};
