/**
 * Represents the supported WhatsApp text styling options
 */
type WhatsAppStyle =
  | 'bold'
  | 'italic'
  | 'strikethrough'
  | 'monospace'
  | 'variable'
  | 'newline';

/**
 * Interface representing a styled text segment with content and applied styles.
 */
interface StyledText {
  content: string;
  styles: WhatsAppStyle[];
}

/**
 * Interface for style markers defining how WhatsApp formats text.
 * Each marker consists of:
 * - style: The type of formatting
 * - startDelimiter: The character(s) that begin the style
 * - endDelimiter: The character(s) that end the style
 */
interface StyleMarker {
  style: WhatsAppStyle;
  startDelimiter: string;
  endDelimiter: string;
}

/**
 * The variable replacer to use for variable styles.
 */
export const VARIABLE_REPLACER = '##';

/**
 * Defines WhatsApp's text formatting markers and their corresponding delimiters.
 */
const STYLE_MARKERS: readonly StyleMarker[] = [
  { style: 'bold', startDelimiter: '*', endDelimiter: '*' },
  { style: 'italic', startDelimiter: '_', endDelimiter: '_' },
  { style: 'strikethrough', startDelimiter: '~', endDelimiter: '~' },
  { style: 'monospace', startDelimiter: '```', endDelimiter: '```' },
  {
    style: 'variable',
    startDelimiter: VARIABLE_REPLACER,
    endDelimiter: VARIABLE_REPLACER,
  },
] as const;

/**
 * Checks if a style marker exists at the given position in text
 * @param text - The text to search within
 * @param position - The position to check for a marker
 * @returns The style marker if found, or null if not
 */
function findStyleMarkerAtPosition(
  text: string,
  position: number
): StyleMarker | null {
  return (
    STYLE_MARKERS.find((marker) =>
      text.startsWith(marker.startDelimiter, position)
    ) ?? null
  );
}

/**
 * Finds the position of the next style marker or newline in the text.
 * @param text - The text to search within
 * @param startPosition - The position to start searching from
 * @returns The position of the next marker or the end of the text
 */
function findNextMarkerPosition(text: string, startPosition: number): number {
  for (let position = startPosition; position < text.length; position++) {
    const nextMarker = findStyleMarkerAtPosition(text, position);
    const isNewline = text[position] === '\n';

    if (isNewline || nextMarker) return position;
  }
  return text.length;
}

/**
 * Processes a newline character in the text
 * @returns A styled text segment representing the newline
 */
function handleNewline(): StyledText {
  return { content: '\n', styles: ['newline'] };
}

/**
 * Finds the closing delimiter position for a style marker
 * @param text - The text to search within
 * @param startPosition - The position to start searching from
 * @param marker - The style marker to find the closing delimiter for
 * @returns The position of the closing delimiter
 */
function findClosingDelimiter(
  text: string,
  startPosition: number,
  marker: StyleMarker
): number {
  return text.indexOf(marker.endDelimiter, startPosition);
}

/**
 * Creates a styled text segment with the given content and active styles
 * @param content - The content of the segment
 * @param activeStyles - The active styles
 * @returns A styled text segment
 */
function createStyledSegment(
  content: string,
  activeStyles: Set<WhatsAppStyle>
): StyledText {
  return { content, styles: Array.from(activeStyles) };
}

/**
 * Handles the processing of a style marker in the text
 * @param text - The text to process
 * @param position - The position to start processing from
 * @param marker - The style marker to process
 * @param activeStyles - The active styles
 * @returns The new position and the processed segment
 */
function processStyleMarker(
  text: string,
  position: number,
  marker: StyleMarker,
  activeStyles: Set<WhatsAppStyle>
): {
  newPosition: number;
  segment?: StyledText;
} {
  const hasStyle = activeStyles.has(marker.style);

  if (hasStyle) {
    activeStyles.delete(marker.style);
    return { newPosition: position + marker.startDelimiter.length };
  }

  const closingPosition = findClosingDelimiter(
    text,
    position + marker.startDelimiter.length,
    marker
  );

  if (closingPosition !== -1) {
    activeStyles.add(marker.style);
    return { newPosition: position + marker.startDelimiter.length };
  }

  // Treat as regular text if no closing delimiter
  return {
    newPosition: position + 1,
    segment: createStyledSegment(text[position], activeStyles),
  };
}

/**
 * Processes a text segment until the next marker
 * @param text - The text to process
 * @param position - The position to start processing from
 * @param activeStyles - The active styles
 * @returns The new position and the processed segment
 */
function processTextUntilNextMarker(
  text: string,
  position: number,
  activeStyles: Set<WhatsAppStyle>
): {
  newPosition: number;
  segment?: StyledText;
} {
  const nextPosition = findNextMarkerPosition(text, position);
  const content = text.slice(position, nextPosition);

  return {
    newPosition: nextPosition,
    segment: content ? createStyledSegment(content, activeStyles) : undefined,
  };
}

/**
 * Builds styled segments from the text by parsing WhatsApp-style formatting
 * @param text - The text to parse
 * @returns An array of styled text segments
 */
function buildStyledSegments(text: string): StyledText[] {
  const segments: StyledText[] = [];
  let position = 0;
  const activeStyles = new Set<WhatsAppStyle>();

  while (position < text.length) {
    if (text[position] === '\n') {
      segments.push(handleNewline());
      position++;
      continue;
    }

    const matchedMarker = findStyleMarkerAtPosition(text, position);
    if (matchedMarker) {
      const { newPosition, segment } = processStyleMarker(
        text,
        position,
        matchedMarker,
        activeStyles
      );
      if (segment) segments.push(segment);
      position = newPosition;
      continue;
    }

    const { newPosition, segment } = processTextUntilNextMarker(
      text,
      position,
      activeStyles
    );
    if (segment) segments.push(segment);
    position = newPosition;
  }

  return segments;
}

/**
 * Parses a WhatsApp message and extracts styled text segments.
 * Handles the following WhatsApp formatting:
 * - *bold*
 * - _italic_
 * - ~strikethrough~
 * - ```monospace```
 * - ##variables##
 * - newlines
 *
 * @example
 * ```typescript
 * const segments = parseWhatsAppMessage("Hello *world* with _style_");
 * // Returns:
 * // [
 * //   { content: "Hello ", styles: [] },
 * //   { content: "world", styles: ['bold'] },
 * //   { content: " with ", styles: [] },
 * //   { content: "style", styles: ['italic'] }
 * // ]
 * ```
 */
export function parseWhatsAppMessage(message: string): StyledText[] {
  return buildStyledSegments(message);
}
