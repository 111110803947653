// src/components/agents-calls-monthly-chart/agents-calls-monthly-chart.tsx

import React from 'react';
import BaseAgentCallsChart from '../base-agent-calls-chart';
import { useAgentCallsData } from 'hooks/charts/agent-calls';
import { CallSummaries } from 'src/types/call-summaries';

const AgentCallsMonthlyChart: React.FC<CallSummaries> = ({ summaries }) => {
  const chartData = useAgentCallsData(summaries, 'monthly');

  return (
    <BaseAgentCallsChart
      labels={chartData?.labels || []}
      agentPeriodsCallSummaries={chartData?.agentPeriodsCallSummaries || []}
      // TODO: check when implementing i18n
      chartTitle="Llamadas Mensuales por Agente"
    />
  );
};

export default AgentCallsMonthlyChart;
