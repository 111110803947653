import { useEffect } from 'react';
import { useCurrentUser } from './users';
import ErrorReporter from 'src/monitoring/errorReporter';

export const useSetErrorReporterUserContext = (): void => {
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    if (currentUser) {
      ErrorReporter.setUserContext(currentUser);
    } else {
      ErrorReporter.setUserContext(null);
    }
  }, [currentUser]);
};
