// src/hooks/analysis/contacts.ts

import { useMemo } from 'react';
import { ContactWithCalls } from 'src/api/generated';
import { useAnalysisValueViews } from '../api/analysis-values/analysis-value-views';
import { FilterParam, generateFilterQueryString } from 'src/utils/queryParams';

export const useAnalysisValuesFromContacts = (contacts: ContactWithCalls[]) => {
  const callsIds = useMemo(() => getCallsIdsFromContacts(contacts), [contacts]);

  const filters: Record<string, FilterParam> = {
    callId: { value: callsIds },
  };
  const filterBy = generateFilterQueryString(filters);
  return useAnalysisValueViews({ filterBy });
};

const getCallsIdsFromContacts = (contacts: ContactWithCalls[]): number[] => {
  const getContactCallsIds = (contact: ContactWithCalls) => {
    const contactCalls = contact.calls;
    if (!contactCalls) return [];
    return contactCalls.map((call) => call.id);
  };

  const contactCallsIds = contacts.map(getContactCallsIds);
  const callsIds = contactCallsIds.flat();
  return callsIds;
};
