export const formatFullName = (
  firstName?: string | null,
  lastName?: string | null
) => {
  const first = formatName(firstName);
  const last = formatName(lastName);
  return `${first} ${last}`.trim();
};

export const formatName = (name?: string | null) => {
  return name?.trim() || '';
};
