// src/hooks/charts/call-summaries.ts

import { useMemo } from 'react';
import {
  TimeFrame,
  formatDateLabel,
  getMonthName,
  getDateRange,
  getStartOfWeek,
} from 'src/utils/date';
import { DailyCallSummary } from 'src/api/generated';
import {
  CallSummaryChartData,
  PeriodCallSummary,
} from 'src/types/call-summaries';
import { formatWeeklyLabel, periodLengths } from 'src/utils/charts';

export const useCallSummaryData = (
  summaries: DailyCallSummary[],
  timeFrame: TimeFrame
): CallSummaryChartData => {
  const { startDate, endDate } = useMemo(() => {
    return getDateRange(timeFrame, periodLengths[timeFrame]);
  }, [timeFrame]);

  const filteredSummaries = useMemo(
    () =>
      summaries.filter((summary) => {
        const summaryDay = new Date(summary.day);
        return summaryDay >= startDate && summaryDay <= endDate;
      }),
    [summaries, startDate, endDate]
  );

  const chartData = useMemo(
    () => processSummaries(timeFrame, filteredSummaries),
    [filteredSummaries, timeFrame]
  );

  return chartData;
};

const processSummaries = (
  timeFrame: TimeFrame,
  summaries: DailyCallSummary[]
): CallSummaryChartData => {
  const processingFunctions = {
    daily: processSummariesDaily,
    weekly: processSummariesWeekly,
    monthly: processSummariesMonthly,
  };
  const processingFunction = processingFunctions[timeFrame];

  return processingFunction(summaries);
};

const processSummariesDaily = (
  summaries: DailyCallSummary[]
): CallSummaryChartData => ({
  labels: summaries.map((summary) => formatDateLabel(summary.day)),
  effectiveCalls: summaries.map((data) => data.totalEffectiveCalls),
  missedCalls: summaries.map((data) => data.totalMissedCalls),
  voicemailCalls: summaries.map((data) => data.totalVoicemailCalls),
});

const aggregateSummaries = (
  summaries: DailyCallSummary[],
  getDateKey: (date: string) => string
) => {
  const summaryData: Record<string, PeriodCallSummary> = {};

  summaries.forEach((summary) => {
    const key = getDateKey(summary.day);
    const data = summaryData[key] || {
      totalEffectiveCalls: 0,
      totalMissedCalls: 0,
      totalVoicemailCalls: 0,
    };

    data.totalEffectiveCalls += summary.totalEffectiveCalls;
    data.totalMissedCalls += summary.totalMissedCalls;
    data.totalVoicemailCalls += summary.totalVoicemailCalls;
    summaryData[key] = data;
  });

  return Object.keys(summaryData).map((key) => ({
    label: key,
    ...summaryData[key],
  }));
};

const processSummariesWeekly = (
  summaries: DailyCallSummary[]
): CallSummaryChartData => {
  const currentWeekStart = getStartOfWeek(new Date()).toISOString();
  const weeklyData = aggregateSummaries(summaries, (date) =>
    getStartOfWeek(new Date(date)).toISOString()
  );

  return {
    labels: weeklyData.map((data) =>
      formatWeeklyLabel(data.label, currentWeekStart)
    ),
    effectiveCalls: weeklyData.map((data) => data.totalEffectiveCalls),
    missedCalls: weeklyData.map((data) => data.totalMissedCalls),
    voicemailCalls: weeklyData.map((data) => data.totalVoicemailCalls),
  };
};

const processSummariesMonthly = (
  summaries: DailyCallSummary[]
): CallSummaryChartData => {
  const currentDate = new Date();
  const currentMonthKey = currentDate.toISOString().slice(0, 7);

  const initialMonthSummaries = Array.from({ length: periodLengths.monthly })
    .map((_, index) => {
      const monthDate = new Date();
      monthDate.setMonth(monthDate.getMonth() - index, 1);
      const monthKey = monthDate.toISOString().slice(0, 7);

      const label =
        getMonthName(monthDate.getMonth()) +
        (monthKey === currentMonthKey ? ' (Actual)' : '');

      return {
        label,
        totalEffectiveCalls: 0,
        totalMissedCalls: 0,
        totalVoicemailCalls: 0,
        monthKey,
      };
    })
    .reverse();

  const aggregatedSummaries = aggregateSummaries(summaries, (date) =>
    new Date(date).toISOString().slice(0, 7)
  );

  const transformedAggregatedData = aggregatedSummaries.map((summary) => ({
    ...summary,
    monthKey: summary.label,
  }));

  const mergedData = initialMonthSummaries.map((monthSummary) => {
    const actualData = transformedAggregatedData.find(
      (data) => data.monthKey === monthSummary.monthKey
    );
    if (actualData) {
      return {
        ...actualData,
        label: monthSummary.label,
      };
    }
    return monthSummary;
  });

  return {
    labels: mergedData.map((data) => data.label),
    effectiveCalls: mergedData.map((data) => data.totalEffectiveCalls),
    missedCalls: mergedData.map((data) => data.totalMissedCalls),
    voicemailCalls: mergedData.map((data) => data.totalVoicemailCalls),
  };
};
