/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Opportunity } from '../models/Opportunity';
import type { Partial_Opportunity_ } from '../models/Partial_Opportunity_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OpportunitiesService {

    /**
     * @param filterBy Filter criteria in the format "field:value;field2<value2;field3:[value3,value4,value5]" for exact matches.
     * @param searchBy Search criteria in the format "name lastname" for partial matches.
     * @param sortBy Sorting criteria in the format "field:asc;field2:desc".
     * @param page The page number in pagination.
     * @param pageSize The number of items per page in pagination.
     * @returns Opportunity Ok
     * @throws ApiError
     */
    public static getOpportunities(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<Array<Opportunity>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/opportunities',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Opportunity Ok
     * @throws ApiError
     */
    public static updateOpportunity(
        id: number,
        requestBody: Partial_Opportunity_,
    ): CancelablePromise<Opportunity> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/opportunities/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized.`,
                404: `Resource not found.`,
            },
        });
    }

}
