import React from 'react';
import { Template } from 'src/api/generated';
import { Typography } from '@material-tailwind/react';
import { TemplateSuggestionItem } from './template-suggestion-item';
import { SuggestionButton } from 'src/modules/shared/components/molecules/suggestions/suggestion-button';
import { SuggestionsPanel } from 'src/modules/shared/components/molecules/suggestions/suggestions-panel';

interface TemplateSuggestionsProps {
  suggestions: Template[];
  selectedIndex: number;
  loading: boolean;
  hasMorePages: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSelect: (template: Template) => void;
  onLoadMore: () => void;
  onCreateTemplate: () => void;
}

const TemplateSuggestions: React.FC<TemplateSuggestionsProps> = ({
  suggestions,
  selectedIndex,
  loading,
  hasMorePages,
  isOpen,
  onSelect,
  onLoadMore,
  onCreateTemplate,
}) => {
  const renderEmpty = () => (
    <div className="p-2">
      <Typography
        variant="small"
        color="gray"
        className="text-center py-4 text-xs"
      >
        {/* TODO: check when implementing i18n */}
        No se encontraron plantillas
      </Typography>
      <SuggestionButton
        onClick={onCreateTemplate}
        // TODO: check when implementing i18n
        label="Nueva plantilla"
        size="xs"
      />
    </div>
  );

  const renderFooter = () => (
    <div className="p-1">
      <SuggestionButton
        onClick={onCreateTemplate}
        // TODO: check when implementing i18n
        label="Nueva plantilla"
        size="xs"
      />
    </div>
  );

  return (
    <SuggestionsPanel
      items={suggestions}
      position="top"
      selectedIndex={selectedIndex}
      loading={loading}
      hasMore={hasMorePages}
      isOpen={isOpen}
      onLoadMore={onLoadMore}
      renderItem={(template, isSelected) => (
        <TemplateSuggestionItem
          key={template.id}
          template={template}
          isSelected={isSelected}
          onClick={() => onSelect(template)}
        />
      )}
      renderEmpty={renderEmpty}
      renderFooter={renderFooter}
      className="max-h-[70vh]"
    />
  );
};

export default TemplateSuggestions;
