import { Typography } from '@material-tailwind/react';
import React from 'react';
import { WhatsAppTemplate } from 'src/api/generated';
import { translateTemplateValues } from 'src/modules/messaging/whatsapp/utils/template.util';

interface TemplateInfoProps {
  template: WhatsAppTemplate;
}

const TemplateInfo: React.FC<TemplateInfoProps> = ({ template }) => (
  <div className="space-y-4">
    {/* TODO: check when implementing i18n */}
    <Typography variant="lead" className="mx-3">
      Detalles de la plantilla
    </Typography>
    <div className="py-4 px-8 bg-gray-100 rounded-lg shadow-sm">
      <table className="min-w-full">
        <tbody className="divide-y divide-gray-200">
          <tr>
            {/* TODO: check when implementing i18n */}
            <td className="py-2 text-sm font-medium text-gray-500">Nombre</td>
            <td className="py-2 text-sm text-gray-600">
              {translateTemplateValues('name', template.name)}
            </td>
          </tr>
          <tr>
            {/* TODO: check when implementing i18n */}
            <td className="py-2 text-sm font-medium text-gray-500">Idioma</td>
            <td className="py-2 text-sm text-gray-600">
              {translateTemplateValues('language', template.language)}
            </td>
          </tr>
          <tr>
            {/* TODO: check when implementing i18n */}
            <td className="py-2 text-sm font-medium text-gray-500">Estado</td>
            <td className="py-2 text-sm text-gray-600">
              {translateTemplateValues('status', template.status)}
            </td>
          </tr>
          <tr>
            {/* TODO: check when implementing i18n */}
            <td className="py-2 text-sm font-medium text-gray-500">
              Categoría
            </td>
            <td className="py-2 text-sm text-gray-600">
              {translateTemplateValues('category', template.category)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default TemplateInfo;
