import { Spinner, Typography } from '@material-tailwind/react';

interface MessageLoadingProps {
  message?: string;
  darkMode?: boolean;
}

const MessageLoading: React.FC<MessageLoadingProps> = ({
  // TODO: check when implementing i18n
  message = 'Cargando',
  darkMode = false,
}) => {
  const textColor = darkMode ? 'text-white-400' : 'text-blue-400';

  return (
    <div className="flex flex-1 flex-row gap-4 items-center justify-center min-w-[300px] p-6">
      <Typography className={`text-xl ${textColor}`}>{message}</Typography>
      <Spinner color="blue" />
    </div>
  );
};

export default MessageLoading;
