// src/components/atoms/tooltips/styled-tooltip/index.tsx

import React from 'react';
import { Tooltip } from '@material-tailwind/react';

interface StyledTooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
}

const StyledTooltip: React.FC<StyledTooltipProps> = ({
  content,
  children,
  placement = 'top',
  className = '',
}) => {
  const classes = `
  border border-blue-gray-100 bg-white px-4 py-3 shadow-2xl 
  ${className}
  `;
  return (
    <Tooltip content={content} placement={placement} className={classes}>
      {children}
    </Tooltip>
  );
};

export default StyledTooltip;
