import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import TextInput from 'src/modules/shared/components/atoms/inputs/text-input';
import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  onSearch: () => void;
  placeholder?: string;
  size?: ComponentSize;
  variant?: ComponentVariant;
  className?: string;
  error?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  onSearch,
  // TODO: check when implementing i18n
  placeholder = 'Buscar...',
  size = 'md',
  variant = 'primary',
  className = '',
  error,
}) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const baseClasses = [
    'flex',
    'items-center',
    'w-full',
    'max-w-md',
    className,
  ].join(' ');

  const iconSizeClasses: Record<ComponentSize, string> = {
    lg: 'h-5 w-5',
    md: 'h-4 w-4',
    sm: 'h-4 w-4',
    xs: 'h-3 w-3',
    '2xs': 'h-3 w-3',
  };

  return (
    <div className={baseClasses}>
      <div className="relative flex-1">
        <TextInput
          variant={variant}
          size={size}
          error={error}
          className="rounded-r-none border-r-0"
          refInputProps={{
            value,
            onChange: (e) => onChange(e.target.value),
            onKeyDown: handleKeyDown,
            placeholder,
          }}
        />
      </div>
      <Button
        variant={variant}
        size={size}
        onClick={onSearch}
        className="shrink-0 rounded-l-none"
      >
        <MagnifyingGlassIcon className={iconSizeClasses[size]} />
      </Button>
    </div>
  );
};

export default SearchInput;
