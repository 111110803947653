// src/contexts/timezone-sync-provider.tsx

import { useSyncTimezone } from 'hooks/date';
import React, { ReactNode } from 'react';

interface TimezoneProviderProps {
  children: ReactNode;
}

const TimezoneProvider: React.FC<TimezoneProviderProps> = ({ children }) => {
  useSyncTimezone();

  return <>{children}</>;
};

export default TimezoneProvider;
