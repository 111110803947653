/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Interface representing a conversation status DTO.
 */
export enum ConversationStatus {
    OPEN = 'open',
    CLOSED = 'closed',
    ARCHIVED = 'archived',
    PENDING = 'pending',
}
