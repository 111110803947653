/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type representing a message direction.
 */
export enum MessageDirection {
    INBOUND = 'inbound',
    OUTBOUND = 'outbound',
}
