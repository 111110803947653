import { Card, CardBody, Typography } from '@material-tailwind/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { AnalysisValueView } from 'src/api/generated';
import {
  getScoreDescription,
  getScoreEvidence,
  getScoreMaxValue,
  getScoreValue,
} from 'src/utils/analysisValues/scores/scores';
import { Tooltip } from '@mui/material';

const ScoreRow = ({ scores }: { scores: AnalysisValueView[] }) => {
  return (
    <tr>
      {scores.map((score) => {
        const scoreValue = getScoreValue(score);
        const scoreMaxValue = getScoreMaxValue(score);

        const noValue = scoreValue === null || scoreMaxValue === null;
        const maxValueAchieved = !noValue && scoreValue === scoreMaxValue;
        const scoreText = !noValue ? `${scoreValue} / ${scoreMaxValue}` : 'N/A';

        return (
          <td key={score.id} className="th">
            <Card className="mx-4 my-2 bg-gray-100">
              <CardBody className="p-2 flex-grow">
                <Typography className="text-center">
                  {getScoreDescription(score)}
                </Typography>
                <div className="flex flex-row justify-center items-center">
                  <Typography
                    variant="h6"
                    className={`mr-1 ${maxValueAchieved ? 'text-green-500' : 'text-amber-500'}`}
                  >
                    {scoreText}
                  </Typography>
                  <Tooltip
                    title={getScoreEvidence(score)}
                    placement="right"
                    PopperProps={{
                      // workaround to avoid tooltip being hidden behind modal
                      style: { zIndex: 10000 },
                    }}
                  >
                    <InformationCircleIcon className="h-5 w-5" />
                  </Tooltip>
                </div>
              </CardBody>
            </Card>
          </td>
        );
      })}
      {scores.length % 2 !== 0 && <td />}
    </tr>
  );
};

export default ScoreRow;
