import Icons from 'components/atoms/icons';

function Footer() {
  return (
    <footer className="bg-gray-800 text-gray-300 py-6 absolute bottom-0 left-0 right-0">
      <div className="max-w-screen-lg xl:max-w-screen-xl mx-auto flex justify-between items-center">
        <p className="text-center sm:text-left">
          LlamadaPro @ {new Date().getFullYear()}
        </p>
        <div className="space-x-4 flex items-center">
          <a href="https://facebook.com" className="hover:underline">
            <Icons.Facebook />
          </a>
          <a href="https://twitter.com" className="hover:underline">
            <Icons.Twitter />
          </a>
          <a href="https://linkedin.com" className="hover:underline">
            <Icons.LinkedIn />
          </a>
          <a href="https://instagram.com" className="hover:underline">
            <Icons.Instagram />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
