import React from 'react';
import { PlusIcon } from '@heroicons/react/24/solid';
import Button from '../../../atoms/buttons/button';
import { ComponentSize } from 'src/modules/shared/types/component.type';

interface SuggestionButtonProps {
  onClick: () => void;
  label: string;
  className?: string;
  icon?: React.ReactNode;
  size?: ComponentSize;
}

export const SuggestionButton: React.FC<SuggestionButtonProps> = ({
  onClick,
  label,
  className = '',
  icon = <PlusIcon className="h-4 w-4" />,
  size = 'md',
}) => {
  return (
    <Button
      onClick={onClick}
      variant="secondary"
      size={size}
      className={`w-full flex items-center justify-center gap-2 ${className}`}
    >
      {icon}
      {label}
    </Button>
  );
};
