// src/components/views/reports-view/basic-reports.tsx

import CallsSummaryDailyChart from 'components/molecules/charts/calls-summary-daily-chart';
import CallsSummaryWeeklyChart from 'components/molecules/charts/calls-summary-weekly-chart';
import AgentCallsDailyChart from 'components/molecules/charts/agent-calls-daily-chart';
import AgentCallsWeeklyChart from 'components/molecules/charts/agent-calls-weekly-chart';
import CallsSummaryMonthlyChart from 'components/molecules/charts/calls-summary-monthly-chart';
import AgentCallsMonthlyChart from 'components/molecules/charts/agent-calls-monthly-chart';
import { DailyCallSummary } from 'src/api/generated';

interface BasicReportsProps {
  summaries: DailyCallSummary[];
}

const BasicReports: React.FC<BasicReportsProps> = ({ summaries }) => {
  return (
    <div className="h-full grid lg:grid-cols-2 md:grid-cols-1">
      <div className="p-4">
        <CallsSummaryDailyChart summaries={summaries} />
      </div>
      <div className="p-4">
        <AgentCallsDailyChart summaries={summaries} />
      </div>
      <div className="p-4">
        <CallsSummaryWeeklyChart summaries={summaries} />
      </div>
      <div className="p-4">
        <AgentCallsWeeklyChart summaries={summaries} />
      </div>
      <div className="p-4">
        <CallsSummaryMonthlyChart summaries={summaries} />
      </div>
      <div className="p-4">
        <AgentCallsMonthlyChart summaries={summaries} />
      </div>
    </div>
  );
};

export default BasicReports;
