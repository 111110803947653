import React, { useEffect } from 'react';
import LabelSelect from 'src/modules/shared/components/atoms/inputs/label-select';
import { WhatsAppTemplate } from 'src/api/generated';

interface TemplateSelectProps {
  templates: WhatsAppTemplate[];
  selectedTemplateId?: string;
  onTemplateChange: (templateId?: string) => void;
}

const TemplateSelect: React.FC<TemplateSelectProps> = ({
  templates,
  selectedTemplateId,
  onTemplateChange,
}) => {
  useEffect(() => {
    if (templates.length > 0 && !selectedTemplateId) {
      onTemplateChange(templates[0].id);
    }
  }, [templates, onTemplateChange, selectedTemplateId]);

  return (
    <LabelSelect
      // TODO: check when implementing i18n
      label="Plantilla"
      size="sm"
      options={templates.map((template) => ({
        content: template.name,
        value: template.id,
      }))}
      selectProps={{
        value: selectedTemplateId,
        onChange: (event: React.ChangeEvent<HTMLSelectElement>) => {
          onTemplateChange(event.target.value);
        },
      }}
    />
  );
};

export default TemplateSelect;
