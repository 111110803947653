import ErrorReporter from 'src/monitoring/errorReporter';

export const equalsInsensitive = (text1: string, text2: string) => {
  const collator = new Intl.Collator('es', {
    sensitivity: 'base',
  });
  return collator.compare(text1, text2) === 0;
};

export const equalsInsensitiveUnspaced = (text1: string, text2: string) => {
  const collator = new Intl.Collator('es', {
    sensitivity: 'base',
  });
  const unspacedText1 = text1.replace(/\s/g, '');
  const unspacedText2 = text2.replace(/\s/g, '');
  return collator.compare(unspacedText1, unspacedText2) === 0;
};

/**
 * Removes accents and converts a string to lower case.
 *
 * @param str - The string to normalize.
 * @returns - The normalized string.
 */
export const normalizeString = (str: string): string => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

/**
 * Normalizes the keys of an object, including nested objects.
 *
 * @param obj - The object to normalize.
 * @returns - A new object with normalized keys.
 */
export const normalizeObject = (
  obj: Record<string, any>
): Record<string, any> => {
  const normalizedObj: any = {};

  for (const [key, value] of Object.entries(obj)) {
    const normalizedKey = normalizeString(key);
    normalizedObj[normalizedKey] =
      value && typeof value === 'object' && !Array.isArray(value)
        ? normalizeObject(value)
        : value;
  }

  return normalizedObj;
};

/**
 * Adds pad to number.
 *
 * @param number - The number to pad.
 * @param maxLength - The maximum length of the string.
 * @returns - The padded string.
 *
 * Examples:
 * padNumber(1, 3) // '001'
 * padNumber(10, 3) // '010'
 */
export const padNumber = (number: number, maxLength: number) => {
  return number.toString().padStart(maxLength, '0');
};

export const safeParseStringArray = (rawInput: string): string[] => {
  if (!rawInput) return [];

  try {
    const parsed = JSON.parse(rawInput);

    if (Array.isArray(parsed)) {
      return parsed.every((element) => typeof element === 'string')
        ? parsed
        : [];
    }
  } catch (error) {
    ErrorReporter.sendException({
      message: 'Failed to parse the input string.',
      error,
    });
  }
  return [];
};
