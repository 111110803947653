import { Device } from '@twilio/voice-sdk';
import { useEffect, useState } from 'react';

export interface AudioPhoneConfigActions {
  selectedInputDevice: string;
  setSelectedInputDevice: (deviceId: string) => void;
  selectedOutputDevice: string;
  setSelectedOutputDevice: (deviceId: string) => void;
}

export const useAvailableAudioDevices = () => {
  const [inputDevices, setInputDevices] = useState<MediaDeviceInfo[]>([]);
  const [outputDevices, setOutputDevices] = useState<MediaDeviceInfo[]>([]);

  useEffect(() => {
    const setAudioDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const availableInputDevices = devices.filter(
        (device) => device.kind === 'audioinput'
      );
      const availableOutputDevices = devices.filter(
        (device) => device.kind === 'audiooutput'
      );
      setInputDevices(availableInputDevices);
      setOutputDevices(availableOutputDevices);
    };

    setAudioDevices();
    navigator.mediaDevices.addEventListener('devicechange', setAudioDevices);

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        setAudioDevices
      );
    };
  }, []);

  return {
    inputDevices,
    outputDevices,
  };
};

export const useSelectAudioDevices = (
  device: Device | null
): AudioPhoneConfigActions => {
  const [selectedInputDevice, setSelectedInputDevice] = useState('');
  const [selectedOutputDevice, setSelectedOutputDevice] = useState('');

  useEffect(() => {
    if (device && selectedInputDevice) {
      device.audio?.setInputDevice(selectedInputDevice).catch(console.error);
    }
    if (device && selectedOutputDevice) {
      device.audio?.speakerDevices
        .set(selectedOutputDevice)
        .catch(console.error);
    }
  }, [device, selectedInputDevice, selectedOutputDevice]);

  return {
    selectedInputDevice,
    setSelectedInputDevice,
    selectedOutputDevice,
    setSelectedOutputDevice,
  };
};
