/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type for text parameter type.
 */
export enum WhatsAppTemplateMessageTextParameterType {
    TEXT = 'text',
}
