import { Outlet } from '@tanstack/react-router';
import Footer from 'components/molecules/footer';
import Navbar from 'components/molecules/navbar';

function UnauthenticatedApp() {
  return (
    <div className="relative min-h-screen">
      <Navbar />
      <main className="w-full">
        <section className="flex">
          <div className="flex-1 min-w-0 overflow-auto pt-40">
            <Outlet />
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default UnauthenticatedApp;
