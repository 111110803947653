// src/components/molecules/charts/base-calls-summary-chart/base-calls-summary-chart.tsx

import React from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import { Typography } from '@material-tailwind/react';
import { BaseCallsSummaryChartProps } from 'src/types/call-summaries';
import { totalDatalabelPlugin } from '../utils';

const BaseCallsSummaryChart: React.FC<BaseCallsSummaryChartProps> = ({
  labels,
  effectiveCalls,
  missedCalls,
  voicemailCalls,
  chartTitle,
}) => {
  const data: ChartData<'bar'> = {
    labels,
    // TODO: check when implementing i18n
    datasets: [
      {
        label: 'Llamadas Efectivas',
        data: effectiveCalls,
        backgroundColor: '#033663',
      },
      {
        label: 'Llamadas Perdidas',
        data: missedCalls,
        backgroundColor: '#EECA86',
      },
      {
        label: 'Buzón de Voz',
        data: voicemailCalls,
        backgroundColor: '#E9AB11',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: totalDatalabelPlugin,
    },
    scales: {
      y: {
        stacked: true,
      },
      x: {
        stacked: true,
      },
    },
  };

  return (
    <div>
      <Typography variant="h6" className="m-2 text-blue-600">
        {chartTitle}
      </Typography>
      <Bar options={options} data={data} />
    </div>
  );
};

export default BaseCallsSummaryChart;
