import LoginButton from 'components/molecules/buttons/login-button';
import LogoutButton from 'components/molecules/buttons/logout-button';
import { useAuth0 } from '@auth0/auth0-react';

function Navbar() {
  const { isAuthenticated } = useAuth0();

  return (
    <nav className="bg-blue-600 p-4 text-white absolute top-0 left-0 right-0">
      <div className="flex max-w-screen-xl mx-auto justify-between items-center">
        <div className="flex items-center">
          <h1 className="text-2xl font-bold mr-2">LlamadaPro</h1>
        </div>
        <div className="space-x-4">
          {isAuthenticated ? <LogoutButton /> : <LoginButton />}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
