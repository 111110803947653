import { useCallback, useEffect, useState } from 'react';
import {
  FacebookLoginResponse,
  FBLoginOptions,
  PostMessageEvent,
  WhatsAppSessionInfo,
} from './types';
import { useCreateWhatsAppIntegration } from 'src/modules/messaging/whatsapp/hooks/api/integrations';
import {
  Integration,
  WhatsAppIntegrationCreateBodyParams as WhatsAppIntegrationCreateParams,
} from 'src/api/generated';
import { useAlert } from 'src/contexts/alert-context';

const APP_ID = '542019688162818';
const CONFIG_ID = '3940031726317726';

export const useInitFacebookSDK = () => {
  useEffect(() => {
    const loadFacebookSDK = () => {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: APP_ID,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v21.0',
        });
      };

      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        const js = d.createElement(s) as HTMLScriptElement;
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode?.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    };

    loadFacebookSDK();
  }, []);
};

export const useSetPostMessageListener = (
  onSessionInfoReceived: (sessionInfo: WhatsAppSessionInfo) => void
) => {
  const handleMessageEvent = useCallback(
    (event: MessageEvent) => {
      const comesFromFacebook = event.origin.endsWith('facebook.com');
      if (!comesFromFacebook) return;

      try {
        const data: PostMessageEvent = JSON.parse(event.data);
        console.log({ data });
        if (data.type !== 'WA_EMBEDDED_SIGNUP') return;

        console.log('|| WA_EMBEDDED_SIGNUP ||');
        if (data.event === 'FINISH') {
          // TODO: alert explaining
          onSessionInfoReceived({
            phone_number_id: data.data.phone_number_id!,
            waba_id: data.data.waba_id!,
          });
        } else if (data.event === 'CANCEL') {
          // TODO: alert explaining
          console.warn('Signup canceled at step:', data.data.current_step);
        } else if (data.event === 'ERROR') {
          // TODO: alert explaining
          console.error('Signup error:', data.data.error_message);
        } else {
          console.log('Unhandled event:', data.event);
        }
      } catch (err) {
        console.log('Non-JSON response:', event.data);
      }
    },
    [onSessionInfoReceived]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent);
    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [handleMessageEvent]);
};

export const useWhatsAppSignup = () => {
  const [loginResponse, setLoginResponse] =
    useState<FacebookLoginResponse | null>(null);

  const launchWhatsAppSignup = useCallback(() => {
    const fbLoginOptions: FBLoginOptions = {
      config_id: CONFIG_ID,
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        feature: 'whatsapp_embedded_signup',
        sessionInfoVersion: '3',
      },
    };

    window.FB.login((response: FacebookLoginResponse) => {
      if (response.authResponse) {
        setLoginResponse(response);
        console.log({ response });
        console.log(
          'Facebook login successful, code:',
          response.authResponse.code
        );
        // TODO: send this authResponse or code to your backend
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, fbLoginOptions);
  }, []);

  return {
    loginResponse,
    launchWhatsAppSignup,
  };
};

interface UseStoreWhatsAppIntegrationProps {
  sessionInfo: WhatsAppSessionInfo | null;
  loginResponse: FacebookLoginResponse | null;
}

interface UseStoreWhatsAppIntegrationReturn {
  loading: boolean;
  error: Error | null;
  integration: Integration | null;
}

/**
 * Hook to store the WhatsApp integration in the database.
 */
export const useStoreWhatsAppIntegration = ({
  sessionInfo,
  loginResponse,
}: UseStoreWhatsAppIntegrationProps): UseStoreWhatsAppIntegrationReturn => {
  const { showAlert } = useAlert();
  const onError = () => {
    showAlert('Error al conectar con WhatsApp', 'error');
  };
  const onSuccess = () => {
    showAlert('Integración con WhatsApp creada correctamente', 'success');
  };

  const { loading, error, integration, createWhatsAppIntegration } =
    useCreateWhatsAppIntegration(onSuccess, onError);

  useEffect(() => {
    if (!sessionInfo || !loginResponse) return;

    const code = loginResponse.authResponse?.code;
    if (!code) return;

    const newWhatsAppIntegration: WhatsAppIntegrationCreateParams = {
      phoneNumberId: sessionInfo.phone_number_id,
      wabaId: sessionInfo.waba_id,
      code: code,
    };
    createWhatsAppIntegration(newWhatsAppIntegration);
  }, [createWhatsAppIntegration, loginResponse, sessionInfo]);

  return {
    loading,
    error,
    integration: integration ?? null,
  };
};
