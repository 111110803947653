import { useEffect, useState } from 'react';
import { Team, User } from 'src/api/generated';

// TODO: workaround to default multi teams because of lack of support for teams with multiple phones.
// 17 and 18 are meant to be Sales team 1 and 2 of tenant "unlock"
const HARDCODED_TEAMS = [17, 18];

export const useFilterTeams = (teams: Team[], currentUser: User | null) => {
  const [selectedTeams, setSelectedTeams] = useState<number[]>([]);

  useEffect(() => {
    const exists =
      !!currentUser &&
      !!currentUser.teamId &&
      teams.some((team) => team.id === currentUser.teamId);

    if (exists) {
      if (HARDCODED_TEAMS.includes(currentUser.teamId!)) {
        setSelectedTeams(HARDCODED_TEAMS);
        return;
      }
      setSelectedTeams([currentUser.teamId!]);
    }
  }, [currentUser, teams]);

  return { selectedTeams, setSelectedTeams };
};
