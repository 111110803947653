import { Link } from '@tanstack/react-router';
import React from 'react';

type SidebarButtonProps = {
  to: string;
  icon?: JSX.Element;
  label: string;
  hidden?: boolean;
  disabled?: boolean;
  className?: string;
};

const baseClasses = `
  select-none disabled:pointer-events-none transition-all
  focus:opacity-[0.85] active:opacity-[0.85]
  disabled:opacity-50 disabled:shadow-none
  focus:shadow-none active:shadow-none shadow-none drop-shadow-none
  shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20
  flex space-x-2 w-full py-3 px-4 rounded-lg
  bg-transparent hover:bg-blue-400
  font-sans font-bold text-center text-white normal-case text-md
`;

const SidebarButton: React.FC<SidebarButtonProps> = ({
  to,
  icon,
  label,
  hidden = false,
  disabled = false,
  className = '',
}) => {
  if (hidden) return null;

  return (
    <Link to={to} className="flex" disabled={disabled}>
      {({ isActive }: { isActive: boolean }) => {
        const activeClasses = isActive
          ? '!bg-white text-blue-600 hover:!bg-blue-100'
          : '';
        const classes = `${baseClasses} ${activeClasses} ${className}`;

        return (
          <div className={classes}>
            {icon}
            <span>{label}</span>
          </div>
        );
      }}
    </Link>
  );
};

export default SidebarButton;
