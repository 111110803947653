import { Conversation } from 'src/api/generated';
import {
  getConversationExpirationInfo,
  formatExpirationTime,
} from 'src/modules/messaging/utils/conversations/conversation.util';
import ConversationStatusDisplay from 'src/modules/messaging/components/atoms/conversations/conversation-status-display';
import Tag from 'src/modules/shared/components/atoms/tag';
import { RiHourglassFill } from 'react-icons/ri';
import { HOUR_IN_MS } from 'src/utils/date';

interface ConversationExpirationDisplayProps {
  conversation: Conversation;
}

const ConversationExpirationDisplay: React.FC<
  ConversationExpirationDisplayProps
> = ({ conversation }) => {
  const { isExpired, timeRemaining } =
    getConversationExpirationInfo(conversation);

  if (isExpired) {
    return <ConversationStatusDisplay status="expired" size="sm" />;
  }

  if (timeRemaining !== null) {
    const isNearExpiration = timeRemaining <= 3 * HOUR_IN_MS;

    return (
      <Tag
        text={formatExpirationTime(timeRemaining)}
        icon={<RiHourglassFill className="h-3 w-3" />}
        variant={isNearExpiration ? 'error' : 'neutral'}
        size="sm"
        className="opacity-75"
      />
    );
  }

  return null;
};

export default ConversationExpirationDisplay;
