// src/components/molecules/controlled-select/controlled-select.tsx

export interface SelectOption {
  text: string;
  value: string;
}

interface ControlledSelectProps {
  options: SelectOption[];
  selectedValue?: string;
  onSelectionChange: (selectedValue: string | undefined) => void;
  className?: string;
}

export const ControlledSelect: React.FC<ControlledSelectProps> = ({
  options,
  selectedValue,
  onSelectionChange,
  className = '',
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    onSelectionChange(selectedValue);
  };

  const classes = `
  text-base font-normal px-4 pr-8 py-2 border border-gray-300 rounded-lg shadow-sm
  focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500
  ${className}
  `;

  return (
    <select onChange={handleChange} value={selectedValue} className={classes}>
      {options.map(({ text, value }) => (
        <option key={value} value={value}>
          {text}
        </option>
      ))}
    </select>
  );
};

export default ControlledSelect;
