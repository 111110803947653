import DialerButton from '../dialer-button';

interface KeypadButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

const KeypadButton: React.FC<KeypadButtonProps> = ({
  label,
  onClick,
  disabled = false,
}) => {
  return (
    <DialerButton onClick={onClick} disabled={disabled}>
      {label}
    </DialerButton>
  );
};

export default KeypadButton;
