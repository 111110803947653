// src/modules/shared/components/molecules/modals/copy-modal/index.tsx

import { IconButton, Typography } from '@material-tailwind/react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import Modal from '../modal';
import { useCopyToClipboard } from 'src/modules/shared/hooks/modal';

interface ModalProps {
  children: JSX.Element;
  copyableContent?: string;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  headerClasses?: string;
  bodyClassName?: string;
}

const CopyModal: React.FC<ModalProps> = ({
  children,
  copyableContent,
  isOpen,
  onClose,
  title,
  headerClasses = '',
  bodyClassName = '',
}) => {
  const copyToClipboard = useCopyToClipboard();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <div className="flex items-center">
          {title ? (
            <Typography
              variant="h4"
              className={`text-gray-800 ${headerClasses}`}
            >
              {title}
            </Typography>
          ) : (
            <span />
          )}
          {copyableContent && (
            <IconButton
              onClick={() => copyToClipboard(copyableContent)}
              variant="text"
              className="mx-1"
            >
              <DocumentDuplicateIcon strokeWidth={2} className="h-6 w-6" />
            </IconButton>
          )}
        </div>
      }
      body={children}
      bodyClassName={bodyClassName}
    />
  );
};

export default CopyModal;
