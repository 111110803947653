import React from 'react';
import Table, {
  TableColumnProps,
  TableRowProps,
} from 'components/molecules/tables/table';
import { useFiltering, useSorting, usePagination } from 'hooks/tables';
import Paginator from 'components/molecules/tables/paginator';
import Filters, { FilterOption } from 'components/molecules/tables/filters';
import SortingHeader, {
  SortOrder,
} from 'components/molecules/tables/sorting-header';

type AdvancedTableProps = {
  columns: TableColumnProps[];
  data: TableRowProps[];
  filterOptions: FilterOption[];
  rowsPerPage?: number;
  defaultFilterIndex?: number;
  controlledFilterIndex?: number;
  setControlledFilterIndex?: (selectedIndex: number) => void;
  defaultSortField?: string | null;
  defaultSortOrder?: SortOrder | null;
  controlledSortField?: string | null;
  setControlledSortField?: (field: string | null) => void;
  controlledSortOrder?: SortOrder | null;
  setControlledSortOrder?: (order: SortOrder | null) => void;
  countRecords?: boolean;
  headerElement?: React.ReactNode;
};

const AdvancedTable: React.FC<AdvancedTableProps> = ({
  columns,
  data,
  filterOptions,
  rowsPerPage = 10,
  defaultFilterIndex,
  controlledFilterIndex,
  setControlledFilterIndex,
  defaultSortField = null,
  defaultSortOrder = null,
  controlledSortField,
  setControlledSortField,
  controlledSortOrder,
  setControlledSortOrder,
  countRecords = false,
  headerElement,
}) => {
  const {
    filteredData,
    selectedFilter,
    setSelectedFilter,
    filterOptionsWithCount,
  } = useFiltering(
    data,
    filterOptions,
    defaultFilterIndex,
    countRecords,
    controlledFilterIndex,
    setControlledFilterIndex
  );
  const { sortedData, sortField, setSortField, sortOrder, setSortOrder } =
    useSorting(
      filteredData,
      defaultSortField,
      defaultSortOrder,
      controlledSortField,
      setControlledSortField,
      controlledSortOrder,
      setControlledSortOrder
    );
  const { paginatedData, totalPages, currentPage, setCurrentPage } =
    usePagination(sortedData, rowsPerPage);

  const resetTable = () => {
    setSortOrder(defaultSortOrder);
    setSortField(defaultSortField);
    setCurrentPage(1);
  };

  const selectFilter = (filterValue: FilterOption): void => {
    resetTable();
    setSelectedFilter(filterValue);
  };

  const selectSortField = (sortField: string | null): void => {
    resetTable();
    setSortField(sortField);
  };

  const sortableColumns = columns.map((column) => ({
    ...column,
    header: (
      <SortingHeader
        header={column.header}
        accessor={column.accessor}
        sortField={sortField}
        onSetSortField={selectSortField}
        order={sortOrder}
        onSetOrder={setSortOrder}
      />
    ),
  }));

  return (
    <>
      <Filters
        filterOptions={filterOptionsWithCount}
        selectedFilter={selectedFilter}
        onFilterChange={selectFilter}
        headerElement={headerElement}
      />
      <Table columns={sortableColumns} data={paginatedData} />
      <Paginator
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </>
  );
};

export default AdvancedTable;
