/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * WhatsApp message types.
 */
export enum WhatsAppMessageType {
    TEXT = 'text',
    IMAGE = 'image',
    AUDIO = 'audio',
    VIDEO = 'video',
    DOCUMENT = 'document',
    STICKER = 'sticker',
    LOCATION = 'location',
    CONTACTS = 'contacts',
    INTERACTIVE = 'interactive',
    REACTION = 'reaction',
    BUTTON = 'button',
    ORDER = 'order',
    SYSTEM = 'system',
    UNKNOWN = 'unknown',
    UNSUPPORTED = 'unsupported',
}
