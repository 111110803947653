// src/components/atoms/tooltips/titled-tooltip/index.tsx

import React from 'react';
import { Typography } from '@material-tailwind/react';
import StyledTooltip from 'components/atoms/tooltips/styled-tooltip';

interface TitledTooltipProps {
  title: string;
  description: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
}

const TitledTooltip: React.FC<TitledTooltipProps> = ({
  title,
  description,
  icon,
  children,
  placement,
  className = '',
}) => {
  return (
    <StyledTooltip
      content={
        <div className="relative flex flex-col gap-2 max-w-[300px]">
          <div className="flex flex-row justify-between">
            <Typography className="text-blue-gray-600 font-semibold">
              {title}
            </Typography>
            {icon ? icon : ''}
          </div>
          <Typography className="text-sm text-blue-gray-600">
            {description}
          </Typography>
        </div>
      }
      placement={placement}
      className={className}
    >
      {children}
    </StyledTooltip>
  );
};

export default TitledTooltip;
