import React, { useMemo } from 'react';
import { parseWhatsAppMessage } from 'src/modules/messaging/whatsapp/utils/messages/format.util';
import { Typography } from '@material-tailwind/react';

interface WhatsAppTextProps {
  text: string;
}

const WhatsAppText: React.FC<WhatsAppTextProps> = ({ text }) => {
  const segments = useMemo(() => parseWhatsAppMessage(text), [text]);

  return (
    <Typography className="text-sm">
      {segments.map((segment, index) => {
        const { content, styles } = segment;
        let styledElement: React.ReactNode = content;

        if (styles.includes('bold')) {
          styledElement = <span className="font-bold">{styledElement}</span>;
        }
        if (styles.includes('italic')) {
          styledElement = <span className="italic">{styledElement}</span>;
        }
        if (styles.includes('strikethrough')) {
          styledElement = <span className="line-through">{styledElement}</span>;
        }
        if (styles.includes('monospace')) {
          styledElement = (
            <code className="font-mono bg-gray-100 text-gray-800 px-1 rounded">
              {styledElement}
            </code>
          );
        }
        if (styles.includes('variable')) {
          styledElement = (
            <span className="bg-blue-500 text-white font-semibold">
              {styledElement}
            </span>
          );
        }
        if (styles.includes('newline')) {
          styledElement = <br />;
        }

        return <React.Fragment key={index}>{styledElement}</React.Fragment>;
      })}
    </Typography>
  );
};

export default WhatsAppText;
