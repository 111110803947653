import AppExtensionsSDK, { Event } from '@pipedrive/app-extensions-sdk';
import { useCallback, useEffect, useState } from 'react';
import ErrorReporter from 'src/monitoring/errorReporter';
import {
  PipedriveIntegration,
  PipedriveOutgoingCallContext,
  PipedriveVisibilityEvent,
} from './types';
import { usePhoneContext } from 'src/contexts/phone-context';
import { hideFloatingWindow, showFloatingWindow } from './utils';
import { CallProvider, CallProviderData } from 'hooks/phones/types';
import STATES from 'states/index';

const DEVELOPMENT_ENV = import.meta.env.DEV;

export const useInitPipedriveIntegration = (
  sdk: AppExtensionsSDK | null,
  callProvider: CallProvider
): PipedriveIntegration => {
  useSetPipedriveEventHandlers(sdk, callProvider);

  const { setPhoneVisibility } = useSetPipedrivePhoneVisibility(sdk);

  return {
    setPhoneVisibility,
  };
};

export const useInitPipedriveSdk = (
  identifier: string
): { sdk: AppExtensionsSDK | null } => {
  const [sdk, setSdk] = useState<AppExtensionsSDK | null>(null);

  const initializeSDK = useCallback(async () => {
    try {
      const sdk = await new AppExtensionsSDK({
        identifier,
      }).initialize();

      setSdk(sdk);
    } catch (error) {
      console.log('Failed to initialize AppExtensionsSDK:', error);
    }
  }, [identifier]);

  useEffect(() => {
    if (!DEVELOPMENT_ENV) {
      initializeSDK();
    }
  }, [initializeSDK]);

  return { sdk };
};

const useSetPipedriveEventHandlers = (
  sdk: AppExtensionsSDK | null,
  callProvider: CallProvider
) => {
  const subscribeVisibilityEvents = useCallback(() => {
    if (!sdk) {
      const unsubscribeFn = () => {};
      return unsubscribeFn;
    }

    const unsubscribeFn = sdk.listen<Event.VISIBILITY>(
      Event.VISIBILITY,
      async ({ error, data }: PipedriveVisibilityEvent) => {
        if (error) {
          ErrorReporter.sendMessage(error, 'error');
          return;
        }

        console.log({ data });

        const context = data?.context;
        const callToNumber = context?.callToNumber;
        if (!callToNumber) return;

        let providerData: CallProviderData | undefined = undefined;
        if (context?.relatedIds) {
          const outgoingCallContext = context as PipedriveOutgoingCallContext;
          const contactProviderId =
            outgoingCallContext.relatedIds?.personId?.toString();
          const opportunityProviderId =
            outgoingCallContext.relatedIds?.dealId?.toString();

          providerData = {
            providerName: 'pipedrive',
            contactProviderId,
            opportunityProviderId,
          };
        }

        callProvider.callActions.startCall({
          phoneNumber: callToNumber as string,
          callProviderData: providerData,
        });
      }
    );

    return unsubscribeFn;
  }, [callProvider.callActions, sdk]);

  useEffect(() => {
    const unsubscribeVisibilityEvents = subscribeVisibilityEvents();

    const unsubscribeAll = () => {
      unsubscribeVisibilityEvents();
    };
    return () => {
      unsubscribeAll();
    };
  }, [subscribeVisibilityEvents]);
};

const useSetPipedrivePhoneVisibility = (sdk: AppExtensionsSDK | null) => {
  const { setKeyboardVisible, phoneState } = usePhoneContext();

  const setPhoneVisibility = useCallback(
    (visibility: boolean) => {
      if (!sdk) return;

      setKeyboardVisible(visibility);
      visibility ? showFloatingWindow(sdk) : hideFloatingWindow(sdk);
    },
    [sdk, setKeyboardVisible]
  );

  useEffect(() => {
    if (phoneState !== STATES.PHONE.INCOMING) return;

    setPhoneVisibility(true);
  }, [phoneState, setPhoneVisibility]);

  return { setPhoneVisibility };
};
