import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, ComponentType } from 'react';

interface WithChildrenProps {
  children: ReactNode;
}

const withAuthentication = <P extends WithChildrenProps>(
  ProviderComponent: ComponentType<P>
) => {
  const WrappedComponent = (props: P) => {
    const { isAuthenticated } = useAuth0();

    return isAuthenticated ? (
      <ProviderComponent {...props} />
    ) : (
      <>{props.children}</>
    );
  };

  const commponentName =
    ProviderComponent.displayName || ProviderComponent.name || 'Component';
  WrappedComponent.displayName = `withAuthentication(${commponentName})`;

  return WrappedComponent;
};

export default withAuthentication;
