import { Button, ButtonGroup } from '@material-tailwind/react';
import { PhoneArrowDownLeftIcon } from '@heroicons/react/24/solid';
import CallMenu from 'components/molecules/calls/call-menu';

interface CallButtonProps {
  startCall: () => void;
  disabled?: boolean;
  callWithoutInternetChoose: boolean;
  callingWithoutInternet: boolean;
  updateSetting: (value: boolean) => void;
}

const CallButton = ({
  startCall,
  disabled = false,
  callWithoutInternetChoose,
  callingWithoutInternet,
  updateSetting,
}: CallButtonProps) => {
  const disabledClasses = disabled
    ? 'bg-blue-gray-700 cursor-not-allowed'
    : 'bg-green-500 hover:bg-green-600';
  const buttonClasses = `flex-1 p-2 rounded-l-lg w-full ${disabledClasses}`;

  if (callWithoutInternetChoose) {
    return (
      <ButtonGroup className={'flex flex-row w-full'}>
        <Button
          size="lg"
          disabled={disabled}
          onClick={() => startCall()}
          className={buttonClasses}
        >
          <div className="flex items-center justify-center space-x-2">
            <span className="text-sm">Llamar</span>
            {callingWithoutInternet ? (
              <PhoneArrowDownLeftIcon className="h-4 w-4" />
            ) : null}
          </div>
        </Button>
        <CallMenu
          callingWithoutInternet={callingWithoutInternet}
          updateSetting={updateSetting}
        />
      </ButtonGroup>
    );
  }

  return (
    <Button
      size="lg"
      disabled={disabled}
      onClick={() => startCall()}
      className={buttonClasses}
    >
      <div className="flex items-center justify-center space-x-2">
        <span className="text-sm">Llamar</span>
      </div>
    </Button>
  );
};

export default CallButton;
