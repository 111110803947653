// src/hooks/charts/agent-calls.ts

import { useMemo } from 'react';
import { AgentCallSummary, DailyCallSummary } from 'src/api/generated';
import {
  TimeFrame,
  formatDateLabel,
  getMonthName,
  getDateRange,
  getStartOfMonth,
  getStartOfWeek,
} from 'src/utils/date';
import { formatWeeklyLabel, periodLengths } from 'src/utils/charts';
import {
  AgentCallsChartData,
  AgentPeriodCallSummary,
  AgentPeriodsCallSummary,
} from 'src/types/call-summaries';

export const useAgentCallsData = (
  summaries: DailyCallSummary[],
  timeFrame: TimeFrame
): AgentCallsChartData => {
  const { startDate, endDate } = useMemo(() => {
    return getDateRange(timeFrame, periodLengths[timeFrame]);
  }, [timeFrame]);

  const filteredSummaries = useMemo(
    () =>
      summaries.filter((summary) => {
        const summaryDay = new Date(summary.day);
        return summaryDay >= startDate && summaryDay <= endDate;
      }),
    [summaries, startDate, endDate]
  );

  const chartData = useMemo(
    () => processSummaries(timeFrame, filteredSummaries),
    [filteredSummaries, timeFrame]
  );

  return chartData;
};

const processSummaries = (
  timeFrame: TimeFrame,
  summaries: DailyCallSummary[]
): AgentCallsChartData => {
  const processingFunctions = {
    daily: processSummariesDaily,
    weekly: processSummariesWeekly,
    monthly: processSummariesMonthly,
  };
  const processingFunction = processingFunctions[timeFrame];

  return processingFunction(summaries);
};

const processSummariesDaily = (
  summaries: DailyCallSummary[]
): AgentCallsChartData => {
  const labels = summaries.map((summary) => formatDateLabel(summary.day));
  const agentSummaries: Record<string, AgentPeriodsCallSummary> = {};

  summaries.forEach((summary) => {
    summary.agents.forEach((agent) => {
      const agentData =
        agentSummaries[agent.name] ||
        initializeAgentData(summaries.length, agent.id, agent.name);
      const dayIndex = labels.indexOf(formatDateLabel(summary.day));
      updateAgentData(agentData, agent, dayIndex);
      agentSummaries[agent.name] = agentData;
    });
  });

  return {
    labels,
    agentPeriodsCallSummaries: Object.values(agentSummaries),
  };
};

const processSummariesWeekly = (
  summaries: DailyCallSummary[]
): AgentCallsChartData => {
  const { weeklyData, agents } = aggregateWeeklyData(summaries);
  const { weeks, labels } = getWeeksAndLabels();

  const transformedData = transformWeeklyData(agents, weeklyData, weeks);
  const agentPeriodsCallSummaries: AgentPeriodsCallSummary[] =
    Object.values(transformedData);

  return { labels, agentPeriodsCallSummaries };
};

const processSummariesMonthly = (
  summaries: DailyCallSummary[]
): AgentCallsChartData => {
  const { monthlyData, agents } = aggregateMonthlyData(summaries);
  const { months, labels } = getMonthsAndLabels();

  const transformedData = transformMonthlyData(agents, monthlyData, months);
  const agentPeriodsCallSummaries: AgentPeriodsCallSummary[] =
    Object.values(transformedData);

  return { labels, agentPeriodsCallSummaries };
};

const initializeAgentData = (
  length: number,
  id: number,
  name: string
): AgentPeriodsCallSummary => {
  return {
    agentSummary: {
      id,
      name,
      effectiveCalls: 0,
      missedCalls: 0,
      voicemailCalls: 0,
    },
    effectiveCalls: new Array(length).fill(0),
    missedCalls: new Array(length).fill(0),
    voicemailCalls: new Array(length).fill(0),
  };
};

const updateAgentData = (
  agentData: AgentPeriodsCallSummary,
  agentSummary: AgentCallSummary,
  index: number
): void => {
  agentData.effectiveCalls[index] = agentSummary.effectiveCalls;
  agentData.missedCalls[index] = agentSummary.missedCalls;
  agentData.voicemailCalls[index] = agentSummary.voicemailCalls;
};

const aggregateWeeklyData = (summaries: DailyCallSummary[]) => {
  const weeklyData = new Map<string, Map<number, AgentCallSummary>>();
  const agents = new Map<number, string>();

  summaries.forEach((summary) => {
    const weekStartFormatted = getStartOfWeek(
      new Date(summary.day)
    ).toISOString();

    if (!weeklyData.has(weekStartFormatted)) {
      weeklyData.set(weekStartFormatted, new Map<number, AgentCallSummary>());
    }
    const weeklyAgentSummaries = weeklyData.get(weekStartFormatted);

    summary.agents.forEach((agentSummary) => {
      if (!weeklyAgentSummaries) return;

      const existingAgentData = weeklyAgentSummaries.get(agentSummary.id);
      const agentData: AgentCallSummary = {
        id: agentSummary.id,
        name: agentSummary.name,
        effectiveCalls: existingAgentData
          ? existingAgentData.effectiveCalls + agentSummary.effectiveCalls
          : agentSummary.effectiveCalls,
        missedCalls: existingAgentData
          ? existingAgentData.missedCalls + agentSummary.missedCalls
          : agentSummary.missedCalls,
        voicemailCalls: existingAgentData
          ? existingAgentData.voicemailCalls + agentSummary.voicemailCalls
          : agentSummary.voicemailCalls,
      };

      weeklyAgentSummaries.set(agentSummary.id, agentData);
      agents.set(agentSummary.id, agentSummary.name);
    });
  });

  return { weeklyData, agents };
};

const aggregateMonthlyData = (summaries: DailyCallSummary[]) => {
  const monthlyData = new Map<string, Map<number, AgentCallSummary>>();
  const agents = new Map<number, string>();

  summaries.forEach((summary) => {
    const monthStartFormatted = getStartOfMonth(
      new Date(summary.day)
    ).toISOString();

    if (!monthlyData.has(monthStartFormatted)) {
      monthlyData.set(monthStartFormatted, new Map<number, AgentCallSummary>());
    }
    const monthlyAgentSummaries = monthlyData.get(monthStartFormatted);

    summary.agents.forEach((agentSummary) => {
      if (!monthlyAgentSummaries) return;

      const existingAgentData = monthlyAgentSummaries.get(agentSummary.id);
      const agentData: AgentCallSummary = {
        id: agentSummary.id,
        name: agentSummary.name,
        effectiveCalls: existingAgentData
          ? existingAgentData.effectiveCalls + agentSummary.effectiveCalls
          : agentSummary.effectiveCalls,
        missedCalls: existingAgentData
          ? existingAgentData.missedCalls + agentSummary.missedCalls
          : agentSummary.missedCalls,
        voicemailCalls: existingAgentData
          ? existingAgentData.voicemailCalls + agentSummary.voicemailCalls
          : agentSummary.voicemailCalls,
      };

      monthlyAgentSummaries.set(agentSummary.id, agentData);
      agents.set(agentSummary.id, agentSummary.name);
    });
  });

  return { monthlyData, agents };
};

const getWeeksAndLabels = () => {
  const currentWeekStartFormatted = getStartOfWeek(new Date()).toISOString();
  const weeks = [];
  for (let i = 0; i < periodLengths.weekly; i++) {
    const weekStart = new Date(currentWeekStartFormatted);
    weekStart.setDate(weekStart.getDate() - i * 7);
    weeks.unshift(weekStart.toISOString());
  }

  const labels = weeks.map((date) =>
    formatWeeklyLabel(date, currentWeekStartFormatted)
  );
  return { weeks, labels };
};

const getMonthsAndLabels = () => {
  const currentMonthStartFormatted = getStartOfMonth(new Date()).toISOString();
  const months = [];
  for (let i = 0; i < periodLengths.monthly; i++) {
    const monthStart = new Date(currentMonthStartFormatted);
    monthStart.setMonth(monthStart.getMonth() - i);
    months.unshift(monthStart.toISOString());
  }

  const labels = months.map((date) => {
    const monthName = getMonthName(new Date(date).getMonth());
    const monthKey = date.slice(0, 7);
    const currentMonthKey = currentMonthStartFormatted.slice(0, 7);
    return monthName + (monthKey === currentMonthKey ? ' (Actual)' : '');
  });
  return { months, labels };
};

const transformWeeklyData = (
  agents: Map<number, string>,
  weeklyData: Map<string, Map<number, AgentPeriodCallSummary>>,
  weeks: string[]
): Record<number, AgentPeriodsCallSummary> => {
  const transformedData: Record<number, AgentPeriodsCallSummary> = {};

  agents.forEach((agentName, agentId) => {
    transformedData[agentId] = {
      agentSummary: {
        id: agentId,
        name: agentName,
        effectiveCalls: 0,
        missedCalls: 0,
        voicemailCalls: 0,
      },
      effectiveCalls: new Array(weeks.length).fill(0),
      missedCalls: new Array(weeks.length).fill(0),
      voicemailCalls: new Array(weeks.length).fill(0),
    };
  });

  weeks.forEach((week, weekIndex) => {
    const weeklyAgentSummaries = weeklyData.get(week);
    if (weeklyAgentSummaries) {
      agents.forEach((_agentName, agentId) => {
        const agentData = weeklyAgentSummaries.get(agentId);
        if (agentData) {
          transformedData[agentId].effectiveCalls[weekIndex] =
            agentData.effectiveCalls;
          transformedData[agentId].missedCalls[weekIndex] =
            agentData.missedCalls;
          transformedData[agentId].voicemailCalls[weekIndex] =
            agentData.voicemailCalls;
        }
      });
    }
  });

  return transformedData;
};

const transformMonthlyData = (
  agents: Map<number, string>,
  monthlyData: Map<string, Map<number, AgentPeriodCallSummary>>,
  months: string[]
): Record<number, AgentPeriodsCallSummary> => {
  const transformedData: Record<number, AgentPeriodsCallSummary> = {};

  agents.forEach((agentName, agentId) => {
    transformedData[agentId] = {
      agentSummary: {
        id: agentId,
        name: agentName,
        effectiveCalls: 0,
        missedCalls: 0,
        voicemailCalls: 0,
      },
      effectiveCalls: new Array(months.length).fill(0),
      missedCalls: new Array(months.length).fill(0),
      voicemailCalls: new Array(months.length).fill(0),
    };
  });

  months.forEach((month, monthIndex) => {
    const monthlyAgentSummaries = monthlyData.get(month);
    if (!monthlyAgentSummaries) return;

    agents.forEach((_agentName, agentId) => {
      const agentData = monthlyAgentSummaries.get(agentId);
      if (!agentData) return;

      transformedData[agentId].effectiveCalls[monthIndex] =
        agentData.effectiveCalls;
      transformedData[agentId].missedCalls[monthIndex] = agentData.missedCalls;
      transformedData[agentId].voicemailCalls[monthIndex] =
        agentData.voicemailCalls;
    });
  });

  return transformedData;
};
