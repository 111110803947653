import { createFileRoute } from '@tanstack/react-router';
import AuthenticateRoute from 'components/organisms/app-layouts/authenticate-route';
import FeaturedView from 'components/views/featured-view';

export const Route = createFileRoute('/featured-calls/')({
  component: FeaturedCallsIndexComponent,
});

// Example to add nested routes
// https://tanstack.com/router/latest/docs/framework/react/examples/basic-file-based
function FeaturedCallsIndexComponent() {
  return (
    <AuthenticateRoute>
      <FeaturedView />
    </AuthenticateRoute>
  );
}
