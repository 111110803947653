/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Interface representing a conversation channel DTO.
 */
export enum ConversationChannel {
    WHATSAPP = 'whatsapp',
    EMAIL = 'email',
    SMS = 'sms',
}
