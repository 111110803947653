// src/components/atoms/audio-device-select/audio-device-select.tsx

import { Typography } from '@material-tailwind/react';

interface AudioDeviceSelectProps {
  label: string;
  devices: MediaDeviceInfo[];
  selectedDeviceId: string;
  onChange: (deviceId: string) => void;
}

const AudioDeviceSelect: React.FC<AudioDeviceSelectProps> = ({
  label,
  devices,
  selectedDeviceId,
  onChange,
}) => (
  <div className="mb-4">
    <Typography variant="small" className="font-normal">
      {label}
    </Typography>
    <select
      id={label}
      value={selectedDeviceId}
      onChange={(e) => {
        const deviceId = e.target.value;
        onChange(deviceId);
      }}
      className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
    >
      {devices.map((device) => (
        <option key={device.deviceId} value={device.deviceId}>
          {device.label || `${label} ${device.deviceId}`}
        </option>
      ))}
    </select>
  </div>
);

export default AudioDeviceSelect;
