// src/hooks/api/opportunities.ts

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from './api';
import {
  ApiQueryParams,
  OpportunitiesService,
  Opportunity,
} from 'src/api/generated';
import { CacheKeyValue } from 'src/types/cache';
import { updateListCache } from 'src/utils/cache';

interface OpportunitiesResponse extends ApiResponse {
  opportunities: Opportunity[];
}

export const useOpportunities = (
  queryParams: ApiQueryParams = {}
): OpportunitiesResponse => {
  const fetchOpportunities = async (): Promise<Opportunity[]> => {
    const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

    return await OpportunitiesService.getOpportunities(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize
    );
  };

  const {
    data: opportunities,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['opportunities', queryParams],
    queryFn: fetchOpportunities,
  });

  return {
    loading,
    error,
    opportunities: opportunities || [],
    refetch,
  };
};

interface UpdateOpportunityParams {
  opportunityId: number;
  updatedOpportunity: Partial<Opportunity>;
}

interface UpdateOpportunityResponse {
  loading: boolean;
  error: Error | null;
  updateOpportunity: (updateOpportunityParams: UpdateOpportunityParams) => void;
}

export const useUpdateOpportunity = (
  onUpdateSuccess?: () => void,
  onUpdateError?: () => void,
  cacheKey: CacheKeyValue[] = []
): UpdateOpportunityResponse => {
  const queryClient = useQueryClient();

  const updateOpportunity = async ({
    opportunityId,
    updatedOpportunity,
  }: UpdateOpportunityParams): Promise<Opportunity> => {
    return await OpportunitiesService.updateOpportunity(
      opportunityId,
      updatedOpportunity
    );
  };
  const mutation = useMutation({
    mutationFn: updateOpportunity,
    onSuccess: (data: Opportunity) => {
      const queryKey = ['opportunities', ...cacheKey];
      updateListCache({
        queryClient,
        queryKey,
        updatedData: data,
      });
      onUpdateSuccess && onUpdateSuccess();
    },
    onError: () => onUpdateError && onUpdateError(),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    updateOpportunity: mutation.mutate,
  };
};
