// src/hooks/api/start-calls.ts

import { Call, CallsService, NewBridgeCall } from '../../../api/generated';
import { useMutation } from '@tanstack/react-query';

interface StartBridgCallResponse {
  loading: boolean;
  error: Error | null;
  call?: Call | null;
  startBridgeCall: (newCallBridgeCall: NewBridgeCall) => void;
}

export const useStartBridgeCall = (
  onUpdateSuccess?: (call: Call) => void,
  onUpdateError?: (error?: Error | null) => void
): StartBridgCallResponse => {
  const startBridgeCall = async (
    newCallBridgeCall: NewBridgeCall
  ): Promise<Call> => {
    return await CallsService.startBridgeCall(newCallBridgeCall);
  };

  const mutation = useMutation({
    mutationFn: startBridgeCall,
    onSuccess: (data: Call) => onUpdateSuccess && onUpdateSuccess(data),
    onError: (error: Error | null) => onUpdateError && onUpdateError(error),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    call: mutation.data,
    startBridgeCall: mutation.mutate,
  };
};
