import { Message, MessageStatus } from 'src/api/generated';
import {
  CheckIcon,
  ClockIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import Tooltip from 'src/modules/shared/components/atoms/tooltips/tooltip';
import { getMessageStatusTranslation } from 'src/modules/messaging/whatsapp/utils/messages/message.util';

interface MessageStatusIndicatorProps {
  message: Message;
  className?: string;
}

interface StatusConfig {
  icon: JSX.Element;
  getTooltip: (errorMessage?: string) => string;
  colorClass: string;
}

const STATUS_CONFIGS: Record<MessageStatus, StatusConfig | null> = {
  [MessageStatus.PENDING]: {
    icon: <ClockIcon className="h-3 w-3" />,
    getTooltip: () =>
      getMessageStatusTranslation(MessageStatus.PENDING, 'tooltip'),
    colorClass: 'text-white',
  },
  [MessageStatus.FAILED]: {
    icon: <ExclamationCircleIcon className="h-4 w-4 mt-0.5 stroke-[3px]" />,
    getTooltip: (errorMessage) =>
      errorMessage ||
      getMessageStatusTranslation(MessageStatus.FAILED, 'tooltip'),
    colorClass: 'text-red-300',
  },
  [MessageStatus.SENT]: {
    icon: <CheckIcon className="h-3 w-3 stroke-[2px]" />,
    getTooltip: () =>
      getMessageStatusTranslation(MessageStatus.SENT, 'tooltip'),
    colorClass: 'text-white/80',
  },
  [MessageStatus.DELIVERED]: {
    icon: (
      <span className="flex -space-x-1">
        <CheckIcon className="h-3 w-3 stroke-[2px]" />
        <CheckIcon className="h-3 w-3 stroke-[2px]" />
      </span>
    ),
    getTooltip: () =>
      getMessageStatusTranslation(MessageStatus.DELIVERED, 'tooltip'),
    colorClass: 'text-white/80',
  },
  [MessageStatus.READ]: {
    icon: (
      <span className="flex -space-x-1">
        <CheckIcon className="h-3 w-3 stroke-[3px]" />
        <CheckIcon className="h-3 w-3 stroke-[3px]" />
      </span>
    ),
    getTooltip: () =>
      getMessageStatusTranslation(MessageStatus.READ, 'tooltip'),
    colorClass: 'text-white',
  },
  [MessageStatus.DELETED]: null,
  [MessageStatus.WARNING]: null,
};

const baseContainerClasses =
  'inline-flex h-4 w-4 mx-0.5 items-center justify-center';

const MessageStatusIndicator: React.FC<MessageStatusIndicatorProps> = ({
  message,
  className = '',
}) => {
  const { status, errors } = message;
  const errorMessage = errors?.[0]?.message;

  const config = STATUS_CONFIGS[status];
  if (!config) return null;

  return (
    <Tooltip
      content={config.getTooltip(errorMessage)}
      size="sm"
      placement="top"
    >
      <span
        className={`${baseContainerClasses} ${config.colorClass} ${className}`}
      >
        {config.icon}
      </span>
    </Tooltip>
  );
};

export default MessageStatusIndicator;
