import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/login')({
  beforeLoad: ({ context }) => {
    const { isAuthenticated, loginWithRedirect } = context.auth;

    if (!isAuthenticated) {
      throw loginWithRedirect();
    }

    throw redirect({
      to: '/calls',
    });
  },
});
