// src/hooks/utils/countdown.ts

import { useEffect, useState } from 'react';

const useActionAfterCountdown = (
  durationInMs: number,
  onComplete: () => void,
  updateIntervalInMs: number = 10
) => {
  const [timeLeft, setTimeLeft] = useState(durationInMs);

  useEffect(() => {
    setTimeLeft(durationInMs);
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= updateIntervalInMs) {
          clearInterval(interval);
          onComplete();
          return 0;
        }
        return prevTime - updateIntervalInMs;
      });
    }, updateIntervalInMs);

    return () => clearInterval(interval);
  }, [durationInMs, onComplete, updateIntervalInMs]);

  const progress = ((durationInMs - timeLeft) / durationInMs) * 100;

  return { timeLeft, progress };
};

export default useActionAfterCountdown;
