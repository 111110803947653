import React from 'react';
import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { Typography } from '@material-tailwind/react';

export type SortOrder = 'asc' | 'desc' | null;

type SortingHeaderProps = {
  header: string | JSX.Element;
  accessor: string;
  sortField: string | null;
  onSetSortField: (sortField: string | null) => void;
  order: 'asc' | 'desc' | null;
  onSetOrder: (sortOrder: SortOrder) => void;
  disabled?: boolean;
};

const SortingHeader: React.FC<SortingHeaderProps> = ({
  header,
  accessor,
  sortField,
  onSetSortField,
  order,
  onSetOrder,
  disabled = false,
}) => {
  const selectedHeader: boolean = sortField === accessor;
  const sortOrder: SortOrder =
    selectedHeader && order === 'desc' ? 'asc' : 'desc';

  const handleSorting = () => {
    if (disabled) {
      return null;
    }

    onSetSortField(accessor);
    onSetOrder(sortOrder);
  };

  const displayChevron = (): JSX.Element | null => {
    if (disabled) {
      return null;
    }

    if (!selectedHeader) {
      return <ChevronUpDownIcon strokeWidth={2} className={'h-4 w-4'} />;
    }

    if (sortOrder === 'asc') {
      return <ChevronUpIcon strokeWidth={2} className={'h-4 w-4'} />;
    }

    return <ChevronDownIcon strokeWidth={2} className={'h-4 w-4'} />;
  };

  return (
    <div
      className="flex items-center justify-between gap-2 cursor-pointer"
      onClick={handleSorting}
    >
      {typeof header === 'string' ? (
        <Typography variant="small" color="blue-gray" className="font-normal">
          {header}
        </Typography>
      ) : (
        header
      )}
      {displayChevron()}
    </div>
  );
};

export default SortingHeader;
