// src/components/atoms/call-quality-radio/call-quality-radio.tsx

import { Radio, Typography } from '@material-tailwind/react';
import TextTooltip from '../tooltips/text-tooltip';
import { CALL_QUALITY_BITRATES } from 'src/types/phone-config';

interface CallQualityRadioProps {
  selectedDeviceBitrate: number;
  setSelectedDeviceBitrate: (bitrate: number) => void;
}

const QUALITY_OPTIONS = [
  {
    label: 'Muy Alta',
    value: CALL_QUALITY_BITRATES.HIGH,
    color: 'blue' as const,
    description: null,
  },
  {
    label: 'Alta',
    value: CALL_QUALITY_BITRATES.MID,
    color: 'green' as const,
    description: null,
  },
  {
    label: 'Media',
    value: CALL_QUALITY_BITRATES.LOW,
    color: 'amber' as const,
    description:
      'Recomendado si tienes poca conexión a internet (o inestable).',
  },
];

const CallQualityRadio: React.FC<CallQualityRadioProps> = ({
  selectedDeviceBitrate,
  setSelectedDeviceBitrate,
}) => {
  return (
    <div className="mb-4">
      <TextTooltip
        // TODO: check when implementing i18n
        content="Calidad de Llamadas"
        tooltipContent={
          'La calidad más alta requiere una conexión a internet estable. Si recibes la ' +
          'alerta de internet, intenta bajar la calidad para mejorar la estabilidad y fluidez de tu llamada.'
        }
        placement="top"
      />
      <div className="flex flex-col">
        {QUALITY_OPTIONS.map((option) => (
          <Radio
            key={option.value}
            name="callQuality"
            color={option.color}
            label={
              <div className="flex flex-col">
                <Typography
                  color="blue-gray"
                  variant="small"
                  className={`font-medium ${option.description ? 'mt-2' : ''}`}
                >
                  {option.label}
                </Typography>
                {option.description && (
                  <Typography
                    variant="small"
                    color="gray"
                    className="font-normal"
                  >
                    {option.description}
                  </Typography>
                )}
              </div>
            }
            checked={selectedDeviceBitrate === option.value}
            onChange={() => setSelectedDeviceBitrate(option.value)}
            containerProps={
              option.description ? { className: '-mt-2' } : undefined
            }
            crossOrigin={undefined}
          />
        ))}
      </div>
    </div>
  );
};

export default CallQualityRadio;
