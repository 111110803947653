import KeypadButton from 'components/atoms/phone/keypad-button';
import DeleteButton from 'components/atoms/phone/delete-button';

interface DialerProps {
  onDigitClick: (digit: string) => void;
  onDeleteClick?: () => void;
  disabled?: boolean;
  fourthRowKeys?: string[];
}

/**
 * Renders a phone dialer keypad.
 *
 * @param props - Properties for the Dialer component.
 * @returns The Dialer component.
 */
export function Dialer({
  onDigitClick,
  onDeleteClick,
  disabled = false,
  fourthRowKeys = ['+', '0'],
}: DialerProps) {
  const firstRow = ['1', '2', '3'];
  const secondRow = ['4', '5', '6'];
  const thirdRow = ['7', '8', '9'];

  const fourthRow = [...fourthRowKeys];
  if (onDeleteClick) fourthRow.push('delete');

  const rows = [firstRow, secondRow, thirdRow, fourthRow];

  return (
    <div className="flex flex-col space-y-2 my-4">
      {rows.map((row, rowIndex) => (
        <div key={`row-${rowIndex}`} className="flex justify-center space-x-2">
          {row.map((key) =>
            key === 'delete' ? (
              <DeleteButton
                key="delete"
                onClick={onDeleteClick || (() => {})}
                disabled={disabled}
              />
            ) : (
              <KeypadButton
                key={key}
                label={key}
                onClick={() => onDigitClick(key)}
                disabled={disabled}
              />
            )
          )}
        </div>
      ))}
    </div>
  );
}

export default Dialer;
