import { useEffect, useCallback, useRef, RefObject, useState } from 'react';

interface UseInfiniteScrollParams {
  containerRef: RefObject<HTMLDivElement>;
  isFetching: boolean;
  hasMore: boolean;
  fetchMore: () => void;
  direction?: 'up' | 'down';
  threshold?: number;
}

export const useInfiniteScroll = ({
  containerRef,
  isFetching,
  hasMore,
  fetchMore,
  direction = 'up',
  threshold = 0.2,
}: UseInfiniteScrollParams) => {
  const prevScrollHeightRef = useRef<number>(0);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    const thresholdDistance = clientHeight * threshold;
    const isScrollingUp = direction === 'up';
    const distanceFromEdge = isScrollingUp
      ? scrollTop
      : scrollHeight - scrollTop - clientHeight;

    const shouldFetchMore =
      distanceFromEdge <= thresholdDistance && hasMore && !isFetching;

    if (shouldFetchMore) {
      prevScrollHeightRef.current = scrollHeight;
      fetchMore();
    }
  }, [containerRef, fetchMore, hasMore, isFetching, direction, threshold]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef, handleScroll]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container || isFetching) return;

    const prevScrollHeight = prevScrollHeightRef.current;
    if (!prevScrollHeight) return;

    const newScrollHeight = container.scrollHeight;

    if (direction === 'up') {
      container.scrollTop = newScrollHeight - prevScrollHeight;
    }

    prevScrollHeightRef.current = 0;
  }, [containerRef, isFetching, direction]);
};

interface UseScrollPositionReturn {
  isAtBottom: boolean;
  handleScroll: (event: React.UIEvent<HTMLDivElement>) => void;
}

export function useScrollPosition(
  dependencies: unknown[]
): UseScrollPositionReturn {
  const [isAtBottom, setIsAtBottom] = useState(true);
  const dependenciesKey = JSON.stringify(dependencies);

  useEffect(() => {
    setIsAtBottom(true);
  }, [dependenciesKey]);

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    const atBottom = scrollTop + clientHeight >= scrollHeight - 50;
    setIsAtBottom(atBottom);
  }, []);

  return { isAtBottom, handleScroll };
}

interface UseScrollToBottomParams {
  bottomAnchorRef: RefObject<HTMLDivElement>;
  isAtBottom: boolean;
  dependencies: unknown[];
}

interface UseScrollToBottomReturn {
  scrollToBottom: () => void;
}

export function useScrollToBottom({
  bottomAnchorRef,
  isAtBottom,
  dependencies,
}: UseScrollToBottomParams): UseScrollToBottomReturn {
  const isInitialMount = useRef(true);
  const dependenciesKey = JSON.stringify(dependencies);

  useEffect(() => {
    isInitialMount.current = true;
  }, [dependenciesKey]);

  const scrollToBottom = useCallback(
    (behavior: ScrollBehavior = 'smooth') => {
      if (!bottomAnchorRef.current) return;

      const validBehavior: ScrollBehavior =
        behavior === 'auto' || behavior === 'smooth' ? behavior : 'smooth';

      bottomAnchorRef.current.scrollIntoView({ behavior: validBehavior });
    },
    [bottomAnchorRef]
  );

  useEffect(() => {
    if (!isAtBottom || dependencies.length === 0) return;

    if (isInitialMount.current) {
      scrollToBottom('auto');
      isInitialMount.current = false;
    } else {
      scrollToBottom();
    }
  }, [dependencies.length, isAtBottom, scrollToBottom]);

  return { scrollToBottom };
}
