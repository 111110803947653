// src/modules/messaging/whatsapp/hooks/api/messages.ts

import { useMutation } from '@tanstack/react-query';
import {
  Message,
  MessagingWhatsappMessagesService,
  Alias_WhatsAppTemplateMessageCreateBodyParams as WhatsAppTemplateMessageCreateParams,
  Alias_WhatsAppMessageCreateBodyParams as WhatsAppMessageCreateBodyParams,
} from 'src/api/generated';

interface UseWhatsAppTemplateMessageCreateResponse {
  loading: boolean;
  error: Error | null;
  message?: Message;
  createWhatsAppTemplateMessage: (
    params: WhatsAppTemplateMessageCreateParams
  ) => void;
}

export const useCreateWhatsAppTemplateMessage = (
  onSuccess: (data: Message) => void = () => {},
  onError: (error: Error) => void = () => {}
): UseWhatsAppTemplateMessageCreateResponse => {
  const createWhatsAppTemplateMessage = async (
    params: WhatsAppTemplateMessageCreateParams
  ): Promise<Message> => {
    const { tenantId: _, ...messageParams } = params;

    return await MessagingWhatsappMessagesService.createTemplateMessage(
      messageParams
    );
  };

  const mutation = useMutation({
    mutationFn: createWhatsAppTemplateMessage,
    onSuccess: (data: Message) => onSuccess(data),
    onError: (error: Error) => onError(error),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    message: mutation.data,
    createWhatsAppTemplateMessage: mutation.mutate,
  };
};

interface UseWhatsAppMessageCreateParams
  extends WhatsAppMessageCreateBodyParams {
  conversationId: string;
}

interface UseWhatsAppMessageCreateResponse {
  loading: boolean;
  error: Error | null;
  message?: Message;
  createWhatsAppMessage: (params: UseWhatsAppMessageCreateParams) => void;
}

export const useCreateWhatsAppMessage = (
  onSuccess: (data: Message) => void = () => {},
  onError: (error: Error) => void = () => {}
): UseWhatsAppMessageCreateResponse => {
  const createWhatsAppMessage = async ({
    conversationId,
    ...params
  }: UseWhatsAppMessageCreateParams): Promise<Message> => {
    const { tenantId: _, ...messageParams } = params;

    return MessagingWhatsappMessagesService.createMessage(
      conversationId,
      messageParams
    );
  };

  const mutation = useMutation({
    mutationFn: createWhatsAppMessage,
    onSuccess: (data: Message) => onSuccess(data),
    onError: (error: Error) => onError(error),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    message: mutation.data,
    createWhatsAppMessage: mutation.mutate,
  };
};
