import { OpenAPI } from './generated';

const configApiUrl = () => {
  if (import.meta.env.DEV) {
    OpenAPI.BASE = 'http://localhost:7000';
    return;
  }
  if (import.meta.env.VITE_ENV_MODE_STAGE_API_URL) {
    OpenAPI.BASE = import.meta.env.VITE_ENV_MODE_STAGE_API_URL;
    return;
  }

  OpenAPI.BASE = import.meta.env.VITE_API_URL;
};

configApiUrl();
